@if (data) {
  <div class="container-fluid">
    <div class="row">
      <div class="col-md">
        <span>
          <label>{{ "TOOLTIP.CREDITS.AUTHOR" | translate }}</label>
          <label>{{ data.author || "-" }}</label>
        </span>

        <span>
          <label>{{ "TOOLTIP.CREDITS.CREATION" | translate }}</label>
          <label>{{ data.createdAt || "-" }}</label>
        </span>

        <span>
          <label>{{ "TOOLTIP.CREDITS.EDITED" | translate }}</label>
          <label>{{ data.editedAt || "-" }}</label>
        </span>

        <span>
          <label>{{ "TOOLTIP.CREDITS.EDITOR" | translate }}</label>
          <label>{{ data.editor || "-" }}</label>
        </span>
      </div>
    </div>
  </div>
}
