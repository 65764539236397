import { Component } from "@angular/core";
import { ALLOWED_CHARACTERS } from "src/config/allowed-characters.config";
import { PrefixComponent } from "../../prefix.component";
import { TemplateNumberComponent } from "../../templates/template-number/template-number.component";
import { MaxLength } from "../../types/MaxLength";

@Component({
  standalone: true,
  selector: "app-prefix-de2",
  imports: [TemplateNumberComponent],
  templateUrl: "./prefix-de2.component.html",
  styleUrl: "./prefix-de2.component.less",
})
export class PrefixDe2Component extends PrefixComponent<string> {
  public max: MaxLength;

  public commas: number;

  public constructor() {
    super({
      defaultValue: "0,00000",
      updateOn: "blur",
    });
    this.max = 999999999.99999;
    this.required = true;
    this.commas = 5;
    this.characters = ALLOWED_CHARACTERS["de2"];
  }
}
