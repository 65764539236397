import { Subscription } from "rxjs";
import { ThreatLevel } from "../../global/prefixes/prefix.component";
import { FormConditional } from "./form-conditional";
import { FormField } from "./form-field";

export class FormCategory extends FormConditional {
  public expanded: boolean;
  public expandable: boolean;
  public label: string;

  public errors: Map<number, FormField>;
  public warnings: Map<number, FormField>;
  public fields: Map<number, FormField>;

  public rows: FormField[][];

  public subscriptions: Subscription;

  public constructor(name: string, expanded = true, expandable = true, label?: string) {
    super(name);
    this.expanded = expanded;
    this.expandable = expandable;
    this.label = label ?? name;
    this.rows = [];

    this.errors = new Map<number, FormField>();
    this.warnings = new Map<number, FormField>();
    this.fields = new Map<number, FormField>();
    this.subscriptions = new Subscription();
  }

  public setFields(fields: Map<number, FormField>): void {
    this.fields = fields;
    this.rows = this.parseRows(fields);
  }

  /**
   * Listen to threat changes on current fields
   */
  public threatListener(): void {
    for (const [index, field] of this.fields.entries()) {
      this.subscriptions.add(
        field.threat.subscribe(([, threat]) => {
          switch (threat.type) {
            case ThreatLevel.VALID:
              if (!field.errors.size) this.errors.delete(index);
              if (!field.warnings.size) this.warnings.delete(index);
              break;

            case ThreatLevel.ERROR:
              this.errors.set(index, field);
              break;

            case ThreatLevel.WARNING:
              this.warnings.set(index, field);
              break;

            default:
              console.error(`Undefined threat level: ${threat}`);
              break;
          }
        }),
      );
    }
  }

  private parseRows(fields: typeof this.fields): FormField[][] {
    const allFields = Array.from(fields.values());
    const rows: FormField[][] = [];
    let row: FormField[] = [];
    for (const field of allFields) {
      if (!field.tile) {
        if (row.length) {
          rows.push(row);
          row = [];
        }

        row.push(field);
        rows.push(row);
        row = [];
      } else {
        row.push(field);
      }
    }
    if (row.length) {
      rows.push(row);
      row = [];
    }

    return rows;
  }
}
