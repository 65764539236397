import { AfterViewInit, Component, inject, OnInit } from "@angular/core";
import { RouterModule } from "@angular/router";
import { TranslateModule } from "@ngx-translate/core";
import { ApplicationService } from "src/services/application.service";
import { SessionService } from "src/services/session.service";
import { DefaultComponent } from "../default.component";

@Component({
  standalone: true,
  selector: "app-logged-out",
  imports: [TranslateModule, RouterModule],
  templateUrl: "./logged-out.component.html",
  styleUrl: "./logged-out.component.less",
})
export class LoggedOutComponent extends DefaultComponent implements AfterViewInit, OnInit {
  public application: ApplicationService;
  public session: SessionService;

  public constructor() {
    super();
    this.application = inject(ApplicationService);
    this.session = inject(SessionService);
  }

  public async ngOnInit(): Promise<void> {
    if (this.session.authenticated.value === true) {
      await this.session.disconnect();
    }
  }

  public ngAfterViewInit(): void {
    this.application.loading.next(false);
  }
}
