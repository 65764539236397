import { Pipe, PipeTransform, Renderer2, inject } from "@angular/core";
import { DomSanitizer, SafeHtml } from "@angular/platform-browser";

@Pipe({
  name: "iconReplace",
  standalone: true,
})
export class IconReplacePipe implements PipeTransform {
  private sanitizer: DomSanitizer;
  private renderer: Renderer2;

  public constructor() {
    this.sanitizer = inject(DomSanitizer);
    this.renderer = inject(Renderer2);
  }

  /**
   * Replace html fontawesome with material
   * @param value string
   * @returns
   */
  public transform(value: unknown): SafeHtml {
    let replaced = `${value}`;
    const pattern = /<i[^>]*>[^>]*i>/g;
    const matches = Array.from(replaced.matchAll(pattern))
      .map((v) => v[0])
      .flat();
    for (const match of matches) {
      replaced = replaced.replace(match, this._convert(match));
    }
    return this.sanitizer.bypassSecurityTrustHtml(replaced);
  }

  /**
   * Convert old <i> icon to new material icon
   * @param i old i element as string
   * @returns new span icon
   */
  private _convert(i: string): string {
    const demo: HTMLSpanElement = this.renderer.createElement("span");
    demo.innerHTML = i;
    const oldElement = <HTMLElement>demo.firstChild;
    const newElement: HTMLSpanElement = this.renderer.createElement("span");
    newElement.classList.add("material-icons-outlined");
    newElement.style.display = "inline-block";
    newElement.style.verticalAlign = "middle";
    newElement.style.fontSize = newElement.style.width = newElement.style.height = "1rem";

    if (oldElement) {
      this._transferAttributes(oldElement, newElement);
      this._convertIcon(oldElement, newElement);
    }
    demo.innerHTML = "";
    demo.appendChild(newElement);
    return demo.innerHTML;
  }

  /**
   * Transfer all attributes except class to other element
   * @param a old element
   * @param b new element
   */
  private _transferAttributes(a: HTMLElement, b: HTMLElement): void {
    for (const attribute of Array.from(
      Object.values(a.attributes)
        .map((v) => v.name)
        .filter((name) => name !== "class"),
    )) {
      const value = a.getAttribute(attribute);
      if (value) {
        b.setAttribute(attribute, value);
      }
    }
  }

  /**
   * Convert icon and icon color from fontawesome to material
   * @param a old <i> element
   * @param b new <span> element
   */
  private _convertIcon(a: HTMLElement, b: HTMLElement): void {
    for (const className of a.classList) {
      if (b.innerText === "" && className.startsWith("fa-")) {
        b.innerText = this._findIcon(className);
      }
      if (className.startsWith("ft-")) {
        const color = className.split("ft-").pop();
        if (color) b.style.color = color;
      }
      if (className === "text-danger") {
        b.style.color = "red";
      }
    }
  }

  /**
   * Replace font awesome icon with material icons
   * https://fonts.google.com/icons?icon.set=Material+Icons&icon.style=Outlined
   * @param className fontawesome class name
   * @returns icon name
   */
  private _findIcon(className: string): string {
    const icon = className.split("fa-").pop();
    if (icon) {
      switch (icon) {
        case "adjust":
          return "pending_actions";
        case "forward":
          return "fast_forward";
        case "step-forward":
          return "skip_next";
        case "fast-forward":
          return "next_week";
        case "trash":
        case "trash-alt":
          return "delete";
        case "unlock":
          return "lock_open";
        case "folder-plus":
          return "create_new_folder";
        case "sign-out-alt":
          return "logout";
        case "sign-in-alt":
          return "login";
        case "question-circle":
          return "help";
        case "cogs":
          return "settings";
        case "pencil-alt":
          return "edit";
        case "hourglass-half":
          return "hourglass_top";
        case "hourglass":
          return "hourglass_empty";
        case "ban":
          return "block";
        case "check-square":
          return "check_box";
        case "exclamation":
          return "priority_high";
        case "envelope":
          return "mail";
        default:
          return icon.replace(/-/g, "_");
      }
    }
    return className;
  }
}
