import { Component } from "@angular/core";
import { ALLOWED_CHARACTERS } from "src/config/allowed-characters.config";
import { PrefixComponent } from "../../prefix.component";
import { TemplateDateComponent } from "../../templates/template-date/template-date.component";

@Component({
  standalone: true,
  selector: "app-prefix-dat",
  imports: [TemplateDateComponent],
  templateUrl: "./prefix-dat.component.html",
  styleUrl: "./prefix-dat.component.less",
})
export class PrefixDatComponent extends PrefixComponent<string> {
  public constructor() {
    super();
    this.characters = ALLOWED_CHARACTERS["dat"];
  }
}
