import { Component } from "@angular/core";
import { ALLOWED_CHARACTERS } from "src/config/allowed-characters.config";
import { PrefixComponent } from "../../prefix.component";
import { TemplateHTMLComponent } from "../../templates/template-html/template-html.component";

@Component({
  standalone: true,
  selector: "app-prefix-kop",
  imports: [TemplateHTMLComponent],
  templateUrl: "./prefix-kop.component.html",
  styleUrl: "./prefix-kop.component.less",
})
export class PrefixKopComponent extends PrefixComponent<string> {
  public constructor() {
    super();
    this.post = false;
    this.characters = ALLOWED_CHARACTERS["kop"];
  }
}
