import { Component, inject } from "@angular/core";
import { FormControl } from "@angular/forms";
import { MatProgressSpinnerModule } from "@angular/material/progress-spinner";
import { RouterModule } from "@angular/router";
import { TranslateModule } from "@ngx-translate/core";
import { SessionService } from "src/services/session.service";
import { PrefixJanComponent } from "../components/global/prefixes/impl/prefix-jan/prefix-jan.component";
import { PrefixOmsComponent } from "../components/global/prefixes/impl/prefix-oms/prefix-oms.component";
import { CheckboxValue } from "../components/global/prefixes/templates/template-checkbox/template-checkbox.component";
import { DefaultComponent } from "../default.component";
@Component({
  standalone: true,
  selector: "app-recovery",
  imports: [MatProgressSpinnerModule, PrefixOmsComponent, PrefixJanComponent, TranslateModule, RouterModule],
  templateUrl: "./recovery.component.html",
  styleUrl: "./recovery.component.less",
})
export class RecoveryComponent extends DefaultComponent {
  private session: SessionService;

  public isLoading: boolean;
  public username: FormControl<string | null> | null;
  public check: FormControl<CheckboxValue | null> | null;

  public constructor() {
    super();
    this.session = inject(SessionService);

    this.isLoading = false;
    this.username = null;
    this.check = null;
  }

  public async recover(): Promise<void> {
    if (this.check && this.username) {
      this.isLoading = true;
      const check = this.check.value;
      const username = this.username.value;

      if (username && check) {
        try {
          await this.session.recover({
            usernameoremail: username,
            privateemail: Object.values(check).shift() || false,
          });
        } catch (e) {
          this.isLoading = false;
          console.error("[RECOVER ERROR]", e);
        }
      } else {
        this.isLoading = false;
        throw new Error("Invalid username or check");
      }
    } else {
      throw new Error("Undefined username or check control");
    }
  }
}
