export const STORAGE = <const>{
  ROLE_ID: "role-id",
  WORKSPACE_ID: "workspace-id",
  TABLE_SETTINGS: "table-settings",
  LOCALE: "locale",
  TABINDEX: "tabindex",
  SCHEDULES: "schedules",
  SCHEDULES_DATE: "schedules_date",
  SCHEDULES_VIEW: "schedules_view",
};
