import { Component } from "@angular/core";
import { ALLOWED_CHARACTERS } from "src/config/allowed-characters.config";
import { PrefixComponent } from "../../prefix.component";
import { TemplateNotificationComponent } from "../../templates/template-notification/template-notification.component";

@Component({
  standalone: true,
  selector: "app-prefix-to3",
  imports: [TemplateNotificationComponent],
  templateUrl: "./prefix-to3.component.html",
  styleUrl: "./prefix-to3.component.less",
})
export class PrefixTo3Component extends PrefixComponent<string> {
  public constructor() {
    super();
    this.post = false;
    this.characters = ALLOWED_CHARACTERS["to3"];
  }
}
