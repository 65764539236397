import { Component } from "@angular/core";
import { ALLOWED_CHARACTERS } from "src/config/allowed-characters.config";
import { PrefixTextComponent } from "../../prefix-text.component";
import { TemplateTextComponent } from "../../templates/template-text/template-text.component";
import { MaxLength } from "../../types/MaxLength";
import { Regex } from "../../types/Regex";

@Component({
  standalone: true,
  selector: "app-prefix-em2",
  imports: [TemplateTextComponent],
  templateUrl: "./prefix-em2.component.html",
  styleUrl: "./prefix-em2.component.less",
})
export class PrefixEm2Component extends PrefixTextComponent {
  public maxlength: MaxLength;
  public regex: Regex;

  public constructor() {
    super();
    this.maxlength = 384;
    this.regex = "EMAIL";
    this.required = true;
    this.characters = ALLOWED_CHARACTERS["em2"];
  }
}
