@if (!disabled && control) {
  <div class="time-container">
    <div class="fake-input" (click)="hours.focus(); hours.select()">
      <input
        #hours
        type="text"
        size="2"
        maxlength="2"
        min="0"
        max="23"
        placeholder="--"
        (beforeinput)="onBeforeInput($event)"
        (blur)="onBlur($event)"
        (keydown)="onKeydown($event)"
        (change)="log($event)"
        (click)="$event.stopImmediatePropagation()"
        [attr.aria-label]="'TEMPLATE.TIME.HOURS' | translate"
      />
      <span class="separator">:</span>
      <input
        #minutes
        type="text"
        size="2"
        maxlength="2"
        min="0"
        max="59"
        placeholder="--"
        (beforeinput)="onBeforeInput($event)"
        (blur)="onBlur($event)"
        (keydown)="onKeydown($event)"
        (click)="$event.stopImmediatePropagation()"
        [attr.aria-label]="'TEMPLATE.TIME.MINUTES' | translate"
      />
    </div>
  </div>
} @else {
  <app-template-html [value]="value" [control]="control" />
}
