import { CommonModule } from "@angular/common";
import { Component, Input, OnInit, forwardRef } from "@angular/core";
import { FormControl } from "@angular/forms";
import { TranslateModule } from "@ngx-translate/core";
import { skip } from "rxjs";
import { FormField } from "src/app/components/default/form/form-field";
import { FormPrefixComponent } from "../../containers/form-prefix/form-prefix.component";
import { PrefixComponent } from "../../prefix.component";
import { PrefixGt2Component } from "../prefix-gt2/prefix-gt2.component";

export interface Group {
  labelSuffix: string;
  value: {
    label: string;
    value: string;
  }[];
}

@Component({
  standalone: true,
  selector: "app-prefix-sao",
  imports: [CommonModule, TranslateModule, forwardRef(() => FormPrefixComponent), PrefixGt2Component],
  templateUrl: "./prefix-sao.component.html",
  styleUrl: "./prefix-sao.component.less",
})
export class PrefixSaoComponent extends PrefixComponent<Group[]> implements OnInit {
  @Input()
  public hours: FormField<string> | null;

  @Input()
  public output: FormField | null;

  @Input()
  public groups: Group[];

  public fields: Map<string, FormField>;
  public otherHours: number = 0;
  public bruto: FormControl<string | null>;
  public netto: FormControl<string | null>;
  public done = false;
  public constructor() {
    super();
    this.hours = null;
    this.output = null;
    this.groups = [];

    this.fields = new Map<string, FormField>();
    this.bruto = new FormControl<string | null>(null);
    this.netto = new FormControl<string | null>(null);
  }

  public ngOnInit(): void {
    for (const group of this.groups) {
      const newFields = this.getFields(group);
      for (const [key, field] of Object.entries(newFields)) {
        this.fields.set(key, field);
        this.addSubscription(
          field.value.pipe(skip(1)).subscribe(() => this.calculate()),
          field.threat.subscribe((threat) => this.threatchanged.next(threat))
        );
      }
    }
    if (this.hours) {
      this.otherHours = parseFloat(this.hours.value.value);
    }
    this.done = true;
  }

  public getField(groupid: string, id: string): FormField | null {
    return this.fields.get(`${groupid}:${id}`) ?? null;
  }

  /**
   * returns record with formfields
   * @param group
   * @returns
   */
  private getFields(group: Group): Record<string, FormField<unknown>> {
    return {
      ...group.value.reduce(
        (next, row) => ({
          ...next,
          [`${group.labelSuffix}:${row.label}`]: new FormField<string>(row.label, row.value, "uu4", null),
        }),
        {}
      ),
      [`${group.labelSuffix}:LW`]: new FormField<string>("LW", "100", "pr2", null),
    };
  }

  /**
   * Calculate when form changes
   */
  private calculate(): void {
    let brutoHours = 0;
    let nettoHours = 0;
    let weekPercentage = 0;
    for (const group of this.groups) {
      const lwField = this.getField(group.labelSuffix, "LW");
      weekPercentage = (Number.parseInt(<string>lwField?.value.value) ?? 100) / 100 || 0;
      for (const day of group.value) {
        const dayField = this.getField(group.labelSuffix, day.label);
        if (dayField !== null) {
          if (dayField.template === "uu4") {
            let value = Number.parseFloat((<string>dayField.value.value).replace(",", "."));
            if (isNaN(value)) value = 0;
            brutoHours += value;
            nettoHours += value * weekPercentage;
          }
        }
      }
    }
    this.bruto.patchValue(brutoHours.toString());
    this.netto.patchValue(nettoHours.toString());
    if (this.hours && this.output) {
      let outcome = Math.floor((1 - (nettoHours / this.otherHours || 0)) * 100);
      if (outcome < 0) {
        outcome = 0;
      } else if (outcome > 100) {
        outcome = 100;
      }
      this.output.value.next(outcome);
    }
    const newGroups: Group[] = [];
    for (const group of this.groups) {
      const newGroup = {
        labelSuffix: group.labelSuffix,
        value: group.value.map((value) => {
          return { label: value.label, value: this.getField(group.labelSuffix, value.label)?.value.value };
        }),
      };
      newGroup.value.push({ label: "LW", value: this.getField(group.labelSuffix, "LW")?.value.value });
      newGroups.push(<Group>newGroup);
    }
    this.updateControl(newGroups);
    this.hours?.value.next(nettoHours.toString());
  }
}
