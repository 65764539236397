import { CommonModule } from "@angular/common";
import { Component, inject } from "@angular/core";
import { SnackbarService } from "../../snackbar.service";
import { SnackbarTemplateComponent } from "../snackbar-template.component";

@Component({
  selector: "app-default-snackbar",
  standalone: true,
  imports: [CommonModule],
  templateUrl: "./default-snackbar.component.html",
  styleUrl: "./default-snackbar.component.less",
})
export class DefaultSnackbarComponent extends SnackbarTemplateComponent {
  public snackbar: SnackbarService;
  public constructor() {
    super();
    this.snackbar = inject(SnackbarService);
  }
}
