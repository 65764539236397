import { Component, Input } from "@angular/core";
import { ALLOWED_CHARACTERS } from "src/config/allowed-characters.config";
import { PrefixComponent } from "../../prefix.component";
import { LinkData, TemplateLinkComponent } from "../../templates/template-link/template-link.component";

@Component({
  standalone: true,
  selector: "app-prefix-ln3",
  imports: [TemplateLinkComponent],
  templateUrl: "./prefix-ln3.component.html",
  styleUrl: "./prefix-ln3.component.less",
})
export class PrefixLn3Component extends PrefixComponent<LinkData[]> {
  @Input()
  public href: LinkData[];

  @Input()
  public routes: LinkData[];

  public constructor() {
    super();
    this.href = [];
    this.routes = [];
    this.post = false;
    this.characters = ALLOWED_CHARACTERS["ln3"];
  }
}
