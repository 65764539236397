@defer {
  <div class="rep-container">
    @for (row of repeated; track row.index) {
      <div class="rep-row-container">
        <!-- row! -->
        @for (field of row.fields; track $index) {
          <!-- field! -->
          <div class="field" [ngClass]="{ hidden: field.hidden || !field.condition.value }">
            @if (field.labelVisibility !== 2) {
              <div class="field-name-container" [class.no-label]="!field.label || field.labelVisibility === 1">
                <div class="field-name">
                  {{ field.label }}
                  @if (field.required) {
                    <span class="required">*</span>
                  }
                </div>
              </div>
            }
            <!-- prefix -->
            <div class="rep-field-container" [class.overwrite-error]="field.errors.size < 1">
              @defer {
                <app-form-prefix [field]="field" (dirty)="dirty = true; deactivate.next(false)" />

                <div class="extras">
                  @if (field.errors.size) {
                    <div class="threat-container" [tooltip]="{ component: threatTooltip, data: field.errors }">
                      <mat-icon class="error-icon">priority_high</mat-icon>
                    </div>
                  } @else if (field.labelVisibility !== 2) {
                    <div class="tooltip-container">
                      @if (field.tooltip) {
                        <span class="tooltip-content" [tooltip]="field.tooltip">
                          <mat-icon>info</mat-icon>
                        </span>
                      }
                    </div>
                  }
                </div>
              }
            </div>
          </div>
        }
        <div class="action">
          <button mat-stroked-button [disabled]="disabled" (click)="remove(row.index)"><mat-icon>delete</mat-icon>{{ "TOOLTIP.TABLE.DELETE" | translate }}</button>
        </div>
      </div>
    }

    <div>
      <div class="action">
        <button mat-stroked-button [disabled]="disabled" (click)="add()"><mat-icon>add</mat-icon>{{ "TOOLTIP.TABLE.ADD" | translate }}</button>
      </div>
    </div>
  </div>
}
