export interface FileDownload {
  blob: Blob;
  label: string;
  extension?: string;
}

export class FileManager {
  public static async downloadDyn(head: Response): Promise<void> {
    const contentDisposition = head.headers.get("Content-Disposition");
    if (!contentDisposition) {
      throw new Error("Invalid headers send with dyn download response");
    }
    const args = contentDisposition.split(";").map((e) => e.trim());
    if (args.includes("inline")) {
      throw new Error("Inline files are not supported");
    }
    let fileName = "";
    // let rfc5987 = false;
    for (const a of args) {
      const i = a.split("=").map((e) => e.trim());
      if (i.length != 2) continue;
      if (i[0] == "filename*") {
        // rfc5987 = true;
        //geen zin in een proper implementation
        throw new Error("filename contains non ascii text, or encodes its character set in the response, either goes unhandled");
      }
      if (i[0] == "filename" /*&&!rfc5987*/) {
        fileName = i[1];
      }
    }
    FileManager.download({ blob: await head.blob(), label: fileName });
  }

  public static download(...files: FileDownload[]): void {
    if (files.length) {
      for (const file of files) {
        const objectURL = URL.createObjectURL(file.blob);
        const anchor = document.createElement("a");

        anchor.href = objectURL;
        anchor.download = file.label + (file.extension || "");
        anchor.click();
        anchor.remove();

        URL.revokeObjectURL(objectURL);
      }
    } else {
      throw new Error(`Could not create blobs`);
    }
  }
}
