<div class="row">
  <div class="col-md mb-3">
    <mat-checkbox [formControl]="printNotFilledIn">{{ "DIALOG.LIMITEDPRINT.LABEL" | translate }}</mat-checkbox>
  </div>
</div>

<div class="row">
  <div class="col-md button-container">
    <button [mat-dialog-close]="!!printNotFilledIn.value">
      <label>{{ "DIALOG.LIMITEDPRINT.PRINT" | translate }}</label>
    </button>

    <button [mat-dialog-close]="undefined">
      <label>{{ "DIALOG.LIMITEDPRINT.BACK" | translate }}</label>
    </button>
  </div>
</div>
