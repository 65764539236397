import { BehaviorSubject, Subject } from "rxjs";
import { Attachment } from "src/classes/Attachment";
import { Threat, ThreatLevel } from "../../global/prefixes/prefix.component";
import { FormConditional } from "./form-conditional";

export enum LabelVisibility {
  visible,
  invisible,
  hidden,
}
export interface FormFieldTile {
  icon: string | null;
  image: string | null;
}

export class FormField<VALUE = unknown, EXTRAS = Record<string, unknown>> extends FormConditional {
  public label: string | null;
  public value: BehaviorSubject<VALUE>;
  public template: string;
  public tile: FormFieldTile | null;

  public characters: string | null;

  public attachments: Attachment[];
  public tooltip: string | null;

  public postable: boolean;
  public required: boolean;
  public labelVisibility: LabelVisibility;

  public extras: EXTRAS | null;

  public touched: boolean;

  public errors: Map<string, unknown>;
  public warnings: Map<string, unknown>;

  public threat: Subject<Threat>;

  public readonly: boolean;

  public constructor(name: string, value: VALUE, template: string, label: string | null = null, extras: EXTRAS | null = null) {
    super(name);
    this.label = label;
    this.value = new BehaviorSubject(value);
    this.template = template;
    this.tile = null;

    this.characters = null;

    this.attachments = [];
    this.tooltip = null;

    this.postable = true;
    this.required = false;
    this.readonly = false;
    this.labelVisibility = LabelVisibility.visible;

    this.extras = extras;

    this.touched = false;

    this.errors = new Map();
    this.warnings = new Map();

    this.threat = new Subject();

    this.subscriptions.add(
      this.threat.subscribe(([id, threat]) => {
        switch (threat.type) {
          case ThreatLevel.VALID:
            this.errors.delete(id);
            this.warnings.delete(id);
            break;

          case ThreatLevel.ERROR:
            this.errors.set(id, threat.value);
            break;

          case ThreatLevel.WARNING:
            this.warnings.set(id, threat.value);
            break;

          default:
            console.error(`Undefined threat level: ${threat}`);
            break;
        }
      }),
    );
  }
}
