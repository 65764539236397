import { Component } from "@angular/core";
import { ALLOWED_CHARACTERS } from "src/config/allowed-characters.config";
import { PrefixComponent } from "../../prefix.component";
import { TemplateNumberComponent } from "../../templates/template-number/template-number.component";
import { Regex } from "../../types/Regex";

@Component({
  standalone: true,
  selector: "app-prefix-pr6",
  imports: [TemplateNumberComponent],
  templateUrl: "./prefix-pr6.component.html",
  styleUrl: "./prefix-pr6.component.less",
})
export class PrefixPr6Component extends PrefixComponent<string> {
  public regex: Regex;
  public commas: number;

  public constructor() {
    super({
      defaultValue: "0",
      updateOn: "blur",
    });
    this.regex = "PR6";
    this.commas = 3;
    this.characters = ALLOWED_CHARACTERS["pr6"];
  }
}
