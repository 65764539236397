import { Component } from "@angular/core";
import { MatIconModule } from "@angular/material/icon";
import { TranslateModule } from "@ngx-translate/core";
import { SnackbarComponent } from "../snackbar.component";

@Component({
  standalone: true,
  selector: "app-warning-message",
  imports: [MatIconModule, TranslateModule],
  templateUrl: "./warning-message.component.html",
  styleUrl: "./warning-message.component.less",
})
export class WarningMessageComponent extends SnackbarComponent<string> {
  public constructor() {
    super();
  }
}
