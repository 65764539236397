import { bootstrapApplication } from "@angular/platform-browser";
import { MsalRedirectComponent } from "@azure/msal-angular";
import { AppComponent } from "./app/app.component";
import { appConfig } from "./app/app.config";
import { ORGANISATION } from "./organisation/organisation";

bootstrapApplication(AppComponent, appConfig)
  .then((appRef) => {
    appRef.bootstrap(MsalRedirectComponent);
  })
  .catch((err) => console.error(err));

const map = ORGANISATION.MAP;
if (map) {
  const script = document.createElement("script");
  script.setAttribute("src", `https://maps.googleapis.com/maps/api/js?key=${map.KEY}&v=weekly&libraries=maps,marker`);
  document.body.append(script);
}
