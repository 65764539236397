import { Component } from "@angular/core";
import { ALLOWED_CHARACTERS } from "src/config/allowed-characters.config";
import { PrefixComponent } from "../../prefix.component";
import { TemplateNumberComponent } from "../../templates/template-number/template-number.component";
import { Min } from "../../types/Min";
import { Regex } from "../../types/Regex";

@Component({
  standalone: true,
  selector: "app-prefix-uur",
  imports: [TemplateNumberComponent],
  templateUrl: "./prefix-uur.component.html",
  styleUrl: "./prefix-uur.component.less",
})
export class PrefixUurComponent extends PrefixComponent<string> {
  public min: Min;
  public regex: Regex;

  public commas: number;

  public constructor() {
    super({
      defaultValue: "0",
      updateOn: "blur",
    });
    this.min = 0.01;
    this.required = true;
    this.regex = "UUR";
    this.commas = 2;
    this.characters = ALLOWED_CHARACTERS["uur"];
  }
}
