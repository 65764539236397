import { Component, Input, OnChanges } from "@angular/core";
import { MatIconModule } from "@angular/material/icon";
import { ALLOWED_CHARACTERS } from "src/config/allowed-characters.config";
import { PrefixTextComponent } from "../../prefix-text.component";
import { PrefixEventType } from "../../prefix.component";
import { TemplateHTMLComponent } from "../../templates/template-html/template-html.component";
import { TemplateTextareaComponent } from "../../templates/template-textarea/template-textarea.component";
import { MaxLength } from "../../types/MaxLength";

export interface AttachmentLink {
  originalFilename: string;
  link: string;
  description: string;
  filename: string;
  extension: string;
}

@Component({
  standalone: true,
  selector: "app-prefix-bes",
  imports: [TemplateTextareaComponent, TemplateHTMLComponent, MatIconModule],
  templateUrl: "./prefix-bes.component.html",
  styleUrl: "./prefix-bes.component.less",
})
export class PrefixBesComponent extends PrefixTextComponent implements OnChanges {
  public maxlength: MaxLength;

  @Input()
  public attachmentLinks: AttachmentLink[];

  public constructor() {
    super();
    this.attachmentLinks = [];
    this.maxlength = 2000;
    this.characters = ALLOWED_CHARACTERS["bes"];
  }

  public downloadAttachment(link: AttachmentLink): void {
    this.event.emit({ type: PrefixEventType.SNAPSHOT, params: [link.link, link.filename ?? null] });
  }
}
