import { Component } from "@angular/core";
import { ALLOWED_CHARACTERS } from "src/config/allowed-characters.config";
import { PrefixComponent } from "../../prefix.component";
import { TemplateNumberComponent } from "../../templates/template-number/template-number.component";
import { Max } from "../../types/Max";

@Component({
  standalone: true,
  selector: "app-prefix-prc",
  imports: [TemplateNumberComponent],
  templateUrl: "./prefix-prc.component.html",
  styleUrl: "./prefix-prc.component.less",
})
export class PrefixPrcComponent extends PrefixComponent<string> {
  public max: Max;

  public constructor() {
    super({ updateOn: "blur" });
    this.max = 999;
    this.characters = ALLOWED_CHARACTERS["prc"];
  }
}
