@if (!disabled) {
  <app-template-textarea [counter]="counter" [disabled]="disabled" [characters]="characters" [value]="value" [control]="control" [maxlength]="maxlength" [label]="label" />
} @else {
  <app-template-html [control]="control" [value]="value" />
  @for (attachment of attachmentLinks; track $index) {
    <button class="attachment" (click)="downloadAttachment(attachment)">
      <mat-icon>attachment</mat-icon>
      <span>{{ attachment.filename }}</span>
    </button>
  }
}
