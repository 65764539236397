<app-template-select
  [disabled]="disabled"
  [value]="value"
  [control]="control"
  [disabled]="disabled"
  [required]="required"
  [options]="options"
  [multiple]="multiple"
  [label]="label"
/>
