<div class="container">
  <div id="modal" class="row">
    <div class="col-md">
      <div class="row">
        <div id="frame-container" class="col-md">
          <div class="title">
            @if (title) {
              <label>{{ title.label | translate: title.params }}</label>
            }
          </div>

          <div class="close" (click)="close()">
            <mat-icon>close</mat-icon>
          </div>
        </div>
      </div>

      <div id="content-container" class="row">
        <div class="col-md">
          <div #renderer></div>
        </div>
      </div>
    </div>
  </div>
</div>
