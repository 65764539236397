import { Injectable } from "@angular/core";
import { CanDeactivateFn, GuardResult } from "@angular/router";
import { DefaultComponent } from "src/app/default.component";

@Injectable()
export class DeactivateGuard {
  public deactivate: boolean;

  public constructor() {
    this.deactivate = true;
  }

  public canDeactivate(): boolean {
    return this.deactivate;
  }
}

export const deactivateGuard: CanDeactivateFn<DefaultComponent> = (component: DefaultComponent): GuardResult | Promise<GuardResult> => {
  return component.canDeactivate();
};
