import { FormStyle, TranslationWidth, getLocaleDayNames, getLocaleMonthNames } from "@angular/common";
import { NativeDateAdapter } from "@angular/material/core";
import { ApplicationService } from "../services/application.service";

export class CustomDateAdapter extends NativeDateAdapter {
  private application: ApplicationService;

  public constructor(matDateLocale: string, application: ApplicationService) {
    super(matDateLocale);
    this.application = application;
  }

  public override getFirstDayOfWeek(): number {
    return 1;
  }

  public override getDayOfWeekNames(style: "long" | "short" | "narrow"): string[] {
    return [...getLocaleDayNames(this.application.locale.value, FormStyle.Format, this.getStyle(style))];
  }

  public override getMonthNames(style: "long" | "short" | "narrow"): string[] {
    return [...getLocaleMonthNames(this.application.locale.value, FormStyle.Format, this.getStyle(style))];
  }

  public override format(date: Date, displayFormat: Record<string, unknown>): string {
    if (!this.isValid(date)) {
      throw Error("I18nDateAdapter: Cannot format invalid date.");
    }
    const dtf = new Intl.DateTimeFormat(this.application.locale.value, { ...displayFormat, timeZone: "utc" });
    return this.formatter(dtf, date);
  }

  private formatter(dtf: Intl.DateTimeFormat, date: Date): string {
    const d = new Date();
    d.setUTCFullYear(date.getFullYear(), date.getMonth(), date.getDate());
    d.setUTCHours(date.getHours(), date.getMinutes(), date.getSeconds(), date.getMilliseconds());
    return dtf.format(d);
  }

  private getStyle(style: "long" | "short" | "narrow"): TranslationWidth {
    switch (style) {
      case "long":
        return TranslationWidth.Wide;
      case "short":
        return TranslationWidth.Abbreviated;
      case "narrow":
        return TranslationWidth.Narrow;
    }
  }
}
