import { CommonModule } from "@angular/common";
import { Component, HostListener, Input, OnInit, inject } from "@angular/core";
import { FormControl } from "@angular/forms";
import { Router } from "@angular/router";
import { DefaultComponent } from "src/app/default.component";
import { IconReplacePipe } from "src/pipes/icon-replace.pipe";
import { PrefixTemplate } from "../../PrefixTemplate";

enum Severity {
  LOW,
  MINOR,
  MAJOR,
  CRITICAL,
}

@Component({
  standalone: true,
  selector: "app-template-notification",
  imports: [CommonModule, IconReplacePipe],
  templateUrl: "./template-notification.component.html",
  styleUrl: "./template-notification.component.less",
})
export class TemplateNotificationComponent<CONTROL, VALUE = CONTROL> extends DefaultComponent implements PrefixTemplate<CONTROL, VALUE>, OnInit {
  @Input({ required: true })
  public control: FormControl<CONTROL> | null;

  @Input()
  public value: VALUE | null;

  @Input()
  public label: string | null;

  @Input()
  public severity: Severity;

  private router: Router;

  public constructor() {
    super();
    this.router = inject(Router);
    this.control = null;
    this.value = null;
    this.label = null;

    this.severity = Severity.LOW;
  }

  public ngOnInit(): void {
    if (!this.control) throw new Error("Undefined control");
  }

  /**
   * Returns the key of Severity
   * @param severity
   * @returns
   */
  public getSeverityAsString(severity: Severity): string {
    return Severity[severity];
  }

  @HostListener("click", ["$event"])
  public onLinkClick(event: PointerEvent): void {
    const element = <HTMLElement>event.target;
    if (element.nodeName === "A") {
      const link = element.getAttribute("href");
      if (link && link.startsWith("/")) {
        event.preventDefault();
        this.router.navigate([link]);
      }
    }
  }
}
