import { HttpHeaders } from "@angular/common/http";
import {
  FieldLink,
  FormFieldResponse,
  FormListValue,
  FormListValueGroup,
  GridLayout,
  ProgressResponse,
} from "src/app/components/default/form/form.interface";
import { ComponentSize, ContentPartType } from "./content.component";

export class ContentPart {
  public id: string;
  public type: ContentPartType;
  public title: string;
  public size: ComponentSize;
  public gridStyle: string | null;

  public constructor(id: string, type: ContentPartType) {
    this.id = id;
    this.type = type;
    this.title = "";
    this.size = "auto";
    this.gridStyle = null;
  }

  /**
   * Set correct data of image prefixes
   * @param value
   * @returns
   */
  public parseImage(value: string): { height: number; width: number } {
    return {
      height: parseInt(value.split("|")[1] || "0"),
      width: parseInt(value.split("|")[2] || "0"),
    };
  }

  /**
   * Set correct data of select prefixes
   * @param values
   * @returns
   */
  public parseSelect(values: FormListValue[]): { listvalues: { label: string; value: string }[] } {
    return {
      listvalues: values
        ? values.map((option) => ({
            label: option.d,
            disabled: option.o == "true",
            value: option.v,
          }))
        : [],
    };
  }

  /**
   * Set correct data for labels
   * @param label
   * @returns
   */
  public parseLabel(label: string): { label: string } {
    return {
      label,
    };
  }

  public parseGridStyle(grid: GridLayout): string {
    return `grid-area: ${grid.row} / ${grid.col} / ${grid.row + 1} / ${grid.col + grid.size};`;
  }

  /**
   * Set correct data of select groups
   * @param values
   * @returns
   */
  public parseSelectGroups(values: FormListValueGroup[]): {
    listvaluesgroups: {
      group: string;
      options: {
        label: string;
        value: string;
      }[];
    }[];
  } {
    return {
      listvaluesgroups: values
        ? values.map((group) => ({
            group: group.group,
            options: this.parseSelect(group.listvalues).listvalues,
          }))
        : [],
    };
  }

  /**
   * Set correct data of checkbox prefixes
   * @param value
   * @returns
   */
  public parseCheckbox(value: { value: string; description: string }[]): {
    checkbox: {
      label: string;
    }[];
  } {
    return {
      checkbox: value.map((val) => ({
        label: val.description,
      })),
    };
  }

  /**
   * Set correct data of table prefixes
   * @param data
   * @returns
   */
  public parseTable(data: FormFieldResponse["tabledata"]): { tabledata: FormFieldResponse["tabledata"] } {
    return {
      tabledata: data,
    };
  }

  /**
   * Set correct data of image prefixes
   * @param value
   * @returns
   */
  public parseLink(links: FieldLink[]): { links: FieldLink[] } {
    return {
      links: links,
    };
  }

  /**
   * Sanitize strings/numbers to correct booleans
   * @param value
   * @returns
   */
  public sanitizeBoolean(value: unknown): boolean {
    if (typeof value == "string") {
      return value.toLowerCase() == "true";
    } else {
      return !!value;
    }
  }

  /**
   * Sanitize strings/numbers to correct booleans
   * @param value
   * @returns
   */
  public sanitizeFloat(value: unknown): number {
    if (typeof value == "string") {
      return parseFloat(value.replace(",", ""));
    } else {
      return parseFloat(`${value}`);
    }
  }

  /**
   * Deconstruct content part
   */
  public deconstruct(): void {
    console.log("Deconstructing content part =>", {
      class: this,
    });
  }

  public parseProgress(value: ProgressResponse): Record<string, unknown> {
    console.warn("parseing progress => ", { value });
    return {
      current: this.sanitizeFloat(value.decCurrent),
      end: this.sanitizeFloat(value.decEnd),
      start: this.sanitizeFloat(value.decStart),
      type: value.gtpType,
      unit: value.omsUnit,
    };
  }

  /**
   * Parse Content-Disposition in to a filename
   * @param headers Response headers
   * @returns Parsed Filename
   */
  protected getFilenameFromHeader(headers: HttpHeaders): string | null {
    const header = headers.get("Content-Disposition");
    const start = /filename=(")?/;
    const end = /(")?;/;
    if (header) {
      return header.split(start).pop()?.split(end).shift() || null;
    }
    return null;
  }
}
