@if (control) {
  <div class="radio-container">
    <mat-radio-group [formControl]="control" [ngClass]="[direction]">
      @for (option of options; track $index) {
        <div [ngClass]="[direction]">
          <mat-radio-button [value]="option.value" [disabledInteractive]="false">
            <span [innerHTML]="option.label | iconReplace"></span>
          </mat-radio-button>
        </div>
      }
    </mat-radio-group>
  </div>
}
