<div class="item">
  <label>Datum</label>
  <app-template-date [disabled]="disabled" [value]="dateControl.value" [control]="dateControl" [label]="label" />
</div>

<div class="item">
  <label>Uren:Minuten</label>
  <app-template-time [disabled]="disabled" [value]="timeControl.value" [control]="timeControl" [label]="label" />
</div>

<div class="item">
  <label>Secondes</label>
  <app-template-number [disabled]="disabled" [value]="secondControl.value" [control]="secondControl" [min]="0" [max]="59" [label]="label" />
</div>

<div class="item">
  <label>Millisecondes</label>
  <app-template-number [disabled]="disabled" [value]="msControl.value" [control]="msControl" [min]="0" [max]="999" [label]="label" />
</div>
