import { Injectable, inject } from "@angular/core";
import { Params } from "@angular/router";
import { BehaviorSubject } from "rxjs";
import { ErrorMessageComponent } from "src/app/components/global/snackbar/impl/error-message/error-message.component";
import { SnackbarService } from "src/app/components/global/snackbar/snackbar.service";
import { ROUTES_CONFIG } from "src/config/routes.config";
import { ActionResponse, ParsedActionResponse } from "src/interfaces/post-request/post-request";
import { Bookmark, BookmarkGroup } from "src/interfaces/session/session-bookmark";
import { ApplicationService } from "src/services/application.service";
import { HttpService } from "src/services/http.service";
import { SessionService } from "src/services/session.service";

@Injectable({
  providedIn: "root",
})
export class BookmarkService {
  public bookmarkGroup$: BehaviorSubject<Map<string, BookmarkGroup>>;

  private http: HttpService;
  private session: SessionService;
  private snackbar: SnackbarService;
  private application: ApplicationService;

  public constructor() {
    this.http = inject(HttpService);
    this.session = inject(SessionService);
    this.snackbar = inject(SnackbarService);
    this.application = inject(ApplicationService);

    this.bookmarkGroup$ = new BehaviorSubject(new Map());
  }

  public async initialize(): Promise<void> {
    const bookmarksResponses = await this.http.retrieve<{ bookmarks: string }[]>(ROUTES_CONFIG.bookmarksurl, {});

    for (const bookmarksResponse of bookmarksResponses) {
      const bookmarks = <{ ID: string; Description: string; bookmarkgroups: BookmarkGroup[] }>JSON.parse(bookmarksResponse.bookmarks);
      this.setBookmarkGroups(bookmarks.bookmarkgroups);
    }
  }

  public getBookmarkGroup(groupName: string): BookmarkGroup {
    let bookmarkGroup = this.bookmarkGroup$.value.get(groupName);
    if (!bookmarkGroup) {
      bookmarkGroup = this.bookmarkGroup$.value.set(groupName, { ID: "0", Description: groupName, bookmarks: [] }).get(groupName);
    }
    if (!bookmarkGroup) throw new Error("Bookmarkgroup should exist but does not exist.");
    return bookmarkGroup;
  }

  public async editBookmark(bookmark: Bookmark): Promise<void> {
    const group = this.bookmarkGroup$.value.get(bookmark.group);
    const foundBookmark = group?.bookmarks.find((compare) => compare.link === bookmark.link);
    if (foundBookmark?.Description) {
      foundBookmark.Description = bookmark.Description;
    }
  }

  public async editBookmarkGroup(bookmarkGroup: BookmarkGroup, newDescription: string): Promise<void> {
    const originalBookmarkGroupName = bookmarkGroup.Description;
    bookmarkGroup.bookmarks.forEach((bookmark) => {
      bookmark.group = newDescription;
    });

    bookmarkGroup.Description = newDescription;
    this.bookmarkGroup$.value.delete(originalBookmarkGroupName);
    this.bookmarkGroup$.value.set(bookmarkGroup.Description, bookmarkGroup);
  }

  public async saveBookmarks(): Promise<void> {
    try {
      const data = await this.http.send<ActionResponse>(ROUTES_CONFIG.bookmarksurl, {
        bookmarks: { ID: "0", Description: "", bookmarkgroups: Array.from(this.bookmarkGroup$.value.values()) },
      });
      const response = <ParsedActionResponse>JSON.parse(data.postActionAsJSON);
      // for (const message of response.messages) {
      //   this.snackbar.open(message.Message, { template: "success" });
      // }
      this.application.onMessage(response.messages);
    } catch (error) {
      this.snackbar.open<string>(ErrorMessageComponent, "Something went wrong");
    }
  }

  public removeBookmarkIfIsNotUniqueCheck(): void {
    const linkSet: { [id: string]: boolean } = {};
    for (const bookmarkGroup of this.bookmarkGroup$.value.values()) {
      for (const bookmark of bookmarkGroup.bookmarks) {
        if (linkSet[bookmark.link]) {
          this.removeBookmark(bookmark.link);
        }

        linkSet[bookmark.link] = true;
      }
    }
  }

  public removeBookmark(link: string): void {
    for (const bookmarkGroup of this.bookmarkGroup$.value.values()) {
      bookmarkGroup.bookmarks = bookmarkGroup.bookmarks.filter((bookmarkCompare) => bookmarkCompare.link != link);
      this.bookmarkGroup$.value.set(bookmarkGroup.Description, bookmarkGroup);
    }
  }

  public removeBookmarkGroup(bookmarkGroup: BookmarkGroup): void {
    this.bookmarkGroup$.value.delete(bookmarkGroup.Description);
  }

  /**
   * Setting the bookmarks
   * @param bookmarkGroup
   */
  public setBookmarkGroups(bookmarkGroups: BookmarkGroup[]): void {
    const map = new Map<string, BookmarkGroup>();
    for (const bookmarkGroup of bookmarkGroups) {
      map.set(bookmarkGroup.Description, bookmarkGroup);
    }
    this.bookmarkGroup$.next(map);
  }

  // todo: should not be here.
  public splitLink(s: string): [string, Params] {
    const [url, queryParams] = s.split("?");
    const [key, value] = queryParams.split("=");
    return [url, { [key]: value }];
  }
}
