@if (data) {
  <div class="container-fluid">
    <div class="row">
      <div class="col-md message">
        {{ "DIALOG.TABLE_DELETE_WARNING.MESSAGE" | translate: { value: rows.length } }}
      </div>
    </div>

    <div class="row">
      <div class="col-md button-container">
        <button (click)="confirm()">
          <label>{{ "DIALOG.TABLE_DELETE_WARNING.CONFIRM" | translate }}</label>
        </button>

        <button (click)="deny()">
          <label>{{ "DIALOG.TABLE_DELETE_WARNING.CANCEL" | translate }}</label>
        </button>
      </div>
    </div>
  </div>
}
