import { TableRow } from "./table-row";

export class TableGroup {
  public label: string;
  public fieldname: string;
  public rows: Map<number, TableRow>;
  public shown: TableRow[];

  public groups: Map<number, TableGroup>;

  public level: string;
  public hidden: boolean;
  public nosearchresults: boolean;

  public step: number;

  public collapsed: boolean;

  public nestedTotals: {
    rows: number;
    shown: number;
  };

  public constructor(label: string) {
    this.label = label;
    this.fieldname = "";
    this.rows = new Map<number, TableRow>();
    this.shown = [];
    this.groups = new Map<number, TableGroup>();

    this.level = "0";
    this.hidden = false;
    this.nosearchresults = false;

    this.step = 50;

    this.nestedTotals = {
      rows: 0,
      shown: 0,
    };

    this.collapsed = false;
  }

  public loadMore(step: number): number {
    const sliced = this.availableRows.slice(this.shown.length, this.shown.length + step);
    this.shown = [...this.shown, ...sliced];
    return sliced.length;
  }

  public get availableRows(): TableRow[] {
    return Array.from(this.rows.values()).filter((row) => !row.hidden && !row.deleted);
  }

  /**
   * Is one or more, but not all rows selected
   * @returns
   */
  public someSelected(): boolean {
    const selected = this.getSelected();
    return !!selected.length && selected.length != this.rows.size;
  }

  /**
   * Get all selected rows
   * @returns
   */
  public getSelected(): TableRow[] {
    return Array.from(this.rows.values()).filter((row) => row.selected && !row.deleted);
  }

  /**
   * Select all rows
   * @param select
   */
  public selectAll(select: boolean): void {
    for (const row of this.rows.values()) {
      row.select(select);
    }
  }

  public setTotals(): void {
    this.groups.forEach((group) => {
      group.setTotals();
    });
    this.nestedTotals.rows = this.getTotalRows();
    this.nestedTotals.shown = this.getTotalShown();
  }

  public filterShown(): void {
    this.shown = this.shown.filter((row) => !row.deleted);
    this.groups.forEach((group) => {
      group.filterShown();
    });
  }

  private getTotalRows(): number {
    let count = 0;
    count += this.availableRows.length;
    this.groups.forEach((group) => {
      count += group.getTotalRows();
    });
    return count;
  }

  private getTotalShown(): number {
    let count = 0;
    if (this.hidden || this.nosearchresults) return count;
    count += this.shown.filter((row) => row.deleted !== true).length;
    this.groups.forEach((group) => {
      count += group.getTotalShown();
    });
    return count;
  }
}
