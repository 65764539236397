import { CommonModule } from "@angular/common";
import { Component } from "@angular/core";
import { TranslateModule } from "@ngx-translate/core";
import { TooltipComponent } from "../../tooltip.component";

export type FormThreatTooltipData = Map<string, unknown>;

@Component({
  standalone: true,
  selector: "app-form-threat-tooltip",
  imports: [CommonModule, TranslateModule],
  templateUrl: "./form-threat-tooltip.component.html",
  styleUrl: "./form-threat-tooltip.component.less",
})
export class FormThreatTooltipComponent extends TooltipComponent<FormThreatTooltipData> {
  public constructor() {
    super();
  }
}
