/* eslint-disable @typescript-eslint/no-explicit-any */
import { Pipe, PipeTransform } from "@angular/core";

@Pipe({
  name: "mapvalues",
  standalone: true,
})
export class MapvaluesPipe implements PipeTransform {
  public transform(value: Map<unknown, unknown>): any[] {
    return Array.from(value.values());
  }
}
// TODO: Can be deleted in the near future
