<div class="link-container">
  <ul>
    @for (route of routes; track $index) {
      <li>
        <a
          [class.icon]="route.icon"
          (click)="sendAction(route)"
          [innerHTML]="(route.icon ? createIconElement(route.icon) : route.value) | iconReplace"
          [tooltip]="route.icon ? route.value : null"
        ></a>
        {{ route.icon }}
      </li>
    }
  </ul>
</div>
