import { Component } from "@angular/core";
import { ALLOWED_CHARACTERS } from "src/config/allowed-characters.config";
import { PrefixComponent } from "../../prefix.component";
import { TemplateNumberComponent } from "../../templates/template-number/template-number.component";
import { Max } from "../../types/Max";

@Component({
  standalone: true,
  selector: "app-prefix-nud",
  imports: [TemplateNumberComponent],
  templateUrl: "./prefix-nud.component.html",
  styleUrl: "./prefix-nud.component.less",
})
export class PrefixNudComponent extends PrefixComponent<string> {
  public max: Max;

  public commas: number;

  public constructor() {
    super({
      defaultValue: "0",
      updateOn: "blur",
    });
    this.max = 999999999.99;
    this.required = true;
    this.commas = 2;
    this.characters = ALLOWED_CHARACTERS["nud"];
  }
}
