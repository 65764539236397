import { Component } from "@angular/core";
import { ALLOWED_CHARACTERS } from "src/config/allowed-characters.config";
import { PrefixComponent } from "../../prefix.component";
import { TemplateTextComponent } from "../../templates/template-text/template-text.component";

@Component({
  standalone: true,
  selector: "app-prefix-bel",
  imports: [TemplateTextComponent],
  templateUrl: "./prefix-bel.component.html",
  styleUrl: "./prefix-bel.component.less",
})
export class PrefixBelComponent extends PrefixComponent<string> {
  public constructor() {
    super();
    this.post = false;
    this.characters = ALLOWED_CHARACTERS["bel"];
  }
}
