<div class="link-container">
  <ul>
    @for (link of href; track $index) {
      <li>
        <a [class.icon]="link.icon" [href]="link.link" [innerHTML]="(link.icon ? createIconElement(link.icon) : link.value) | iconReplace"></a>
      </li>
    }
    @for (link of routes; track $index) {
      <li>
        <a [class.icon]="link.icon" [routerLink]="['/app/content', link.link]" [innerHTML]="(link.icon ? createIconElement(link.icon) : link.value) | iconReplace"></a>
      </li>
    }
    @if (!href.length && !routes.length) {
      <li>
        <label [innerHTML]="value"></label>
      </li>
    }
  </ul>
</div>
