import { Component } from "@angular/core";
import { TranslateModule } from "@ngx-translate/core";
import { FormCredits } from "src/app/components/default/form/form.interface";
import { TooltipComponent } from "../../tooltip.component";

@Component({
  standalone: true,
  selector: "app-credits-tooltip",
  imports: [TranslateModule],
  templateUrl: "./credits-tooltip.component.html",
  styleUrl: "./credits-tooltip.component.less",
})
export class CreditsTooltipComponent extends TooltipComponent<FormCredits> {
  public constructor() {
    super();
  }
}
