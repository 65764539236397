import { Component, Input } from "@angular/core";
import { ALLOWED_CHARACTERS } from "src/config/allowed-characters.config";
import { PrefixComponent } from "../../prefix.component";
import { CheckboxOption, CheckboxValue, TemplateCheckboxComponent } from "../../templates/template-checkbox/template-checkbox.component";

@Component({
  standalone: true,
  selector: "app-prefix-jan",
  imports: [TemplateCheckboxComponent],
  templateUrl: "./prefix-jan.component.html",
  styleUrl: "./prefix-jan.component.less",
})
export class PrefixJanComponent extends PrefixComponent<CheckboxValue> {
  @Input()
  public options: CheckboxOption[];

  public constructor() {
    super();
    this.options = [];
    this.characters = ALLOWED_CHARACTERS["jan"];
  }
}
