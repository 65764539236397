import { Component, OnInit, inject } from "@angular/core";
import { MatIconModule } from "@angular/material/icon";
import { TranslateModule } from "@ngx-translate/core";
import { ApplicationService } from "src/services/application.service";
import { KeylistenerService } from "src/services/keylistener.service";
import { Key } from "../../../../../../enums/key";
import { DialogService } from "../../dialog.service";
import { DialogTemplateComponent, DialogTitle } from "../dialog-template.component";

@Component({
  standalone: true,
  selector: "app-dialog-modal",
  imports: [TranslateModule, MatIconModule],
  templateUrl: "./modal.component.html",
  styleUrl: "./modal.component.less",
})
export class ModalComponent extends DialogTemplateComponent implements OnInit {
  public application: ApplicationService;
  private dialog: DialogService;
  private keylistener: KeylistenerService;
  public title: DialogTitle | null;

  public constructor() {
    super();
    this.application = inject(ApplicationService);
    this.dialog = inject(DialogService);
    this.keylistener = inject(KeylistenerService);

    this.title = null;
  }

  public ngOnInit(): void {
    const data = this.data;
    if (data && typeof data == "object" && "title" in data) {
      const title = <DialogTitle>data.title;
      if (title.label) {
        this.title = {
          label: title.label,
          params: title.params || {},
        };
      }
    }

    this.keylistener.add(Key.ESCAPE, () => {
      this.close();
    });
  }

  public close(): void {
    if (this.id) {
      this.dialog.close(this.id);
    } else {
      throw new Error("Unable to close dialog - undefined id");
    }
  }
}
