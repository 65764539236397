import { Component, inject } from "@angular/core";
import { DefaultComponent } from "src/app/default.component";
import { ApplicationService } from "src/services/application.service";

@Component({
  standalone: true,
  selector: "app-caption",
  imports: [],
  templateUrl: "./caption.component.html",
  styleUrl: "./caption.component.less",
})
export class CaptionComponent extends DefaultComponent {
  public application: ApplicationService;

  public title: string | null;

  public constructor() {
    super();
    this.application = inject(ApplicationService);
    this.title = null;

    this.addSubscription(
      this.application.title.subscribe((value) => {
        this.title = value;
      })
    );
  }
}
