import { CommonModule } from "@angular/common";
import { AfterViewInit, Component, ElementRef, Input, OnInit, ViewChild } from "@angular/core";
import { FormControl, ReactiveFormsModule } from "@angular/forms";
import { TranslateModule } from "@ngx-translate/core";
import { DefaultComponent } from "src/app/default.component";
import { PrefixTemplate } from "../../PrefixTemplate";
import { PrefixValidator } from "../../PrefixValidator";
import { Regex } from "../../types/Regex";
import { TemplateHTMLComponent } from "../template-html/template-html.component";

@Component({
  standalone: true,
  selector: "app-template-time",
  imports: [ReactiveFormsModule, TemplateHTMLComponent, CommonModule, TranslateModule],
  templateUrl: "./template-time.component.html",
  styleUrl: "./template-time.component.less",
})
export class TemplateTimeComponent extends DefaultComponent implements PrefixTemplate<string>, OnInit, AfterViewInit {
  @Input({ required: true })
  public control: FormControl<string | null> | null;

  @Input()
  public value: string | null;

  @Input()
  public regex: Regex;

  @Input()
  public required: boolean;

  @Input()
  public disabled: boolean;

  @Input()
  public characters: string | null;

  @Input()
  public label: string | null;

  @ViewChild("hours")
  private hoursRef: ElementRef<HTMLInputElement> | null;

  @ViewChild("minutes")
  private minutesRef: ElementRef<HTMLInputElement> | null;

  public constructor() {
    super();

    this.control = null;
    this.value = null;
    this.regex = null;
    this.required = false;
    this.disabled = false;
    this.characters = null;
    this.label = null;

    this.hoursRef = null;
    this.minutesRef = null;
  }

  public ngOnInit(): void {
    const control = this.control;
    if (control) {
      this.addValidators(control);
    } else {
      throw new Error("Undefined control");
    }
  }

  public ngAfterViewInit(): void {
    const value = this.value;
    if (value) {
      const [hours, minutes] = value.split(":", 2);
      if (this.hoursRef) this.hoursRef.nativeElement.value = hours || "";
      if (this.minutesRef) this.minutesRef.nativeElement.value = minutes || "";
    }
  }

  public onBeforeInput(event: InputEvent): void {
    const pattern = /[0-9]/;
    if (event.data) {
      if (!pattern.test(event.data)) {
        event.preventDefault();
      }
    }
  }

  public onBlur(event: Event): void {
    const target = <HTMLInputElement>event.target;
    const value = parseInt(target.value);
    this.setValue(target, isNaN(value) ? null : value);
  }

  public onKeydown(event: KeyboardEvent): void {
    switch (event.key) {
      case "ArrowUp":
        this.onArrowUp(event);
        break;
      case "ArrowDown":
        this.onArrowDown(event);
        break;
      case "ArrowLeft":
        this.onArrowLeft(event);
        break;
      case "ArrowRight":
        this.onArrowRight(event);
        break;
    }
  }

  private onArrowUp(event: KeyboardEvent): void {
    event.preventDefault();
    const target = <HTMLInputElement>event.target;
    const value = parseInt(target.value);
    let result = 0;
    const min = parseInt(target.min);
    const max = parseInt(target.max);
    if (!isNaN(value)) {
      if (value === max) {
        result = min;
      } else {
        result = value + 1;
      }
      this.setValue(target, result);
    } else {
      this.setValue(target, min);
    }
  }

  private onArrowDown(event: KeyboardEvent): void {
    event.preventDefault();
    const target = <HTMLInputElement>event.target;
    const value = parseInt(target.value);
    let result = 0;
    const min = parseInt(target.min);
    const max = parseInt(target.max);
    if (!isNaN(value)) {
      if (value === min) {
        result = max;
      } else {
        result = value - 1;
      }
      this.setValue(target, result);
    } else {
      this.setValue(target, max);
    }
  }

  private setValue(target: HTMLInputElement, newValue: number | null): void {
    if (newValue !== null) {
      const min = parseInt(target.min);
      const max = parseInt(target.max);
      let value = newValue;
      if (value < min) {
        value = max;
      }
      if (value > max) {
        value = min;
      }
      const result = value.toString().padStart(target.maxLength, "0");
      target.value = result;
    }
    this.updateControl();
  }

  private onArrowLeft(event: KeyboardEvent): void {
    const target = <HTMLInputElement>event.target;
    if (this.minutesRef && this.hoursRef) {
      const minutes = this.minutesRef.nativeElement;
      const hours = this.hoursRef.nativeElement;

      if (minutes === event.target && (target.selectionStart === 0 || target.selectionEnd === 2)) {
        minutes.blur();
        hours.focus();
        setTimeout(() => {
          hours.setSelectionRange(0, hours.value.length);
        }, 0);
      }
    }
  }

  private onArrowRight(event: KeyboardEvent): void {
    const target = <HTMLInputElement>event.target;
    if (this.minutesRef && this.hoursRef) {
      const minutes = this.minutesRef.nativeElement;
      const hours = this.hoursRef.nativeElement;
      if (hours === event.target && (target.selectionStart === 0 || target.selectionEnd === 2)) {
        hours.blur();
        minutes.focus();
        setTimeout(() => {
          minutes.setSelectionRange(0, minutes.value.length);
        }, 0);
      }
    }
  }

  private addValidators(control: FormControl<string | null>): void {
    if (this.regex) control.addValidators([PrefixValidator.regex(this.regex)]);
    if (this.required) control.addValidators([PrefixValidator.required()]);
    control.addValidators(PrefixValidator.time());
    control.updateValueAndValidity();
  }

  private updateControl(): void {
    if (this.hoursRef && this.minutesRef && this.control) {
      const hours = this.hoursRef.nativeElement.value;
      const minutes = this.minutesRef.nativeElement.value;
      this.control.markAsDirty();
      this.control.patchValue(hours || minutes ? `${hours}:${minutes}` : null);
    }
  }
}
