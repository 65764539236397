export enum Key {
  NUMPAD_DECIMAL = "NumpadDecimal",
  NUMPAD_ENTER = "NumpadEnter",
  NUMPAD_ADD = "NumpadAdd",
  NUMPAD_SUBTRACT = "NumpadSubtract",
  NUMPAD_MULTIPLY = "NumpadMultiply",
  NUMPAD_DEVIDE = "NumpadDevide",
  NUMPAD_LOCK = "NumLock",

  NUMPAD_0 = "Numpad0",
  NUMPAD_1 = "Numpad1",
  NUMPAD_2 = "Numpad2",
  NUMPAD_3 = "Numpad3",
  NUMPAD_4 = "Numpad4",
  NUMPAD_5 = "Numpad5",
  NUMPAD_6 = "Numpad6",
  NUMPAD_7 = "Numpad7",
  NUMPAD_8 = "Numpad8",
  NUMPAD_9 = "Numpad9",

  ARROW_UP = "ArrowUp",
  ARROW_DOWN = "ArrowDown",
  ARROW_LEFT = "ArrowLeft",
  ARROW_RIGHT = "ArrowRight",

  INSERT = "Insert",
  DELETE = "Delete",
  HOME = "Home",
  END = "End",
  PAGE_UP = "PageUp",
  PAGE_DOWN = "PageDown",
  PAUSE = "Pause",
  SCROLL_LOCK = "ScrollLock",

  F1 = "F1",
  F2 = "F2",
  F3 = "F3",
  F4 = "F4",
  F5 = "F5",
  F6 = "F6",
  F7 = "F7",
  F8 = "F8",
  F9 = "F9",
  F10 = "F10",
  F11 = "F11",
  F12 = "F12",

  ESCAPE = "Escape",
  BACKTICK = "Backquote",
  TAB = "TAB",
  CAPS_LOCK = "CapsLock",
  SHIFT = "ShiftLeft|ShiftRight",
  // SHIFT_LEFT = "ShiftLeft",
  // SHIFT_RIGHT = "ShiftRight",
  CONTROL = "ControlLeft|ControlRight",
  // CONTROL_LEFT = "ControlLeft",
  // CONTROL_RIGHT = "ControlRight",
  META = "MetaLeft|MetaRight",
  // META_LEFT = "MetaLeft",
  // META_RIGHT = "MetaRight",
  ALT = "AltLeft|AltRight",
  // ALT_LEFT = "AltLeft",
  // ALT_RIGHT = "AltRight",
  CONTEXT = "ContextMenu",
  ENTER = "Enter",
  BACKSLASH = "Backslash",
  BACKSPACE = "Backspace",
  EQUAL = "Equal",
  MINUS = "Minus",
  SPACE = "Space",
  COMMA = "Comma",
  PERIOD = "Period",
  SEMICOLON = "Semicolon",
  QUOTE = "Quote",
  BRACKET_LEFT = "BracketLeft",
  BRACKET_RIGHT = "BracketRight",

  DIGIT_1 = "Digit1",
  DIGIT_2 = "Digit2",
  DIGIT_3 = "Digit3",
  DIGIT_4 = "Digit4",
  DIGIT_5 = "Digit5",
  DIGIT_6 = "Digit6",
  DIGIT_7 = "Digit7",
  DIGIT_8 = "Digit8",
  DIGIT_9 = "Digit9",

  A = "KeyA",
  B = "KeyB",
  C = "KeyC",
  D = "KeyD",
  E = "KeyE",
  F = "KeyF",
  G = "KeyG",
  H = "KeyH",
  I = "KeyI",
  J = "KeyJ",
  K = "KeyK",
  L = "KeyL",
  M = "KeyM",
  N = "KeyN",
  O = "KeyO",
  P = "KeyP",
  Q = "KeyQ",
  R = "KeyR",
  S = "KeyS",
  T = "KeyT",
  U = "KeyU",
  V = "KeyV",
  W = "KeyW",
  X = "KeyX",
  Y = "KeyY",
  Z = "KeyZ",
}
