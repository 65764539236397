import { AfterViewInit, Component } from "@angular/core";
import { ALLOWED_CHARACTERS } from "src/config/allowed-characters.config";
import { PrefixValidator } from "../../PrefixValidator";
import { PrefixTextComponent } from "../../prefix-text.component";
import { TemplateTextComponent } from "../../templates/template-text/template-text.component";
import { MaxLength } from "../../types/MaxLength";

@Component({
  standalone: true,
  selector: "app-prefix-rrn",
  imports: [TemplateTextComponent],
  templateUrl: "./prefix-rrn.component.html",
  styleUrl: "./prefix-rrn.component.less",
})
export class PrefixRrnComponent extends PrefixTextComponent implements AfterViewInit {
  public maxlength: MaxLength;

  public constructor() {
    super();
    this.maxlength = 11;
    this.characters = ALLOWED_CHARACTERS["rrn"];
  }

  public ngAfterViewInit(): void {
    this.control.addValidators(PrefixValidator.rrn());
    this.control.updateValueAndValidity();
  }
}
