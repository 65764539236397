@if (timer && time !== null && ms !== null) {
  @switch (type) {
    @case ("bar") {
      <mat-progress-bar [color]="'primary'" [mode]="'determinate'" [value]="(100 / ms) * time" />
    }
    @case ("spinner") {
      <mat-progress-spinner [color]="'primary'" [mode]="'determinate'" [value]="(100 / ms) * time" [diameter]="spinnerDiameter" />
    }
    @case ("default") {
      <span> {{ time / 1000 }}</span>
    }
  }
}
