import { FormCategory } from "./form-category";

export enum Mode {
  VIEW,
  EDIT,
}

export enum State {
  VALID = "VALID",
  INVALID = "INVALID",
  LOADING = "LOADING",
  BUSY = "BUSY",
  SAVED = "SAVED",
}

export type FormFields = Map<number, FormCategory>;

export interface Page {
  fields: FormFields;
  title: string | null;
}
