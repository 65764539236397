<ul>
  @for (button of buttons; track $index) {
    @switch (button.id) {
      @case ("1") {
        <li class="action">
          <button button-id="1" mat-stroked-button [disabled]="disabled" (click)="sendSave()">{{ button.description }}</button>
        </li>
      }
      @case ("2") {
        <li class="action">
          <button button-id="2" mat-stroked-button [disabled]="disabled" (click)="sendSave()">{{ button.description }}</button>
        </li>
      }
      @case ("3") {
        <li class="action">
          <button button-id="3" mat-stroked-button [disabled]="disabled" (click)="sendSave()">{{ button.description }}</button>
        </li>
      }
      @case ("5") {
        <li class="action">
          <button button-id="4" mat-stroked-button [disabled]="disabled" (click)="sendSnapshot(button.ffwdaction, button.dlname)">{{ button.description }}</button>
        </li>
      }
      @case ("6") {
        <li class="action">
          <button button-id="6" mat-stroked-button [disabled]="disabled" (click)="sendRedirect(button.ffwdaction)">{{ button.description }}</button>
        </li>
      }
      @case ("7") {
        <li class="action">
          <button button-id="7" mat-stroked-button [disabled]="disabled" (click)="sendDelete(null)" [disabled]="rowsSelected !== undefined && rowsSelected < 1">
            {{ button.description }}
          </button>
        </li>
      }
      @case ("8") {
        <li class="action">
          <button button-id="8" mat-stroked-button [disabled]="disabled" (click)="sendAction(button.ffwdaction)">{{ button.description }}</button>
        </li>
      }
      @case ("9") {
        @for (subbutton of button.snapshottypes; track $index) {
          <li class="action">
            <button button-id="9" mat-stroked-button [disabled]="disabled" (click)="sendSnapshot(subbutton.ffwdaction, null)">{{ subbutton.description }}</button>
          </li>
        }
      }
      @case ("10") {
        <li class="action">
          <button button-id="10" mat-stroked-button [disabled]="disabled" (click)="sendAction(button.ffwdaction)">{{ button.description }}</button>
        </li>
      }
      @case ("11") {
        <li class="action">
          <button button-id="11" mat-stroked-button [disabled]="disabled" (click)="sendSave(button.dlname, true)">{{ button.description }}</button>
        </li>
      }
      @case ("12") {
        <li class="action">
          <button button-id="12" mat-stroked-button [disabled]="disabled" (click)="sendDownload(button.ffwdaction, button.dlname)">{{ button.description }}</button>
        </li>
      }
      @case ("13") {
        @for (button of button.snapshottypes; track $index) {
          <li class="action">
            <button button-id="13" mat-stroked-button [disabled]="disabled" (click)="sendAction(button.ffwdaction)">{{ button.description }}</button>
          </li>
        }
      }
      @case ("14") {
        <li class="action">
          <button button-id="14" mat-stroked-button [disabled]="disabled" (click)="sendReload()">{{ button.description }}</button>
        </li>
      }
      @case ("15") {
        <li class="action">
          <button button-id="15" mat-stroked-button [disabled]="disabled" (click)="sendDownload(null, button.dlname)">{{ button.description }}</button>
        </li>
      }
      @case ("16") {
        <li class="action">
          <button button-id="16" mat-stroked-button [disabled]="disabled" (click)="sendArchive()">{{ button.description }}</button>
        </li>
      }

      @case ("17") {
        <li class="action">
          <button button-id="17" mat-stroked-button [disabled]="disabled" (click)="sendAction(button.ffwdaction)">{{ button.description }}</button>
        </li>
      }

      @default {
        <button>Error unknown button id {{ button.id }}</button>
      }
    }
  }
</ul>
