@if (data) {
  <div class="dialog-container">
    <form (submit)="makeBookmark(input.value)">
      <app-template-text [control]="input" />
      <button mat-stroked-button type="submit" [disabled]="input.invalid">+</button>
    </form>
    <ul class="custom-scroll">
      @for (bookmarkGroup of bookmarks.bookmarkGroup$.value | keyvalue; track $index) {
        <li (click)="!loading && makeBookmark(bookmarkGroup.value.Description)">
          {{ bookmarkGroup.value.Description }}
          @if (isInBookmarkGroup(bookmarkGroup.value.Description)) {
            <span><mat-icon>check</mat-icon></span>
          }
        </li>
      } @empty {
        <li (click)="!loading && makeBookmark('DIALOG.BOOKMARKS.CREATE.NEW_GROUP_NAME')">
          {{ "DIALOG.BOOKMARKS.CREATE.NEW_GROUP_NAME" | translate }}
          @if (isInBookmarkGroup("DIALOG.BOOKMARKS.CREATE.NEW_GROUP_NAME")) {
            <span><mat-icon>check</mat-icon></span>
          }
        </li>
      }
    </ul>
  </div>
}
