import { Component } from "@angular/core";
import { DialogTemplateComponent } from "../dialog-template.component";

@Component({
  standalone: true,
  selector: "app-dialog-main",
  templateUrl: "./main.component.html",
  styleUrl: "./main.component.less",
})
export class MainComponent extends DialogTemplateComponent {
  public constructor() {
    super();
  }
}
