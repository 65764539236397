@if (isLoading$ | async) {
} @else {
  <div class="wrapper">
    <div class="container custom-scroll">
      @if (content) {
        <app-content [content]="content" />
      }
    </div>
  </div>
}
