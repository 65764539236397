import { TableField } from "src/app/components/default/table/table-field";
import { TABLE_TEMPLATE } from "src/app/components/default/table/table-template";
import { TableResponseGroup, TableResponseRow } from "src/app/components/default/table/table.interface";
import { Tile, TileContentPart, TileRow } from "src/app/components/default/tile/tile.interface";
import { ApplicationService } from "src/services/application.service";
import { HttpService } from "src/services/http.service";
import { ContentTable } from "./content-table";

export class ContentTile extends ContentTable {
  public tiles: Tile[];

  public constructor(id: string, http: HttpService, application: ApplicationService) {
    super(id, http, application, "Tile");
    this.tiles = [];
  }

  public parseTiles(data: TileContentPart): Tile[] {
    const incoming = [...this.getRowsFromGroups(data.data.groups), ...data.data.rows];
    const tiles: Tile[] = [];

    for (const row of incoming) {
      const content: TileRow[] = [];
      for (const [index, field] of row.fields.entries()) {
        let tooltip: string | null = null;
        tooltip = field.tt.map((tooltip) => tooltip.value).join("<br />");
        const extras = this.getExtras(field);
        const value = this.sanitizeValue(field);
        content.push({
          header: this.headers.get(index) ?? null,
          field: new TableField(TABLE_TEMPLATE[field.pfx] || field.pfx || TABLE_TEMPLATE.DEFAULT, value, tooltip, extras),
        });
      }

      if (content.length)
        tiles.push({
          title: `${content[0].field.value.value}` ?? "UNKNOWN",
          content,
        });
    }
    return tiles;
  }

  private getRowsFromGroups(groups: TableResponseGroup[]): TableResponseRow[] {
    const rows: TableResponseRow[] = [];
    for (const group of groups) {
      if (group.groups.length) this.getRowsFromGroups(group.groups);
      rows.push(...group.rows);
    }
    return rows;
  }
}
