import { Component, inject } from "@angular/core";
import { DefaultComponent } from "src/app/default.component";
import { SnackbarService } from "../snackbar.service";
import { SnackbarTemplateComponent } from "../template/snackbar-template.component";
@Component({
  standalone: true,
  selector: "app-snackbar",
  imports: [],
  template: "",
})
export class SnackbarComponent<T = unknown> extends DefaultComponent {
  public snackbar: SnackbarService;
  public data: T | null;
  public ref: SnackbarTemplateComponent | null;

  public constructor() {
    super();
    this.snackbar = inject(SnackbarService);
    this.data = null;
    this.ref = null;
  }

  public close(): void {
    const ref = this.ref;
    if (ref) {
      ref.close();
    } else {
      throw new Error("Could not find snackbar template reference.");
    }
  }
}
