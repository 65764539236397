import { Component } from "@angular/core";
import { ALLOWED_CHARACTERS } from "src/config/allowed-characters.config";
import { PrefixTextComponent } from "../../prefix-text.component";
import { TemplateTextComponent } from "../../templates/template-text/template-text.component";

@Component({
  standalone: true,
  selector: "app-prefix-gk1",
  imports: [TemplateTextComponent],
  templateUrl: "./prefix-gk1.component.html",
  styleUrl: "./prefix-gk1.component.less",
})
export class PrefixGk1Component extends PrefixTextComponent {
  public constructor() {
    super();
    this.post = false;
    this.hide = true;
    this.characters = ALLOWED_CHARACTERS["gk1"];
  }
}
