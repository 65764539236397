<div class="container-fluid">
  <div class="row">
    <div class="chart-container col-md">
      @for (chart of charts; track $index) {
        <div class="chart">
          <span>{{ chart.title }}</span>
          <canvas #chart></canvas>
        </div>
      }
    </div>
  </div>
</div>
