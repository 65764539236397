<app-template-text
  [counter]="counter"
  [disabled]="disabled"
  [characters]="characters"
  [value]="value"
  [maxlength]="maxlength"
  [required]="required"
  [control]="control"
  [label]="label"
/>
