import { JsonPipe } from "@angular/common";
import { AfterViewInit, Component, ElementRef, EventEmitter, Input, OnInit, Output, ViewChild } from "@angular/core";
import { FormControl, ReactiveFormsModule } from "@angular/forms";
import { GoogleMap, GoogleMapsModule, MapMarker, MapMarkerClusterer } from "@angular/google-maps";
import { MatButtonModule } from "@angular/material/button";
import { MatIconModule } from "@angular/material/icon";
import { MarkerClusterer } from "@googlemaps/markerclusterer";
import { DefaultComponent } from "src/app/default.component";
import { ORGANISATION } from "src/organisation/organisation";
import { PrefixTemplate } from "../../PrefixTemplate";
import { MaxLength } from "../../types/MaxLength";
import { MinLength } from "../../types/MinLength";

export type Coordinate = google.maps.LatLngLiteral | google.maps.LatLng | google.maps.LatLngAltitudeLiteral;
export interface Polygon {
  index: number;
  vertices: Coordinate[];
}

export interface MapOptions {
  map?: google.maps.MapOptions;
  marker?: google.maps.marker.AdvancedMarkerElement;
}

export interface Marker {
  location: Coordinate;
  data: unknown;
}

interface ActiveMarker {
  element: google.maps.marker.AdvancedMarkerElement;
  context: Marker;
}

@Component({
  selector: "app-template-map",
  standalone: true,
  imports: [ReactiveFormsModule, GoogleMapsModule, GoogleMap, MapMarker, MapMarkerClusterer, MatButtonModule, MatIconModule, JsonPipe],
  templateUrl: "./template-map.component.html",
  styleUrl: "./template-map.component.less",
})
export class TemplateMapComponent extends DefaultComponent implements PrefixTemplate<never>, OnInit, AfterViewInit {
  @Input({ required: true })
  public control: FormControl<never | null> | null;

  @Input()
  public location: Coordinate | null;

  @Input()
  public label: string | null;

  @Input()
  public markers: Marker[];

  @Input()
  public options: MapOptions | null;

  @Input()
  public value: never | null;

  @Input()
  public minlength: MinLength;

  @Input()
  public maxlength: MaxLength;

  @Input()
  public required: boolean;

  @Input()
  public disabled: boolean;

  @Output()
  public event: EventEmitter<null>;

  @ViewChild("container")
  public container: ElementRef<HTMLDivElement> | null;

  public map: google.maps.Map | null;
  public mapMarkers: google.maps.marker.AdvancedMarkerElement[];
  public mapOptions: google.maps.MapOptions;
  public markerOptions: google.maps.marker.AdvancedMarkerElementOptions;

  public activeMarker: ActiveMarker | null;

  public constructor() {
    super();
    this.control = null;
    this.value = null;
    this.minlength = null;
    this.maxlength = null;
    this.required = false;
    this.disabled = false;

    this.container = null;
    this.map = null;
    this.activeMarker = null;

    this.markers = [];
    this.mapMarkers = [];

    this.options = null;
    this.location = null;
    this.label = null;

    if (!ORGANISATION.MAP) throw new Error("Cannot use template-map without map config in organisation!");

    this.mapOptions = {
      zoom: 7,
      streetViewControl: false,
      mapTypeControl: false,
      mapId: ORGANISATION.MAP.ID,
    };

    this.markerOptions = {};

    this.event = new EventEmitter();
  }

  public ngOnInit(): void {
    const control = this.control;
    if (control) {
      this.addValidators(control);
    } else {
      throw new Error("Undefined control");
    }

    this.mapOptions = {
      ...this.mapOptions,
      ...this.options?.map,
    };

    this.markerOptions = {
      ...this.markerOptions,
      ...this.options?.marker,
    };
  }

  public async ngAfterViewInit(): Promise<void> {
    if (this.container) {
      const map = new google.maps.Map(this.container.nativeElement, this.mapOptions);
      const markers = this.markers.map((context: Marker) => {
        const marker = new google.maps.marker.AdvancedMarkerElement({
          position: context.location,
        });
        marker.addListener("click", () => this.onMarkerClick(marker, context));
        return marker;
      });

      new MarkerClusterer({ markers, map, algorithmOptions: { maxZoom: 25 } });
      map.addListener("click", this.onMapClick);

      this.map = map;
      this.mapMarkers = markers;
      this.moveTo(this.location ?? (this.markers.length ? this.markers[0].location : { lat: 0, lng: 0 }));
    } else {
      throw new Error("Could not find map container.");
    }
  }

  public onMapClick(event: google.maps.MapMouseEvent): void {
    console.log("map click event => ", {
      lng: event.latLng?.lng(),
      lat: event.latLng?.lat(),
    });
  }

  public onMarkerClick(element: google.maps.marker.AdvancedMarkerElement, context: Marker): void {
    console.log("marker click event => ", {
      element,
      context,
    });

    if (element.position) {
      this.activeMarker = {
        element,
        context,
      };
      this.moveTo(element.position);
    } else {
      throw new Error("Could not get marker position.");
    }
  }

  public closeMarker(): void {
    this.activeMarker = null;
  }

  private moveTo(position: Coordinate): void {
    if (this.map) {
      this.map.panTo(position);
    } else {
      console.error("COULD NOT FIND MAP!");
    }
  }

  private addValidators(control: FormControl<never | null>): void {
    control.updateValueAndValidity();
  }
}
