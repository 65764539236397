import { Component, Input } from "@angular/core";
import { ALLOWED_CHARACTERS } from "src/config/allowed-characters.config";
import { PrefixComponent } from "../../prefix.component";
import { Direction } from "../../templates/template-checkbox/template-checkbox.component";
import { RadioOption, TemplateRadioComponent } from "../../templates/template-radio/template-radio.component";

@Component({
  standalone: true,
  selector: "app-prefix-ids",
  imports: [TemplateRadioComponent],
  templateUrl: "./prefix-ids.component.html",
  styleUrl: "./prefix-ids.component.less",
})
export class PrefixIdsComponent extends PrefixComponent<string> {
  @Input()
  public options: RadioOption[];
  public direction: Direction;

  public constructor() {
    super();
    this.options = [];
    this.direction = "horizontal";
    this.characters = ALLOWED_CHARACTERS["ids"];
  }
}
