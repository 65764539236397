@if (control) {
  <div [class]="['progress-container', 'type-' + type]">
    <div class="progress-visual">
      @switch (type) {
        @case (1) {
          <mat-progress-bar mode="determinate" [value]="progress" />
        }
        @case (2) {
          <mat-progress-spinner mode="determinate" [diameter]="18" [strokeWidth]="3" [value]="progress" />
        }
      }
    </div>
    <div class="progress-textual">
      @switch (unit) {
        @case ("%") {
          {{ progress | number: "1.0-0" : "nl" }}%
        }
        @default {
          {{ prefix }} {{ control.value | number: "" : "nl" }} {{ unit }} ({{ progress | number: "1.0-0" : "nl" }}%)
        }
      }
    </div>
  </div>
}
