@if (control) {
  <div class="preview-container">
    @if (!disabled) {
      @if (edit) {
        <div class="input-container">
          @switch (type) {
            @case (0) {
              <app-template-text [value]="value" [control]="control" />
            }

            @case (1) {
              <app-template-textarea [control]="control" [value]="value" />
            }

            @default {
              <span>invalid input type: {{ type }}</span>
            }
          }
        </div>
      }
    } @else if (valid | async) {
      <app-template-html class="fake-link" [value]="value" [control]="control" (click)="enlarge()" />
    } @else {
      <app-template-html [value]="value" [control]="control" />
    }

    <div class="output-container" [ngClass]="{ empty, valid: valid | async }">
      <canvas id="canvas" #canvas></canvas>

      @if (!disabled) {
        <canvas id="thumbnail" #thumbnail></canvas>
        <div class="enlarge-container" (click)="enlarge()">
          <label>{{ "FORM.TEMPLATE.PREVIEW.LABEL" | translate }}</label>
        </div>

        <div #error class="error-message">
          <ng-content />
        </div>
      }
    </div>
  </div>
}
