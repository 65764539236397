export type TableSortDirection = "DESC" | "ASC" | "INITIAL";

export interface TableHeaderOptions {
  width?: string | number;
  icon?: string;
}

export class TableHeader {
  public label: string;
  public visible: boolean;
  public template: string | null;
  public sort: {
    direction: TableSortDirection;
    column: string | null;
  } | null;

  public options: TableHeaderOptions;

  public constructor(label: string, options: TableHeaderOptions = {}) {
    this.label = label;
    this.visible = true;
    this.sort = null;
    this.template = null;

    this.options = {
      width: "initial",
      icon: "",
      ...options,
    };
  }
}
