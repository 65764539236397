import { Component } from "@angular/core";
import { ALLOWED_CHARACTERS } from "src/config/allowed-characters.config";
import { PrefixComponent } from "../../prefix.component";
import { TemplateNumberComponent } from "../../templates/template-number/template-number.component";

@Component({
  standalone: true,
  selector: "app-prefix-bed",
  imports: [TemplateNumberComponent],
  templateUrl: "./prefix-bed.component.html",
  styleUrl: "./prefix-bed.component.less",
})
export class PrefixBedComponent extends PrefixComponent<string> {
  public commas: number;

  public constructor() {
    super({ updateOn: "blur" });
    this.commas = 2;
    this.characters = ALLOWED_CHARACTERS["bed"];
  }
}
