import { Component } from "@angular/core";
import { ALLOWED_CHARACTERS } from "src/config/allowed-characters.config";
import { PrefixTextComponent } from "../../prefix-text.component";
import { TemplateTextareaComponent } from "../../templates/template-textarea/template-textarea.component";
import { MaxLength } from "../../types/MaxLength";

@Component({
  standalone: true,
  selector: "app-prefix-bs2",
  imports: [TemplateTextareaComponent],
  templateUrl: "./prefix-bs2.component.html",
  styleUrl: "./prefix-bs2.component.less",
})
export class PrefixBs2Component extends PrefixTextComponent {
  public maxlength: MaxLength;

  public constructor() {
    super();
    this.maxlength = 500;
    this.characters = ALLOWED_CHARACTERS["bs2"];
  }
}
