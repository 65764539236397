import { ChartInput } from "src/app/components/default/chart/chart.component";
import { ChartContentPart, ChartLine } from "src/app/components/default/chart/chart.interface";
import { ContentPart } from "../content-part";

export class ContentChart extends ContentPart {
  public charts: ChartInput[];

  public constructor(id: string) {
    super(id, "Chart");
    this.charts = [];
  }

  /**
   * Create chart input
   * @param title
   * @param input
   * @returns
   */
  public getChart(title: string, input: ChartContentPart["rows"]): ChartInput {
    return {
      title,
      lines: this.parseLines(input),
    };
  }

  /**
   * Parse headers and nodes into lines
   * @param header
   * @param nodes
   */
  private parseLines(rows: ChartContentPart["rows"]): ChartLine[] {
    const lines: (Partial<ChartLine> & { title: string })[] = [];

    for (const row of rows) {
      const line = lines.find((line) => line.title === row.fields[1].value) ?? { title: row.fields[1].value };
      if (!lines.find((compareLine) => compareLine.title === line.title)) lines.push(line);

      if (!line.color) line.color = <[number, number, number]>row.fields[2].value.split(",").map((value) => Number(value));

      const node = <[string, number]>[row.fields[3].value, Number(row.fields[4].value)];
      if (line.nodes) {
        line.nodes.push(node);
        continue;
      }
      line.nodes = [node];
    }

    return lines as ChartLine[];
  }
}
