import { Component } from "@angular/core";
import bwipjs from "@bwip-js/browser";
import { ALLOWED_CHARACTERS } from "src/config/allowed-characters.config";
import { PrefixTextComponent } from "../../prefix-text.component";
import { TemplatePreviewComponent } from "../../templates/template-preview/template-preview.component";

@Component({
  selector: "app-prefix-bae",
  standalone: true,
  imports: [TemplatePreviewComponent],
  templateUrl: "./prefix-bae.component.html",
  styleUrl: "./prefix-bae.component.less",
})
export class PrefixBaeComponent extends PrefixTextComponent {
  public constructor() {
    super({ updateOn: "blur" });
    this.characters = ALLOWED_CHARACTERS["bae"];
  }

  public setBarcode(context: TemplatePreviewComponent): void {
    const canvas = context.getCanvas();
    context.updateCanvas((resolve, reject) => {
      try {
        bwipjs.toCanvas(canvas, { text: this.control.value || "", bcid: "ean13", includetext: true }).toDataURL();
        resolve("success");
      } catch (err) {
        reject("unable to load barcode.");
      }
    });
  }
}
