<div id="loggedout-wrapper" [style.background]="'url(../../../assets/organisation/images/background.png)'">
  <div id="loggedout-container">
    <div class="header">
      <div class="logo">
        <img src="../../../assets/organisation/images/logo-auth.svg" [alt]="'IMAGE_ALT.LOGO' | translate" />
      </div>
    </div>
    <div class="inner">
      <div class="message">
        <p>{{ "LOGGEDOUT.STATUS" | translate }}</p>
        <p>{{ "LOGGEDOUT.MESSAGE" | translate }}</p>
      </div>

      <div class="link">
        <a [routerLink]="'/login'">
          {{ "LOGGEDOUT.TOLOGIN" | translate }}
        </a>
      </div>
    </div>
  </div>
</div>
