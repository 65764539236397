import { ComponentType } from "@angular/cdk/portal";
import { AfterViewInit, Component, ComponentRef, EventEmitter, ViewChild, ViewContainerRef, inject } from "@angular/core";
import { MAT_DIALOG_DATA, MatDialogConfig } from "@angular/material/dialog";
import { take } from "rxjs";
import { DefaultComponent } from "src/app/default.component";
import { DialogComponent } from "../impl/dialog.component";

export interface DialogTitle {
  label: string;
  params?: Record<string, unknown>;
}

@Component({
  standalone: true,
  selector: "app-dialog-template",
  template: "",
})
export class DialogTemplateComponent extends DefaultComponent implements AfterViewInit {
  @ViewChild("renderer", { read: ViewContainerRef })
  public renderer: ViewContainerRef | null;

  public id: string | null;
  public config: MatDialogConfig | null;
  public type: ComponentType<DialogComponent> | null;
  public component: ComponentRef<DialogComponent> | null;
  public oninstance: EventEmitter<DialogComponent>;
  public data: unknown;

  public constructor() {
    super();
    this.renderer = null;
    this.id = null;
    this.config = null;
    this.type = null;
    this.component = null;
    this.oninstance = new EventEmitter();
    this.data = inject(MAT_DIALOG_DATA);

    this.oninstance.pipe(take(1)).subscribe((instance) => (this.id = instance.uuid));
  }

  public ngAfterViewInit(): void {
    const renderer = this.renderer;
    const type = this.type;

    if (renderer && type) {
      this.component = renderer.createComponent<DialogComponent>(type);
      const instance = this.component.instance;
      this.oninstance.emit(instance);
    } else {
      throw new Error("Could not find renderer");
    }
  }
}
