import { AfterViewChecked, ApplicationRef, Component, ComponentRef, ElementRef, EnvironmentInjector, createComponent, inject } from "@angular/core";
import { DefaultComponent } from "src/app/default.component";
import { SnackbarComponent } from "../impl/snackbar.component";
import { SnackbarConfig, SnackbarService } from "../snackbar.service";

@Component({
  standalone: true,
  selector: "app-snackbar-template",
  template: "",
})
export class SnackbarTemplateComponent<DATA = unknown> extends DefaultComponent implements AfterViewChecked {
  public snackbar: SnackbarService;
  public host: ElementRef;
  private injector: EnvironmentInjector;
  private appRef: ApplicationRef;

  public ref: ComponentRef<SnackbarComponent> | null;
  public config: SnackbarConfig<DATA> | null;
  public component: SnackbarComponent | null;

  public constructor() {
    super();
    this.snackbar = inject(SnackbarService);
    this.host = inject(ElementRef);
    this.injector = inject(EnvironmentInjector);
    this.appRef = inject(ApplicationRef);

    this.ref = null;
    this.config = null;
    this.component = null;
  }

  public ngAfterViewChecked(): void {
    const config = this.config;
    const host = <HTMLElement>this.host.nativeElement;

    if (!this.component && config && host) {
      const ref = createComponent(config.type, {
        environmentInjector: this.injector,
      });
      this.ref = ref;
      this.component = ref.instance;
      this.component.data = config.data;
      this.component.ref = this;

      this.setAutoClose();

      host.appendChild(ref.location.nativeElement);
      this.appRef.attachView(ref.hostView);
      this.snackbar.instances.set(this.uuid, this);
    }
  }

  /**
   * Close snackbar
   */
  public close(): void {
    this.snackbar.close(this.uuid);
  }

  /**
   * Set snackbar auto close on duration
   */
  private setAutoClose(): void {
    const config = this.config;

    if (config && config.duration !== null) {
      setTimeout(() => {
        this.close();
      }, config.duration);
    }
  }
}
