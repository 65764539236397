import { Component, Input } from "@angular/core";
import { MatIconModule } from "@angular/material/icon";
import { ALLOWED_CHARACTERS } from "src/config/allowed-characters.config";
import { IconReplacePipe } from "src/pipes/icon-replace.pipe";
import { PrefixComponent } from "../../prefix.component";

@Component({
  standalone: true,
  selector: "app-prefix-xja",
  imports: [MatIconModule, IconReplacePipe],
  templateUrl: "./prefix-xja.component.html",
  styleUrl: "./prefix-xja.component.less",
})
export class PrefixXjaComponent extends PrefixComponent<boolean> {
  @Input()
  public label: string | null;

  public constructor() {
    super();
    this.label = null;
    this.disabled = false;
    this.post = false;
    this.characters = ALLOWED_CHARACTERS["xja"];
  }
}
