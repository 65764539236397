import { ORGANISATION } from "src/organisation/organisation";

const api = ORGANISATION.API;

export const ROUTES_CONFIG = {
  reloginUrl: `${api}/security/relogin`,
  loginUrl: `${api}/security/login`,
  loginJwtUrl: `${api}/security/loginwithjwt`,
  checkotpURL: `${api}/security/checkotp`,
  contentUrl: `${api}/content`,
  logoffUrl: `${api}/security/logoff`,
  changeRoleUrl: `${api}/security/changeRole`,
  changeWorkspaceUrl: `${api}/security/changeWorkspace`,
  checkSessionUrl: `${api}/security/checkSession`,
  headerurl: `${api}/header`,
  metadataurl: `${api}/metadata`,
  unsecureurl: `${api}/unsecure`,
  menuurl: `${api}/menu`,
  attachmenturl: `${api}/attachment`,
  bookmarksurl: `${api}/bookmarks`,
  cockpiturl: `${api}/cockpit`,
  forgotpasswordurl: `${api}/forgotpassword`,
  resetpasswordurl: `${api}/resetpassword`,
  approvelocationurl: `${api}/approvelocation`,
  actionurl: `${api}/action`,
  dynactionUrl: `${api}`,
  changePasswordURL: `${api}/changepassword`,
  conferenceURL: `${api}/conference`,
  conferenceUsers: `${api}/conference/getconferenceusers`,
  conferenceRooms: `${api}/conference/getmijngespreksruimtes`,
  conferenceHistory: `${api}/conference/getgespreksruimtehistory`,
  conferenceCreateRoom: `${api}/conference/creategespreksruimte`,
  conferenceAddUserToRoom: `${api}/conference/addusertogespreksruimte`,
  translation: `${api}/translation`,
  qrcode: `${api}/unsecure?datatypeid=getloginQR`,
  graphql: `${api}/graphql`,
  scheduleUrl: `${api}/schedules`,
};
