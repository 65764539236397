import { Component, Input, OnDestroy, OnInit, inject } from "@angular/core";
import { TranslateService } from "@ngx-translate/core";
import { Subscription } from "rxjs";
import { FormCategory } from "src/app/components/default/form/form-category";
import { FormField } from "src/app/components/default/form/form-field";
import { FormComponent } from "src/app/components/default/form/form.component";
import { FormFields } from "src/app/components/default/form/form.definition";
import { FormFieldResponse, GridValue, GridValueResponse, JsnSchema } from "src/app/components/default/form/form.interface";
import { ContentForm } from "src/app/secure/content/impl/content-form";
import { ApplicationService } from "src/services/application.service";
import { HttpService } from "src/services/http.service";
import { SessionService } from "src/services/session.service";
import { DialogService } from "../../../dialog/dialog.service";
import { PrefixComponent } from "../../prefix.component";

@Component({
  selector: "app-prefix-jsn",
  standalone: true,
  imports: [FormComponent],
  templateUrl: "./prefix-jsn.component.html",
  styleUrl: "./prefix-jsn.component.less",
})
export class PrefixJsnComponent extends PrefixComponent<string> implements OnInit, OnDestroy {
  @Input()
  public schema: JsnSchema;

  public fields: FormFields;

  private http: HttpService;
  private application: ApplicationService;
  private translate: TranslateService;
  private session: SessionService;
  private dialog: DialogService;

  private form: ContentForm;

  private fieldSubscriptions: Subscription;

  public constructor() {
    super();
    this.schema = {};
    this.fields = new Map();

    this.http = inject(HttpService);
    this.application = inject(ApplicationService);
    this.translate = inject(TranslateService);
    this.session = inject(SessionService);
    this.dialog = inject(DialogService);

    this.form = new ContentForm(this.uuid, this.http, this.application, this.session, this.translate, this.dialog);
    this.fieldSubscriptions = new Subscription();
  }

  public ngOnInit(): void {
    if (this.value) {
      const value = JSON.parse(this.value);
      this.fields = this.parseObject(value);
      for (const [, category] of this.fields) {
        for (const [, field] of category.fields) {
          switch (field.template) {
            case "gri": {
              this.fieldSubscriptions.add(
                field.value.subscribe((_value: unknown) => {
                  this.updateJsnValue(
                    field,
                    (<GridValue[]>_value).filter((v) => v.checked).map((v) => v.id),
                  );
                }),
              );
              break;
            }
            default: {
              this.fieldSubscriptions.add(field.value.subscribe((_value) => this.updateJsnValue(field, _value)));
            }
          }
        }
      }
    }
  }

  public ngOnDestroy(): void {
    super.ngOnDestroy();
    this.fieldSubscriptions.unsubscribe();
  }

  public updateJsnValue(field: FormField<unknown, Record<string, unknown>>, newValue: unknown): void {
    const value = this.value ? JSON.parse(this.value) : {};

    let key = field.name;
    if (key.substring(3) in value) {
      key = key.substring(3);
    }

    if (!value[key] || value[key] != newValue) {
      value[key] = newValue;
      if (JSON.stringify(JSON.parse(this.value || "{}")) !== JSON.stringify(value)) {
        this.control.markAsTouched();
        this.control.markAsDirty();
        this.control.patchValue(JSON.stringify(value));
      }
    }
  }

  private parseObject(obj: Record<string, unknown>): Map<number, FormCategory> {
    const fields: FormField[] = [];

    for (const [key, schema] of Object.entries(this.schema)) {
      const value = obj[key];

      switch (schema.pfx) {
        case "gri": {
          schema["gridvalues"] = <GridValueResponse[]>(<[]>schema["gridvalues"]).map((gridvalue: { value: string; description: string }) => ({
            checked: `${(<string[]>value)?.includes(gridvalue.description) ?? false}`,
            description: gridvalue.description,
            value: gridvalue.description,
          }));
          break;
        }
      }

      let fieldName = key;
      if (!fieldName.startsWith(schema.pfx)) {
        fieldName = `${schema.pfx}${key}`;
      }

      const field = this.form
        .parseFields([
          <FormFieldResponse>{
            value,
            name: fieldName,
            hidden: "false",
            tooltips: <unknown[]>[],
            ...schema,
          },
        ])
        .get(0)
        ?.fields.get(0);

      if (field) {
        fields.push(field);
      }
    }

    const category = new FormCategory("jsn", true, true, undefined);
    category.setFields(new Map(fields.map((v, i) => [i, v])));

    return new Map([[0, category]]);
  }
}
