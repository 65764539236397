import { Component } from "@angular/core";
import { PrefixTextComponent } from "../../prefix-text.component";
import { TemplateHexadecimalComponent } from "../../templates/template-hexadecimal/template-hexadecimal.component";

@Component({
  selector: "app-prefix-chx",
  standalone: true,
  imports: [TemplateHexadecimalComponent],
  templateUrl: "./prefix-chx.component.html",
  styleUrl: "./prefix-chx.component.less",
})
export class PrefixChxComponent extends PrefixTextComponent {
  public constructor() {
    super();
  }
}
