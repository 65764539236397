@if (control) {
  <div class="select-container">
    @if (!disabled && options.length !== 1) {
      @if (!groups.length) {
        <mat-select [multiple]="multiple" [formControl]="control">
          @for (option of options; track $index) {
            <mat-option [value]="option.value">
              <span [innerHTML]="option.label | iconReplace"></span>
            </mat-option>
          }
        </mat-select>
      } @else {
        <mat-select [multiple]="multiple" [formControl]="control">
          @for (option of options; track $index) {
            <mat-option [value]="option.value">
              <span [innerHTML]="option.label | iconReplace"></span>
            </mat-option>
          }
          @for (group of groups; track $index) {
            <mat-optgroup [label]="group.group">
              @for (option of group.options; track $index) {
                <mat-option [value]="option.value">
                  <span [innerHTML]="option.label | iconReplace"></span>
                </mat-option>
              }
            </mat-optgroup>
          }
        </mat-select>
      }
    } @else if (value) {
      <app-template-html [value]="getOption(value)?.label" [control]="control" />
    } @else {
      <span>-</span>
    }
  </div>
}
