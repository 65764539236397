import { Component, OnInit } from "@angular/core";
import { ALLOWED_CHARACTERS } from "src/config/allowed-characters.config";
import { PrefixComponent } from "../../prefix.component";
import { TemplateNumberComponent } from "../../templates/template-number/template-number.component";
import { Max } from "../../types/Max";
import { Min } from "../../types/Min";

@Component({
  standalone: true,
  selector: "app-prefix-gtp",
  imports: [TemplateNumberComponent],
  templateUrl: "./prefix-gtp.component.html",
  styleUrl: "./prefix-gtp.component.less",
})
export class PrefixGtpComponent extends PrefixComponent<string> implements OnInit {
  public max: Max;
  public min: Min;

  public constructor() {
    super({ updateOn: "blur" });
    this.max = 999999999999;
    this.min = 0;
    this.characters = ALLOWED_CHARACTERS["gtp"];
  }
}
