<div class="component-container" [ngClass]="{ 'full-size': fullsize, grid: isGrid }">
  @for (c of components | keyvalue; track $index) {
    <div
      #component
      class="component custom-scroll"
      tabindex="0"
      (scroll)="scroll($event)"
      [class]="c.value.type | lowercase"
      [attr.size]="c.value.size"
      [style]="c.value.gridStyle"
    >
      @switch (c.value.type) {
        @case ("Form") {
          <app-form
            [title]="c.value.title"
            [fields]="c.value['fields']"
            [attachments]="c.value['attachments']"
            (event)="c.value['onEvent']($event)"
            (statechange)="c.value['onStateChange']($event)"
            (openAttachment)="c.value['openAttachments']($event)"
            [options]="c.value['options']"
            [buttons]="c.value['buttons']"
            [mode]="c.value['mode']"
            [credits]="c.value['credits']"
            [collection]="c.value['fieldCollection']"
            [buttonenabled]="c.value['buttonenabled']"
            [dialogRef]="dialogRef"
          />
        }
        @case ("Table") {
          <app-table
            (event)="c.value['onEvent']($event)"
            [id]="c.value.id"
            [headers]="c.value['headers']"
            [fields]="c.value['fields']"
            [filters]="c.value['activeFilters']"
            [options]="c.value['options']"
            [buttons]="c.value['buttons']"
            [setstatusaction]="c.value['statusactionid']"
            [searchactionid]="c.value['searchactionid']"
            [sortactionid]="c.value['sortactionid']"
            [searchkeys]="c.value['searchkeys']"
            [containerRef]="component"
          />
        }
        @case ("Chart") {
          <app-chart [charts]="c.value['charts']" />
        }
        @case ("Caption") {
          <app-caption />
        }
        @case ("Tile") {
          <app-tile [tiles]="c.value['tiles']" />
        }
        @case ("Map") {
          <app-map [markers]="c.value['markers']" />
        }
        @default {
          <span>Cannot find component -> {{ c.value.type }}</span>
        }
      }
    </div>
  }
</div>
