import { BehaviorSubject } from "rxjs";
import { AttachmentStatus } from "../enums/attachment-status";

export class Attachment {
  public id: string;

  public label: string;
  public file: File | string;

  public status: BehaviorSubject<AttachmentStatus>;
  private initialStatus: AttachmentStatus;

  public description: string;

  public constructor(id: string, label: string, file: File | string, status: AttachmentStatus, description: string) {
    this.id = id;
    this.label = label;
    this.status = new BehaviorSubject(status);
    this.initialStatus = status;

    this.file = file;
    this.description = description;
  }

  /**
   * Delete attachment
   * @param event
   */
  public delete(event: Event): void {
    event.stopPropagation();
    this.status.next(AttachmentStatus.REMOVED);
  }

  /**
   * Reset attachment to initial status
   * @param event
   */
  public restore(event?: Event): void {
    event?.stopPropagation();
    this.status.next(this.initialStatus);
  }

  /**
   * Set status of attachment to downloading
   * @param event
   */
  public download(event: Event): void {
    event.stopPropagation();
    this.status.next(AttachmentStatus.DOWNLOADING);
  }

  /**
   * Returns true if attachment status equals loading
   * @returns
   */
  public isLoaded(): boolean {
    return this.status.value == AttachmentStatus.LOADED;
  }

  /**
   * Returns true if attachment status equals downloading
   * @returns
   */
  public isDownloading(): boolean {
    return this.status.value == AttachmentStatus.DOWNLOADING;
  }

  /**
   * Returns true if attachment status equals removed
   * @returns
   */
  public isRemoved(): boolean {
    return this.status.value == AttachmentStatus.REMOVED;
  }

  /**
   * Returns true if attachment status equals new
   * @returns
   */
  public isNew(): boolean {
    return this.status.value == AttachmentStatus.NEW;
  }
}
