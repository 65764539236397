import { Component, Input, OnInit } from "@angular/core";
import { FormControl, ReactiveFormsModule } from "@angular/forms";
import { MatOptionModule } from "@angular/material/core";
import { MatSelectModule } from "@angular/material/select";
import { DefaultComponent } from "src/app/default.component";
import { IconReplacePipe } from "src/pipes/icon-replace.pipe";
import { PrefixTemplate } from "../../PrefixTemplate";
import { PrefixValidator } from "../../PrefixValidator";
import { TemplateHTMLComponent } from "../template-html/template-html.component";

export interface SelectOption {
  label: string;
  value: string;
}

export interface SelectGroup {
  group: string;
  options: SelectOption[];
}

@Component({
  standalone: true,
  selector: "app-template-select",
  imports: [MatSelectModule, MatOptionModule, TemplateHTMLComponent, ReactiveFormsModule, IconReplacePipe],
  templateUrl: "./template-select.component.html",
  styleUrl: "./template-select.component.less",
})
export class TemplateSelectComponent extends DefaultComponent implements PrefixTemplate<string | string[], string | string[]>, OnInit {
  @Input({ required: true })
  public control: FormControl<string | string[] | null> | null;

  @Input()
  public value: string | string[] | null;

  @Input()
  public label: string | null;

  @Input()
  public required: boolean;

  @Input()
  public disabled: boolean;

  @Input()
  public options: SelectOption[];

  @Input()
  public groups: SelectGroup[];

  @Input()
  public multiple: boolean;

  public constructor() {
    super();
    this.control = null;
    this.value = null;
    this.label = null;
    this.required = false;
    this.disabled = false;

    this.options = [];
    this.groups = [];
    this.multiple = false;
  }

  public ngOnInit(): void {
    const control = this.control;
    if (control) {
      this.addValidators(control);
      this.isMultiple(this.value);
      this.selectFirst(control);
    } else {
      throw new Error("Undefined control");
    }
  }

  /**
   * Get option based on value
   * @param value
   * @returns
   */
  public getOption(value: string | string[] | null): SelectOption | null {
    return this.options.find((option) => option.value == value) ?? null;
  }

  private addValidators(control: FormControl<string | string[] | null>): void {
    if (this.required) control.addValidators([PrefixValidator.required()]);

    control.updateValueAndValidity();
  }

  /**
   * Select the first option when initial value is falsly
   * @param control
   */
  private selectFirst(control: FormControl): void {
    if (this.options.length) control.setValue(this.value || this.options[0].value);
    if (this.groups.length) control.setValue(this.value || this.options[0].value);
  }

  /**
   * Set multiple true if value is an array
   * @param value
   */
  private isMultiple(value: string | string[] | null): void {
    if (value) this.multiple = Array.isArray(value);
  }
}
