<div class="canvasContainer">
  <canvas #signature></canvas>
  @if (signaturePad) {
    <div class="deleteButton">
      <button mat-icon-button (click)="clear()" [ariaLabel]="'SIGNATURE.BUTTON.CLEAR' | translate">
        <mat-icon>delete</mat-icon>
      </button>
    </div>
  }
</div>
