import { CommonModule } from "@angular/common";
import { Component } from "@angular/core";
import { RouterOutlet } from "@angular/router";
import { LoginComponent } from "../components/default/login/login.component";
import { DefaultComponent } from "../default.component";

@Component({
  standalone: true,
  selector: "app-screen-auth",
  imports: [CommonModule, LoginComponent, RouterOutlet],
  templateUrl: "./auth.component.html",
  styleUrl: "./auth.component.less",
})
export class AuthScreenComponent extends DefaultComponent {}
