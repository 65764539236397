import { Component } from "@angular/core";
import { ALLOWED_CHARACTERS } from "src/config/allowed-characters.config";
import { PrefixComponent } from "../../prefix.component";
import { TemplateTextComponent } from "../../templates/template-text/template-text.component";

@Component({
  standalone: true,
  selector: "app-prefix-hid",
  imports: [TemplateTextComponent],
  templateUrl: "./prefix-hid.component.html",
  styleUrl: "./prefix-hid.component.less",
})
export class PrefixHidComponent extends PrefixComponent<string> {
  public constructor() {
    super();
    this.hide = true;
    this.post = false;
    this.characters = ALLOWED_CHARACTERS["hid"];
  }
}
