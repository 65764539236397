@if (data) {
  <div class="container-fluid">
    <div class="row">
      <div id="threat-container" class="col-md">
        <ol>
          @for (threat of data | keyvalue; track $index) {
            <li class="threat">
              @switch (threat.key) {
                @case ("REGEX") {
                  <span>
                    {{ "FORM.THREATS." + threat.key + "." + threat.value | translate }}
                  </span>
                }
                @case ("BOOL") {
                  <span>
                    {{ "" + threat.value | translate }}
                  </span>
                }
                @default {
                  <span>
                    {{ "FORM.THREATS." + threat.key | translate: { value: threat.value } }}
                  </span>
                }
              }
            </li>
          }
        </ol>
      </div>
    </div>
  </div>
}
