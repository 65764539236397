import { Component } from "@angular/core";
import { ALLOWED_CHARACTERS } from "src/config/allowed-characters.config";
import { PrefixComponent } from "../../prefix.component";
import { TemplateTextComponent } from "../../templates/template-text/template-text.component";

@Component({
  standalone: true,
  selector: "app-prefix-idp",
  imports: [TemplateTextComponent],
  templateUrl: "./prefix-idp.component.html",
  styleUrl: "./prefix-idp.component.less",
})
export class PrefixIdpComponent extends PrefixComponent<string> {
  public hide: boolean;

  public constructor() {
    super();
    this.hide = true;
    this.characters = ALLOWED_CHARACTERS["idp"];
  }
}
