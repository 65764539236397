import { Component } from "@angular/core";
import { ALLOWED_CHARACTERS } from "src/config/allowed-characters.config";
import { PrefixTextComponent } from "../../prefix-text.component";
import { TemplateTextComponent } from "../../templates/template-text/template-text.component";
import { MaxLength } from "../../types/MaxLength";

@Component({
  standalone: true,
  selector: "app-prefix-cdf",
  imports: [TemplateTextComponent],
  templateUrl: "./prefix-cdf.component.html",
  styleUrl: "./prefix-cdf.component.less",
})
export class PrefixCdfComponent extends PrefixTextComponent {
  public maxlength: MaxLength;

  public constructor() {
    super();
    this.maxlength = 12;
    this.characters = ALLOWED_CHARACTERS["cdf"];
  }
}
