import { Component, Input } from "@angular/core";
import { ALLOWED_CHARACTERS } from "src/config/allowed-characters.config";
import { PrefixComponent } from "../../prefix.component";
import { SelectGroup, SelectOption, TemplateSelectComponent } from "../../templates/template-select/template-select.component";

@Component({
  standalone: true,
  selector: "app-prefix-idg",
  imports: [TemplateSelectComponent],
  templateUrl: "./prefix-idg.component.html",
  styleUrl: "./prefix-idg.component.less",
})
export class PrefixIdgComponent extends PrefixComponent<string> {
  @Input()
  public groups: SelectGroup[];

  @Input()
  public options: SelectOption[];

  public multiple: boolean;

  public constructor() {
    super();
    this.groups = [];
    this.options = [];
    this.multiple = false;
    this.options.unshift({ label: "-?-", value: "null" });
    this.characters = ALLOWED_CHARACTERS["idg"];
  }
}
