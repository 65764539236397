import { BehaviorSubject, Subscription } from "rxjs";

export class FormConditional {
  public condition: BehaviorSubject<boolean>;
  public subscriptions: Subscription;

  public name: string;
  public hidden: boolean;

  public constructor(name: string, hidden = false) {
    this.condition = new BehaviorSubject(true);
    this.subscriptions = new Subscription();

    this.name = name;
    this.hidden = hidden;
  }

  public setCondition(condition: BehaviorSubject<boolean>): void {
    this.condition.unsubscribe();
    this.condition = condition;

    this.subscriptions.add(
      this.condition.subscribe((value) => {
        if (this.hidden && value) {
          this.condition.next(false);
        }
      }),
    );
  }
}
