<div class="xja-container">
  @if (value) {
    <span class="correct">
      <mat-icon>check_circle</mat-icon>
    </span>
  } @else {
    <span class="incorrect">
      <mat-icon class="material-symbols-rounded">cancel</mat-icon>
    </span>
  }
  <label [innerHTML]="label | iconReplace"></label>
</div>
