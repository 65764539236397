import { ComponentType } from "@angular/cdk/portal";
import { ApplicationRef, ComponentRef, EnvironmentInjector, Injectable, createComponent, inject } from "@angular/core";
import { OVERLAYS } from "src/config/overlays.config";
import { SnackbarComponent } from "./impl/snackbar.component";
import { DefaultSnackbarComponent } from "./template/default-snackbar/default-snackbar.component";
import { SnackbarTemplateComponent } from "./template/snackbar-template.component";

export enum SnackbarTemplate {
  DEFAULT = 0,
}

export interface SnackbarConfig<DATA = unknown> {
  data: DATA | null;
  type: ComponentType<SnackbarComponent>;
  duration: number | null;
}

export interface SnackbarOptions {}

@Injectable({
  providedIn: "root",
})
export class SnackbarService {
  private injector: EnvironmentInjector;
  private appRef: ApplicationRef;

  public templates: Map<number, ComponentType<SnackbarTemplateComponent>>;
  public instances: Map<string, SnackbarTemplateComponent>;

  public constructor() {
    this.injector = inject(EnvironmentInjector);
    this.appRef = inject(ApplicationRef);

    this.templates = new Map();
    this.instances = new Map();

    this.templates.set(0, DefaultSnackbarComponent);
  }

  /**
   * Open snackbar
   * @param type
   * @param data
   * @param template
   * @param config
   * @returns
   */
  public open<T = never, DATA extends T = T>(
    type: ComponentType<SnackbarComponent>,
    data: DATA | null = null,
    template: SnackbarTemplate = SnackbarTemplate.DEFAULT,
    config: Partial<SnackbarConfig<DATA | null>> = {},
  ): ComponentRef<SnackbarTemplateComponent> {
    const templateComponent = this.templates.get(template);
    const overlay = this.getOverlay();

    if (templateComponent && overlay) {
      const ref = this.createComponent<DATA>(templateComponent, {
        data: data,
        duration: 5000,
        type,
        ...config,
      });

      overlay.appendChild(ref.location.nativeElement);
      this.appRef.attachView(ref.hostView);

      return ref;
    } else {
      throw new Error("Undefined snackbar template.");
    }
  }

  /**
   * Remove instance from map
   * @param uuid
   */
  public close(uuid: string): void {
    const instance = this.instances.get(uuid);
    const overlay = this.getOverlay();

    if (instance && overlay) {
      const child = overlay.querySelector(`#snackbar-${uuid}`);
      if (child && instance.ref) {
        overlay.removeChild(child);
        this.appRef.detachView(instance.ref.hostView);
      } else {
        console.warn("Could not find snackbar => ", uuid);
      }
      this.instances.delete(uuid);
    } else {
      console.warn("Could not find snackbar instance => ", uuid);
    }
  }

  /**
   * Get or create the snackbar overlay
   * @returns
   */
  private getOverlay(): HTMLElement {
    let overlay = document.getElementById(OVERLAYS.SNACKBAR);

    if (!overlay) {
      const body = document.querySelector("body");
      if (body) {
        overlay = document.createElement("div");
        overlay.setAttribute("id", OVERLAYS.SNACKBAR);
        body.appendChild(overlay);
      } else {
        throw new Error("Undefined body");
      }
    }

    return overlay;
  }

  /**
   * Set the snackbar reference element
   * @param config
   */
  private createComponent<DATA>(
    component: ComponentType<SnackbarTemplateComponent>,
    config: SnackbarConfig<DATA>,
  ): ComponentRef<SnackbarTemplateComponent> {
    const ref = createComponent(component, {
      environmentInjector: this.injector,
    });
    ref.instance.config = config;
    ref.location.nativeElement.setAttribute("id", `snackbar-${ref.instance.uuid}`);
    return ref;
  }
}
