import { Component } from "@angular/core";
import { ALLOWED_CHARACTERS } from "src/config/allowed-characters.config";
import { PrefixComponent } from "../../prefix.component";
import { TemplateNumberComponent } from "../../templates/template-number/template-number.component";
import { Max } from "../../types/Max";
import { Min } from "../../types/Min";

@Component({
  standalone: true,
  selector: "app-prefix-be6",
  imports: [TemplateNumberComponent],
  templateUrl: "./prefix-be6.component.html",
  styleUrl: "./prefix-be6.component.less",
})
export class PrefixBe6Component extends PrefixComponent<string> {
  public max: Max;
  public min: Min;
  public commas: number;

  public constructor() {
    super({ updateOn: "blur" });
    this.max = 999999999.99;
    this.commas = 2;
    this.min = 0.01;
    this.required = true;
    this.characters = ALLOWED_CHARACTERS["be6"];
  }
}
