import { AfterViewInit, Directive, HostListener, Input, OnChanges } from "@angular/core";
import { PopupConfig, PopupDirective } from "./popup.directive";

@Directive({
  selector: "[tooltip]",
  standalone: true,
})
export class TooltipDirective extends PopupDirective implements AfterViewInit, OnChanges {
  @Input({ required: true })
  public tooltip: string | Partial<PopupConfig> | null;

  public constructor() {
    super();
    this.tooltip = null;

    this.config.handlers = {
      enter: (): void => {
        this.onMouseEnter();
      },
      leave: (): void => {
        this.onMouseLeave();
      },
    };
  }

  public ngOnChanges(): void {
    //reference is never set!
    // if (this.reference) {
    super.ngOnDestroy();
    this.reference?.destroy();
    this.reference = null;
    this.initialize(this.tooltip);
    // }
  }

  public ngAfterViewInit(): void {
    this.initialize(this.tooltip);
  }

  /**
   * Event triggered when entering mouse on tooltip
   * @param event
   */
  @HostListener("mouseenter", [])
  public onMouseEnter(): void {
    const timer = this.timer;

    this.clear(timer);
    this.timer = setTimeout(() => {
      this.toggle(true);
    }, this.config.options.showDuration || 0);
  }

  /**
   * Event triggered when leaving mouse of tooltip
   * @param event
   */
  @HostListener("mouseleave", [])
  public onMouseLeave(): void {
    const timer = this.timer;

    this.clear(timer);
    this.timer = setTimeout(() => {
      this.toggle(false);
    }, this.config.options.hideDuration || 0);
  }

  @HostListener("mousemove", ["$event"])
  public move(event: MouseEvent): void {
    const reference = this.reference;
    const options = this.config.options;

    if (options.followCursor && reference) {
      const element = <HTMLElement>reference.location.nativeElement;
      const elementX = element.clientWidth;
      const elementY = element.clientHeight;
      const documentX = document.body.clientWidth;
      const documentY = document.body.clientHeight;
      const mouseX = event.pageX;
      const mouseY = event.pageY;

      const right = mouseX + elementX < documentX;
      const top = mouseY + elementY < documentY;

      this.setPosition(element, event.pageX + 16, event.pageY - 8, {
        top,
        right,
      });
    }
  }

  @HostListener("window:resize", [])
  public onResize(): void {
    this.toggle(false);
  }

  /**
   * Clear a timeout
   * @param timeout
   */
  private clear(timeout: NodeJS.Timeout | null): void {
    if (timeout) {
      clearTimeout(timeout);
      timeout = null;
    }
  }
}
