import { CommonModule } from "@angular/common";
import {
  AfterViewInit,
  Component,
  ElementRef,
  EventEmitter,
  Input,
  OnChanges,
  OnInit,
  Output,
  QueryList,
  SimpleChanges,
  ViewChild,
  ViewChildren,
  inject,
} from "@angular/core";
import { FormControl, FormsModule } from "@angular/forms";
import { MatCheckboxModule } from "@angular/material/checkbox";
import { MatChipsModule } from "@angular/material/chips";
import { MatExpansionModule } from "@angular/material/expansion";
import { MatIconModule } from "@angular/material/icon";
import { MatSlideToggleModule } from "@angular/material/slide-toggle";
import { TranslateModule } from "@ngx-translate/core";
import { DefaultComponent } from "src/app/default.component";
import { ROUTES_CONFIG } from "src/config/routes.config";
import { TooltipDirective } from "src/directives/tooltip.directive";
import { ActionResponse, ParsedActionResponse } from "src/interfaces/post-request/post-request";
import { ExtractHtmlPipe } from "src/pipes/extract-html.pipe";
import { IconReplacePipe } from "src/pipes/icon-replace.pipe";
import { MapvaluesPipe } from "src/pipes/mapvalues.pipe";
import { ApplicationService } from "src/services/application.service";
import { HttpService } from "src/services/http.service";
import { Key } from "../../../../enums/key";
import { DialogService, DialogTemplate } from "../../global/dialog/dialog.service";
import {
  TableDeleteWarningComponent,
  TableDeleteWarningDialogData,
} from "../../global/dialog/impl/table-delete-warning/table-delete-warning.component";
import { TablePrefixComponent } from "../../global/prefixes/containers/table-prefix/table-prefix.component";
import { PrefixOmsComponent } from "../../global/prefixes/impl/prefix-oms/prefix-oms.component";
import { PrefixEvent, PrefixEventType } from "../../global/prefixes/prefix.component";
import { LinkData } from "../../global/prefixes/templates/template-link/template-link.component";
import { ButtonAction, ButtonEvent, ButtonsComponent } from "../buttons/buttons.component";
import { TableField } from "./table-field";
import { TableGroup } from "./table-group";
import { TableHeader } from "./table-header";
import { TableRow } from "./table-row";
import { TableContentPart, TableContentPartFilter, TableOptions, TableResponseButton } from "./table.interface";

export interface TableAction {
  type: ButtonAction;
  params: unknown[];
  table: TableComponent;
}

@Component({
  standalone: true,
  selector: "app-table",
  imports: [
    MatChipsModule,
    MatIconModule,
    PrefixOmsComponent,
    TooltipDirective,
    TranslateModule,
    MatExpansionModule,
    ButtonsComponent,
    MatCheckboxModule,
    MatSlideToggleModule,
    MapvaluesPipe,
    TablePrefixComponent,
    CommonModule,
    FormsModule,
    IconReplacePipe,
  ],
  templateUrl: "./table.component.html",
  styleUrls: ["./table.component.less", "./table.print.less"],
})
export class TableComponent extends DefaultComponent implements OnInit, OnChanges, AfterViewInit {
  public dialog: DialogService;
  private http: HttpService;
  public application: ApplicationService;

  @Input()
  public id: string;

  @Input()
  public headers: Map<number, TableHeader>;

  @Input()
  public fields: Map<number, TableGroup>;

  @Input()
  public options: Partial<TableOptions>;

  public settings: TableOptions;

  @Input()
  public step: number;

  @Input()
  public setstatusaction: string;

  @Input()
  public searchactionid: string;

  @Input()
  public sortactionid: string;

  @Input()
  public filters: TableContentPart["groupfilters"];

  @Input()
  public buttons: TableResponseButton[];

  @Input()
  public containerRef: HTMLDivElement | null;

  @Input()
  public searchkeys: string[];

  @Output()
  public add: EventEmitter<this>;

  @Output()
  public event: EventEmitter<TableAction>;

  @ViewChild("tableContainer", { static: true })
  public tableContainer: ElementRef<HTMLDivElement> | null;

  @ViewChildren("groupHeader")
  public groupHeaders: QueryList<ElementRef<HTMLSpanElement>>;

  public switchactive: boolean;
  public filteractive: boolean;
  public filterHighlight: boolean;

  public groupCollection: TableGroup[];
  public rowCollection: TableRow[];

  private filterStates: Map<string, boolean> = new Map();
  public search: FormControl<string | null> | null;

  public hasRows: boolean;

  public totalRows: number;
  public totalShownRows: number;

  public selectedRows: TableRow[];

  public isFixedLayout: boolean;

  private loadPercentage: number;

  private extractHtmlPipe: ExtractHtmlPipe;

  public constructor() {
    super();
    this.dialog = inject(DialogService);
    this.http = inject(HttpService);
    this.application = inject(ApplicationService);
    this.extractHtmlPipe = inject(ExtractHtmlPipe);
    this.id = this.uuid;

    this.headers = new Map<number, TableHeader>();
    this.fields = new Map<number, TableGroup>();
    this.buttons = [];
    this.setstatusaction = "";
    this.searchactionid = "";
    this.sortactionid = "";

    this.tableContainer = null;
    this.groupHeaders = new QueryList();

    this.containerRef = null;

    this.settings = {
      head: true,
      foot: true,
      delete: false,
      archive: false,
      add: false,
      download: false,
      search: true,
      filter: true,
      selectable: true,
    };

    this.options = {};

    this.switchactive = false;
    this.filteractive = false;
    this.filterHighlight = false;

    this.searchkeys = [];
    this.search = null;
    this.groupCollection = [];
    this.rowCollection = [];

    this.hasRows = false;

    this.step = 50;
    this.filters = [];
    this.add = new EventEmitter();
    this.event = new EventEmitter();

    this.totalRows = 0;
    this.totalShownRows = 0;

    this.selectedRows = [];

    this.isFixedLayout = false;

    this.loadPercentage = 90;
  }

  public ngOnChanges(changes: SimpleChanges): void {
    if (changes["fields"]) {
      // Set hasRows
      const fields = changes["fields"].currentValue;
      const first: TableGroup = fields.entries().next().value[1];
      this.hasRows = fields.size ? !!first.rows.size || !!first.groups.size : false;
    }
  }

  public ngOnInit(): void {
    this.groupCollection = this.getGroups();
    this.rowCollection = this.getRows();

    for (const header of this.headers) {
      const width = header[1].options.width;
      if (width && width !== "initial") {
        this.isFixedLayout = true;
        break;
      }
    }

    this.settings = {
      head: true,
      foot: true,
      delete: false,
      archive: false,
      add: false,
      download: false,
      search: true,
      selectable: false,
      filter: this.groupCollection.length > 1,
      ...this.options,
    };

    this.settings.selectable = this.settings.download || this.settings.delete || this.buttons.some((button) => button.id === "17");

    if (this.filters) {
      this.filters.forEach((value) => {
        value.filters.forEach((subvalue) => {
          this.filterStates.set(`${subvalue.level}|${subvalue.value}`, subvalue.active === "true");
        });
      });
    }
    this.applySearch();

    this.loadMore();
    this.setTotals();
    this.hideIDColumn();

    this.toggleFilterHighlight();

    const firstSortHeader = Array.from(this.headers.values()).find((header) => header.sort && header.sort.direction !== "INITIAL");
    if (firstSortHeader) this.sortRows(firstSortHeader);
  }

  public ngAfterViewInit(): void {
    this.setTotals();
  }

  /**
   * Add new search key
   */
  public addSearchkeys(): void {
    if (this.search) {
      const value = this.search.value || "";
      if (value) {
        this.searchkeys = [...new Set([...this.searchkeys, ...value.split(" ")])].filter((i) => i);
        this.applySearch();
        this.onSearchKeyChange();
      } else {
        throw new Error("Invalid search keys.");
      }
    } else {
      throw new Error("Undefined search control.");
    }
  }

  /**
   * Remove search key
   * @param key
   */
  public removeSearchkey(key: string): void {
    this.searchkeys = this.searchkeys.filter((searchkey) => searchkey != key);
    this.applySearch();
    this.applyFilters();
    this.onSearchKeyChange();
  }

  /**
   * Remove all search keys
   */
  public clearSearchkeys(): void {
    this.searchkeys = [];
    this.applySearch();
    this.applyFilters();
    this.onSearchKeyChange();
  }

  /**
   * Hide fields depending on search keys
   */
  public applySearch(): void {
    const removeTags = (str: string): string => {
      return str.replace(/(<([^>]+)>)/gi, "").replace(/&nbsp;/g, " ");
    };
    const isMatch = (value: string, key: string): boolean => {
      return value.toLowerCase().includes(key.toLowerCase());
    };
    const checkgroup = (group: TableGroup): number => {
      let count = 0;
      count += Array.from(group.rows.values()).filter((row) => !row.hidden && !row.deleted).length;
      for (const subgroups of group.groups.values()) {
        count += checkgroup(subgroups);
      }
      return count;
    };

    if (this.search) this.search.patchValue(null);
    this.clearShown();

    for (const row of this.rowCollection) {
      const matched: string[] = [];
      for (const key of this.searchkeys) {
        for (const field of row.fields.values()) {
          const value: string = removeTags(<string>field.value.value || "");
          let link = "";
          if (field.extras && "links" in field.extras && field.extras["links"]) {
            link = (<LinkData[]>field.extras?.["links"]).slice().shift()?.value || "";
          }
          if (isMatch(removeTags(link), key) || isMatch(value, key)) {
            matched.push(key);
            break;
          }
        }
      }
      row.hide(matched.length !== this.searchkeys.length);
    }

    for (const group of this.groupCollection) {
      group.nosearchresults = checkgroup(group) === 0;
    }

    this.loadMore();
    this.setTotals(true);
  }

  /**
   * Set the search control
   * @param control
   */
  public setSearchControl(control: FormControl): void {
    this.search = control;
  }

  /**
   * Generate new search keys
   */
  public setSearchkeys(): void {
    if (this.search) {
      const value = this.search.value || "";
      if (value) {
        this.searchkeys = value.split(" ").filter((i) => i);
        this.onSearchKeyChange(true);
        this.applySearch();
      } else {
        throw new Error("Invalid search keys.");
      }
    } else {
      throw new Error("Undefined search control.");
    }
  }

  /**
   * Keyboard shortcuts for searching
   * @param keycode
   * @param input
   */
  public searchShortcuts(keycode: string): void {
    if (this.search) {
      switch (keycode) {
        case Key.ENTER:
          this.setSearchkeys();
          break;

        case Key.ESCAPE:
          this.clearSearchkeys();
          break;
      }
    } else {
      throw new Error("Undefined search control");
    }
  }

  /**
   * Toggle all groups to hidden
   */
  public toggleAllGroups(toggle = this.someFiltersActive()): void {
    for (const group of this.groupCollection) {
      const filters = this.filters.find((value) => value.level === group.level);
      if (!filters) continue;
      this.toggleFilterGroup(group.level, !toggle, filters.filters);
    }
    this.switchactive = !toggle;
  }

  /**
   * Open filtering interface
   */
  public toggleFilter(): void {
    this.filteractive = !this.filteractive;
  }

  /**
   * Set filter on active or inactive so front can see its active
   */
  public toggleFilterHighlight(): void {
    this.filterHighlight = Array.from(this.filterStates.values()).some((filter) => !filter);
  }

  /**
   * Fire event when trying to add a row
   */
  public addRow(): void {
    this.add.emit(this);
  }

  /**
   * Sort rows based on header column sort value, the whole table is re-indexed
   */
  public sortRows(header: TableHeader): void {
    if (header.sort) {
      const direction = header.sort.direction;
      const groups = this.fields.values();

      for (const _header of this.headers.values()) {
        if (_header === header) continue;
        const sort = _header.sort;
        if (sort && sort.direction) {
          sort.direction = "INITIAL";
        }
      }

      const sortColumn = header.sort.column
        ? Array.from(this.headers.values()).find((h) => `${h.template}${h.label}` === header.sort?.column) || header
        : header;

      /** Checks and sorts group rows and is used resursively */
      const groupCheck = (group: TableGroup, header: TableHeader): void => {
        if (group.rows) {
          let sortedRows = new Map();
          /** Sort DESC and ASC */
          if (direction && ["DESC", "ASC"].includes(direction)) {
            const sortedArray = [...group.rows].sort(([, a], [, b]) => {
              const aHeaderValue = a.fields.get(header);
              const bHeaderValue = b.fields.get(header);

              if (aHeaderValue && bHeaderValue) {
                let aValue = <string>aHeaderValue.value.value;
                aValue = this.extractHtmlPipe.transform(aValue);
                let bValue = <string>bHeaderValue.value.value;
                bValue = this.extractHtmlPipe.transform(bValue);

                if (direction === "DESC") {
                  if (aValue > bValue) {
                    return -1;
                  }
                  if (aValue < bValue) {
                    return 1;
                  }
                }

                if (direction === "ASC") {
                  if (<string>aValue > <string>bValue) {
                    return 1;
                  }
                  if (<string>aValue < <string>bValue) {
                    return -1;
                  }
                }
              }

              return 0;
            });
            sortedRows = new Map(sortedArray);
          }

          /** Sort INITIAL */
          if (direction && direction === "INITIAL") {
            const sortedArray = [...group.rows].sort((a, b) => a[0] - b[0]);
            sortedRows = new Map(sortedArray);
          }

          /** Repopulate records */
          group.rows = sortedRows;
          group.shown = [];
          this.loadMore();
        }

        if (group.groups.values()) {
          for (const nextGroup of group.groups.values()) {
            groupCheck(nextGroup, sortColumn);
          }
        }
      };

      for (const group of groups) {
        groupCheck(group, sortColumn);
      }
    }
  }

  public onSort(header: TableHeader): void {
    if (header.sort) {
      const direction = header.sort.direction;

      if (direction == "INITIAL") {
        header.sort.direction = "DESC";
      } else if (direction == "DESC") {
        header.sort.direction = "ASC";
      } else if (direction == "ASC") {
        header.sort.direction = "INITIAL";
      }

      this.sortRows(header);

      this.http.send(ROUTES_CONFIG.actionurl, {
        FFWDActionID: this.sortactionid,
        actionData: `${header.template}${header.label}=${header.sort.direction.toLowerCase()}`,
      });
    }
  }

  /**
   * Open delete warning dialog
   */
  public deleteRows(): void {
    const ref = this.dialog.open<TableDeleteWarningDialogData>(
      TableDeleteWarningComponent,
      {
        title: {
          label: "DIALOG.TABLE_DELETE_WARNING.TITLE",
        },
        rows: this.getSelectedRows(),
      },
      DialogTemplate.MODAL,
    );
    if (ref) {
      this.addSubscription(
        ref.afterClosed().subscribe(() => {
          this.filterShown();
          this.setTotals();
        }),
      );
    }
  }

  /**
   * Gets all selected rows in a table
   * @returns
   */
  public getSelectedRows(): TableRow[] {
    return this.groupCollection.map((group) => group.getSelected().filter((row) => !row.deleted)).flat();
  }

  public setSelectedRows(): void {
    this.selectedRows = this.groupCollection.map((group) => group.getSelected().filter((row) => !row.deleted)).flat();
  }

  public toggleFilterGroup(level: string, checked: boolean, filters: TableContentPartFilter[]): void {
    const index = this.filters.findIndex((val) => val.level === level && val.filters === val.filters);
    for (let i = 0; i < this.filters[index].filters.length; i++) {
      this.filters[index].filters[i].active = checked ? "true" : "false";

      this.toggleFilterIndividual(level, filters[i], checked, false);
    }
    this.applyFilters();
  }

  //verplaatsen
  public toggleFilterIndividual(level: string, filter: TableContentPartFilter, checked: boolean, apply: boolean = true): void {
    this.http
      .send<ActionResponse>(ROUTES_CONFIG.actionurl, {
        FFWDActionID: this.setstatusaction,
        actionData: `${level}|${filter.value}=${checked ? "false" : "true"}`,
      })
      .then((data) => {
        const response = <ParsedActionResponse>JSON.parse(data.postActionAsJSON);
        this.application.onMessage(response.messages);
      });
    this.filterStates.set(`${level}|${filter.value}`, checked);
    if (apply) {
      this.applyFilters();
    }
  }

  public async onEvent(event: ButtonEvent): Promise<void> {
    const type = event.action;
    const params = event.params;

    this.event.emit({
      type: type,
      params: params,
      table: this,
    });
  }

  /**
   * Incoming prefix event
   * @param row
   */
  public onPrefixEvent(event: PrefixEvent, row: TableRow, _field: unknown): void {
    const type = event.type;
    const params = event.params;
    const field = <TableField>_field;

    switch (type) {
      case PrefixEventType.HIGHLIGHT:
        this.onHighlight(row, <boolean>params.shift());
        break;
      case PrefixEventType.SNAPSHOT:
        this.event.emit({ type: ButtonAction.SNAPSHOT, params: [params.shift()], table: this });
    }
    field;
  }

  public applyFilters(): void {
    // if group has child that is visible - this group should stay visible
    const checkGroups = (groups: Map<number, TableGroup>): boolean => {
      let hidden = !!groups.size; // set to true if has child groups, dont hide if no childgroups
      for (const group of groups.values()) {
        const result = checkGroup(group);
        if (result === false) {
          hidden = false;
        }
      }
      return hidden;
    };

    // return wether group should stay hidden (true = hidden)
    const checkGroup = (group: TableGroup): boolean => {
      if (this.filterStates.has(`${group.level}|${group.label}`)) {
        if (this.filterStates.get(`${group.level}|${group.label}`) === false) {
          // should be hidden because filter is set to false
          return true;
        } else if (group.groups.size === 0) {
          // filter exists & is set to true, so group should be shown
          return false;
        }
      }
      // Let the child groups decide wether this group should be shown
      return checkGroups(group.groups);
    };

    for (const group of this.groupCollection) {
      group.hidden = checkGroup(group);
    }

    const group = this.nextLoadGroup;
    if (group && group.availableRows.length !== group.shown.length && group.shown.length < this.step) {
      this.loadMore();
    }
    this.setTotals();
    this.toggleFilterHighlight();
  }

  /**
   * Check if table should load more rows
   * @param event ScrollEvent
   */
  public onScroll(height: number, position: number): void {
    if ((100 / height) * position > this.loadPercentage || this.checkHeaderOffset()) {
      this.loadMore();
    }
  }

  /**
   * Set group to collapsed, load more rows if less than configured step size are visible
   */
  public onCollapse(group: TableGroup): void {
    group.collapsed = true;

    if (this.visibleRows < this.step) {
      this.loadMore();
    }
  }

  /**
   * Set group to expanded
   */
  public onExpand(group: TableGroup): void {
    group.collapsed = false;

    if (group.shown.length < this.step) {
      this.loadMoreFromGroup(group, this.step);
    }
  }

  public reloadTable(): void {
    if (this.tableContainer) {
      this.containerRef?.scrollTo({ top: 0 });
    } else {
      throw new Error("Undefined table container");
    }
  }

  public filterGroupActive(filters: TableContentPartFilter[]): boolean {
    return filters.every((filter) => this.filterStates.get(`${filter.level}|${filter.value}`));
  }

  public filterActive(filter: TableContentPartFilter): boolean {
    return !!this.filterStates.get(`${filter.level}|${filter.value}`);
  }

  public createIconElement(iconClassList: string): string {
    return `<i class="${iconClassList}"></i>`;
  }

  public someFiltersActive(active = true): boolean {
    return Array.from(this.filterStates.values()).some((filter) => filter === active);
  }

  public onClear(): void {
    if (this.someFiltersActive(false)) this.toggleAllGroups(false);
    this.clearSearchkeys();
  }

  /**
   * Highlight a table row
   * @param row
   * @param highlight
   */
  private onHighlight(row: TableRow, highlight: boolean): void {
    row.highlight(highlight);
  }

  private hideIDColumn(): void {
    for (const [, header] of this.headers) {
      if (header.label === "ID") {
        let visible = false;
        for (const group of this.groupCollection) {
          for (const [, row] of group.rows) {
            const field = row.fields.get(header);
            if (!field) continue;

            if (field.extras && field.extras["links"]) {
              visible = !!(<unknown[]>field.extras["links"]).length;
              break;
            }
          }
          if (visible) break;
        }
        header.label = "";
        header.visible = visible;
      }
    }
  }

  private filterShown(): void {
    for (const group of this.groupCollection) {
      group.filterShown();
    }
  }

  private setTotals(keepTotal = false): void {
    let total = 0;
    let shown = 0;

    for (const group of this.fields.values()) {
      group.setTotals();
      total += group.nestedTotals.rows;
      shown += group.nestedTotals.shown;
    }

    if (this.searchkeys.length) {
      total = 0;
      for (const group of this.groupCollection) {
        total += Array.from(group.rows.values()).filter((row) => !row.deleted).length;
      }
    }

    if (!keepTotal) this.totalRows = total;
    this.totalShownRows = shown;
  }

  /**
   * Get all groups & nested groups
   * @returns
   */
  private getGroups(group: IterableIterator<TableGroup> = this.fields.values()): TableGroup[] {
    const all: TableGroup[] = [];

    const checkGroup = (group: IterableIterator<TableGroup>): void => {
      for (const item of group) {
        all.push(item);
        checkGroup(item.groups.values());
      }
    };
    checkGroup(group);

    return all;
  }

  /**
   * Get all rows & nested rows
   * @returns
   */
  private getRows(group: IterableIterator<TableGroup> = this.fields.values()): TableRow[] {
    const all: TableRow[] = [];

    const checkGroup = (group: IterableIterator<TableGroup>): void => {
      for (const item of group) {
        all.push(...Array.from(item.rows.values()));
        checkGroup(item.groups.values());
      }
    };
    checkGroup(group);

    return all;
  }

  private clearShown(): void {
    for (const group of this.groupCollection) {
      group.shown = [];
    }
  }

  /**
   * Get distance between headers, if scrollbar is > x% of the distance load more
   * @param event ScrollEvent
   * @returns boolean - load more or not
   */
  private checkHeaderOffset(): boolean {
    if (this.tableContainer) {
      const nextLoadGroup = this.nextLoadGroup;
      if (!nextLoadGroup) return false;
      const headers = this.groupHeaders?.toArray() ?? [];
      if (!(nextLoadGroup && headers && this.groupsAfterHaveLoaded(nextLoadGroup))) return false;
      const loadGroupHeaderIndex = headers.findIndex((element) => {
        return element.nativeElement.innerHTML.includes(nextLoadGroup.label);
      });
      if (!loadGroupHeaderIndex) return false;
      const header = headers[loadGroupHeaderIndex + 1].nativeElement;
      const headerOffset = header.parentElement?.parentElement?.parentElement?.offsetTop || 0;

      const target = this.tableContainer.nativeElement;
      const tableHeight = target.scrollHeight - target.offsetHeight;
      const scrollPosition = target.scrollTop;

      return (100 / (tableHeight - headerOffset)) * scrollPosition > this.loadPercentage;
    } else {
      throw new Error("Undefined table container");
    }
  }

  /**
   * Load more rows according to step size
   */
  private loadMore(): void {
    let selectedGroup = this.nextLoadGroup;
    let remainingRows = this.step;
    if (!selectedGroup) return;
    while (remainingRows > 0 && !!selectedGroup) {
      remainingRows -= this.loadMoreFromGroup(selectedGroup, remainingRows);
      selectedGroup = this.nextLoadGroup;
    }
  }

  private loadMoreFromGroup(group: TableGroup, maxRows: number): number {
    const loadedAmount = group.loadMore(maxRows);
    this.setTotals();
    return loadedAmount;
  }

  /**
   * Find the next group to load rows from
   */
  private get nextLoadGroup(): TableGroup | undefined {
    return this.availableGroups.find((group) => group.availableRows.length > group.shown.length);
  }

  /**
   * Check if groups after this group have visible rows
   * @param group group
   * @returns boolean
   */
  private groupsAfterHaveLoaded(group: TableGroup): boolean {
    return !!this.availableGroups.slice(this.availableGroups.indexOf(group) + 1).find((group) => group.shown.length !== 0);
  }

  /**
   * Get all groups in a flat array (no nesting)
   */
  private get allGroups(): TableGroup[] {
    const groups: TableGroup[] = [];
    this.fields.forEach((group) => {
      groups.push(...this.getSubgroups(group));
    });
    return groups;
  }

  /**
   * Get all groups that are not collapsed
   */
  private get availableGroups(): TableGroup[] {
    return this.allGroups.filter((group) => !group.collapsed && !group.hidden && !group.nosearchresults);
  }

  /**
   * Get all nested groups underneath a group
   * @param group the group to get the subgroups from
   * @returns a list with all the nested groups underneath a parent
   */
  private getSubgroups(group: TableGroup): TableGroup[] {
    const groups = [group];
    group.groups.forEach((subgroup) => {
      groups.push(...this.getSubgroups(subgroup));
    });
    return groups;
  }

  /**
   * Total amount of loaded rows
   */
  private get visibleRows(): number {
    let total = 0;
    for (const group of this.allGroups.filter((group) => !group.collapsed)) {
      total += group.shown.length;
    }
    return total;
  }

  private async onSearchKeyChange(clear: boolean = false, searchkeys: string[] = this.searchkeys): Promise<void> {
    if (clear) {
      await this.onSearchKeyChange(false, []);
    }

    const actionData = searchkeys.join("|") || "";
    return this.http.send(ROUTES_CONFIG.actionurl, {
      FFWDActionID: this.searchactionid,
      actionData,
    });
  }
}
