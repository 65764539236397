import { Component, Input, OnInit } from "@angular/core";
import { FormControl } from "@angular/forms";
import { DefaultComponent } from "src/app/default.component";
import { PrefixTemplate } from "../../PrefixTemplate";

@Component({
  standalone: true,
  selector: "app-template-upload",
  templateUrl: "./template-upload.component.html",
  styleUrl: "./template-upload.component.less",
})
export class TemplateUploadComponent extends DefaultComponent implements PrefixTemplate<File | FileList>, OnInit {
  @Input({ required: true })
  public control: FormControl<File | FileList | null> | null;

  @Input()
  public value: File | FileList | null;

  @Input()
  public label: string | null;

  @Input()
  public multiple: boolean;
  @Input()
  public disabled: boolean;

  public constructor() {
    super();
    this.control = null;
    this.value = null;
    this.label = null;
    this.disabled = false;
    this.multiple = false;
  }

  public ngOnInit(): void {
    if (!this.control) throw new Error("Undefined control");
  }

  /**
   * Triggered when adding or removing a file
   * @param event
   */
  public onFileChange(event: Event): void {
    const target = <HTMLInputElement>event.target;
    const files = target.files;

    if (files && this.control) {
      if (files.length > 1 && !this.multiple) {
        throw new Error("Found multiple files while multiple is set to false");
      }
      this.control.setValue(this.multiple ? files : files.item(0));
    }
  }
}
