@if (control) {
  @if (!disabled) {
    <div class="textarea-container">
      <textarea
        [ariaLabel]="label"
        #area
        [formControl]="control"
        [readonly]="disabled"
        (beforeinput)="onBeforeInput($event)"
        (focus)="switchFocus()"
        (focusout)="switchFocus()"
      ></textarea>
      @if (focused && counter) {
        <div class="charcounter-container">
          <span class="charcounter" [class.exceeded]="maxlength && (control.value?.length ?? 0) > maxlength">
            <span>{{ control.value?.length || "0" }}</span>
            @if (maxlength !== null) {
              <span>/{{ maxlength }}</span>
            }
          </span>
        </div>
      }
    </div>
  } @else {
    <app-template-html [control]="control" [value]="value" />
  }
}
