export enum SessionPermission {
  SHOW_COCKPIT = "SHOW_COCKPIT",
  SHOW_REPORTS = "SHOW_REPORTS",
  SHOW_COACH = "SHOW_COACH",
  SHOW_TAX = "SHOW_TAX",
  SEARCH_PAGE = "SEARCH_PAGE",
  SAVE_TABLE_SEARCH = "SAVE_TABLE_SEARCH",
  SAVE_TABLE_FILTERS = "SAVE_TABLE_FILTERS",
  SHOW_SCHEDULES = "SHOW_SCHEDULES",
  SHOW_CONFERENCE = "SHOW_CONFERENCE",
  SHOW_CHART = "SHOW_CHART",
  CAN_PIN = "CAN_PIN",
  CAN_COPY = "CAN_COPY",
  REMOVE_ROW_ON_CLICK_IN_COPY = "REMOVE_ROW_ON_CLICK_IN_COPY",
  REMOVE_COLUMN_ON_CLICK_IN_COPY = "REMOVE_COLUMN_ON_CLICK_IN_COPY",
  DEV_INFO = "DEV_INFO",
}
