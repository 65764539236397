import { Component, Inject, Input } from "@angular/core";
import { DEFAULT_VALUE, PrefixComponent, PrefixOptions } from "./prefix.component";

@Component({
  standalone: true,
  selector: "app-prefix-text",
  template: "",
})
export class PrefixTextComponent extends PrefixComponent<string> {
  @Input()
  public counter: boolean;

  @Input()
  public placeholder: string;

  public constructor(@Inject(DEFAULT_VALUE) options: Partial<PrefixOptions<string | null>> = {}) {
    const settings: PrefixOptions<string | null> = {
      defaultValue: null,
      updateOn: "change",
      ...options,
    };
    super(settings);
    this.counter = true;
    this.placeholder = "";
  }
}
