import { TableResponseGroup, TableResponseRow } from "src/app/components/default/table/table.interface";
import { TileContentPart } from "src/app/components/default/tile/tile.interface";
import { Coordinate, Marker } from "src/app/components/global/prefixes/templates/template-map/template-map.component";
import { ApplicationService } from "src/services/application.service";
import { HttpService } from "src/services/http.service";
import { ContentTable } from "./content-table";

export interface GeoResponse {
  value: {
    lng: number;
    lat: number;
  };
}

export class ContentMap extends ContentTable {
  public markers: Marker[];

  public constructor(id: string, http: HttpService, application: ApplicationService) {
    super(id, http, application, "Map");
    this.markers = [];
  }

  public parseMarkers(data: TileContentPart): Marker[] {
    const incoming = [...this.getRowsFromGroups(data.data.groups), ...data.data.rows];
    console.error(incoming);
    return incoming.map((row) => ({
      location: this.getCoordinate(row),
      data: row.fields,
    }));
  }

  private getRowsFromGroups(groups: TableResponseGroup[]): TableResponseRow[] {
    const rows: TableResponseRow[] = [];
    for (const group of groups) {
      if (group.groups.length) {
        rows.push(...this.getRowsFromGroups(group.groups));
      } else {
        rows.push(...group.rows);
      }
    }
    return rows;
  }

  private getRandomCoordinate(): Coordinate {
    return {
      lat: parseFloat((Math.random() * (90 - -90)).toFixed(4)),
      lng: parseFloat((Math.random() * (180 - -180)).toFixed(4)),
    };
  }

  private getCoordinate(row: TableResponseRow): Coordinate {
    const geo = <GeoResponse>(<unknown>row.fields.find((field) => field.pfx == "geo"));

    if (geo) {
      return {
        lng: geo.value.lng,
        lat: geo.value.lat,
      };
    } else {
      throw new Error("Could not find coordinates in row");
    }
  }
}
