import { Injectable, inject } from "@angular/core";
import { BehaviorSubject } from "rxjs";
import { SessionRole } from "src/interfaces/session/session-role";
import { SessionWorkspace } from "src/interfaces/session/session-workspace";
import { HttpService } from "src/services/http.service";
import { SessionService } from "src/services/session.service";
import { MenuItem, MenuItemsFill, MenuResponse } from "./definitions";

@Injectable({ providedIn: "root" })
export class MenuService {
  public http: HttpService;
  public session: SessionService;

  public workspaces: SessionWorkspace[];
  public roles: SessionRole[];

  public activeMenu$: BehaviorSubject<string | null>;
  public loading: BehaviorSubject<boolean>;
  public menus: Map<number, { menu: string; icon: string; tooltip: string; ariaLabel: string }>;

  public checklist: BehaviorSubject<string | null>;

  public constructor() {
    this.session = inject(SessionService);
    this.http = inject(HttpService);

    this.workspaces = [];
    this.roles = [];

    this.menus = new Map<number, { menu: string; icon: string; tooltip: string; ariaLabel: string }>();
    this.checklist = new BehaviorSubject<string | null>(null);
    this.activeMenu$ = new BehaviorSubject<string | null>("main");
    this.loading = new BehaviorSubject(false);
  }

  /**
   * Load menu depending on @loadURL
   * @param loadURL
   * @returns
   */
  public async load(loadURL: string): Promise<MenuItemsFill> {
    const response = await this.http.retrieve<MenuResponse>(loadURL, { menutype: 1 });
    const data = JSON.parse(response[0].menuDataAsJSON);
    return data.menu;
  }

  /**
   * Fills map based on data
   * @param map
   * @param data
   */
  public fillItems(array: MenuItem[], data: MenuItemsFill): void {
    if (data.lines)
      for (const [, line] of data.lines.entries()) {
        const itemsChildren: MenuItem[] = [];
        if (line.lines) this.fillItems(itemsChildren, line);
        array.push({
          action: line.action,
          label: line?.displayname,
          route: line?.link,
          items: itemsChildren,
          icon: !!line?.addlink,
          iconRoute: line?.addlink,
        });
      }
  }

  /**
   * Reload the current menu.
   */
  public async reload(): Promise<void> {
    this.loading.next(false);
  }
}
