<app-template-number
  [disabled]="disabled"
  [characters]="characters"
  [value]="value"
  [control]="control"
  [min]="min"
  [max]="max"
  [required]="required"
  [commas]="2"
  [label]="label"
/>
