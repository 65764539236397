import { Component } from "@angular/core";
import { ALLOWED_CHARACTERS } from "src/config/allowed-characters.config";
import { PrefixTextComponent } from "../../prefix-text.component";
import { TemplatePasswordComponent } from "../../templates/template-password/template-password.component";
import { MaxLength } from "../../types/MaxLength";
import { MinLength } from "../../types/MinLength";

@Component({
  standalone: true,
  selector: "app-prefix-wac",
  imports: [TemplatePasswordComponent],
  templateUrl: "./prefix-wac.component.html",
  styleUrl: "./prefix-wac.component.less",
})
export class PrefixWacComponent extends PrefixTextComponent {
  public minlength: MinLength;
  public maxlength: MaxLength;

  public constructor() {
    super();
    this.counter = false;
    this.post = true;
    this.minlength = 8;
    this.maxlength = 12;
    this.characters = ALLOWED_CHARACTERS["wac"];
  }
}
