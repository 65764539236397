import { PrefixData } from "src/interfaces/prefix-data";
import { PrefixBaeComponent } from "../../impl/prefix-bae/prefix-bae.component";
import { PrefixBesComponent } from "../../impl/prefix-bes/prefix-bes.component";
import { PrefixBs2Component } from "../../impl/prefix-bs2/prefix-bs2.component";
import { PrefixBs3Component } from "../../impl/prefix-bs3/prefix-bs3.component";
import { PrefixCdpComponent } from "../../impl/prefix-cdp/prefix-cdp.component";
import { PrefixCdxComponent } from "../../impl/prefix-cdx/prefix-cdx.component";
import { PrefixDa2Component } from "../../impl/prefix-da2/prefix-da2.component";
import { PrefixDatComponent } from "../../impl/prefix-dat/prefix-dat.component";
import { PrefixGtpComponent } from "../../impl/prefix-gtp/prefix-gtp.component";
import { PrefixIcoComponent } from "../../impl/prefix-ico/prefix-ico.component";
import { PrefixImgComponent } from "../../impl/prefix-img/prefix-img.component";
import { PrefixJanComponent } from "../../impl/prefix-jan/prefix-jan.component";
import { PrefixLn3Component } from "../../impl/prefix-ln3/prefix-ln3.component";
import { PrefixLnaComponent } from "../../impl/prefix-lna/prefix-lna.component";
import { PrefixLnkComponent } from "../../impl/prefix-lnk/prefix-lnk.component";
import { PrefixNaaComponent } from "../../impl/prefix-naa/prefix-naa.component";
import { PrefixOm2Component } from "../../impl/prefix-om2/prefix-om2.component";
import { PrefixOmlComponent } from "../../impl/prefix-oml/prefix-oml.component";
import { PrefixOmsComponent } from "../../impl/prefix-oms/prefix-oms.component";
import { PrefixPgeComponent } from "../../impl/prefix-pge/prefix-pge.component";
import { PrefixPr6Component } from "../../impl/prefix-pr6/prefix-pr6.component";
import { PrefixPrgComponent } from "../../impl/prefix-prg/prefix-prg.component";
import { PrefixQrcComponent } from "../../impl/prefix-qrc/prefix-qrc.component";
import { PrefixTi2Component } from "../../impl/prefix-ti2/prefix-ti2.component";
import { PrefixTidComponent } from "../../impl/prefix-tid/prefix-tid.component";
import { PrefixXxxComponent } from "../../impl/prefix-xxx/prefix-xxx.component";

export const TABLE_PREFIX_DATA: PrefixData = {
  bae: { class: PrefixBaeComponent, inputs: {} },
  lnk: { class: PrefixLnkComponent, inputs: { routes: "links" } },
  lna: { class: PrefixLnaComponent, inputs: { routes: "links" } },
  ln3: { class: PrefixLn3Component, inputs: { routes: "links" } },
  idp: { class: PrefixLnkComponent, inputs: { routes: "links" } },
  oms: { class: PrefixOmsComponent, inputs: {} },
  oml: { class: PrefixOmlComponent, inputs: {} },
  cdp: { class: PrefixCdpComponent, inputs: {} },
  naa: { class: PrefixNaaComponent, inputs: {} },
  cdx: { class: PrefixCdxComponent, inputs: {} },
  om2: { class: PrefixOm2Component, inputs: {} },
  gtp: { class: PrefixGtpComponent, inputs: {} },
  dat: { class: PrefixDatComponent, inputs: {} },
  da2: { class: PrefixDa2Component, inputs: {} },
  jan: { class: PrefixJanComponent, inputs: {} },
  bes: { class: PrefixBesComponent, inputs: { attachmentLinks: "attachmentLinks" } },
  img: { class: PrefixImgComponent, inputs: {} },
  xxx: { class: PrefixXxxComponent, inputs: {} },
  qrc: { class: PrefixQrcComponent, inputs: {} },
  pr6: { class: PrefixPr6Component, inputs: {} },
  tid: { class: PrefixTidComponent, inputs: {} },
  ti2: { class: PrefixTi2Component, inputs: {} },
  ico: { class: PrefixIcoComponent, inputs: {} },
  bs2: { class: PrefixBs2Component, inputs: {} },
  bs3: { class: PrefixBs3Component, inputs: {} },
  prg: { class: PrefixPrgComponent, inputs: { options: "options" } },
  pge: { class: PrefixPgeComponent, inputs: { options: "options" } },
};
