import { AfterViewInit, Component, ElementRef, Input, QueryList, ViewChildren } from "@angular/core";
import { Chart as ChartJS } from "chart.js/auto";
import { DefaultComponent } from "src/app/default.component";
import { ChartLine } from "./chart.interface";

export interface ChartInput {
  title: string;
  lines: ChartLine[];
}

export interface Chart {
  canvas: HTMLCanvasElement;
  chart: ChartJS;
}

@Component({
  standalone: true,
  selector: "app-chart",
  templateUrl: "./chart.component.html",
  styleUrls: ["./chart.component.less", "./chart.print.less"],
})
export class ChartComponent extends DefaultComponent implements AfterViewInit {
  @Input({ required: true })
  public charts: ChartInput[];

  @ViewChildren("chart")
  public canvas: QueryList<ElementRef<HTMLCanvasElement>> | null;

  public chartCollection: Chart[];

  public constructor() {
    super();
    this.canvas = null;
    this.charts = [];
    this.chartCollection = [];
  }

  public ngAfterViewInit(): void {
    const canvasCollection = this.canvas?.toArray() ?? [];

    if (this.charts.length == canvasCollection.length) {
      this.chartCollection = this.charts.map((chart, index) => {
        const canvas = canvasCollection[index].nativeElement;
        return {
          canvas,
          chart: this.getChart(canvas, chart.title, chart.lines),
        };
      });
    }
  }

  /**
   * Create Chart
   * @param title
   * @param lines
   * @returns
   */
  private getChart(canvas: HTMLCanvasElement, title: string, lines: ChartLine[]): ChartJS {
    const labels: string[] = [];

    for (const line of lines) {
      for (const node of line.nodes) {
        if (!labels.includes(node[0])) labels.push(node[0]);
      }
    }

    return new ChartJS(canvas, {
      type: "line",
      data: {
        labels,
        datasets: lines.map((line) => {
          return {
            label: line.title,
            data: line.nodes.map((node) => node[1]),
            borderColor: `rgba(${line.color})`,
            backgroundColor: `rgba(${line.color})`,
          };
        }),
      },
    });
  }
}
