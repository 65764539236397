import { AfterViewInit, Component } from "@angular/core";
import { ALLOWED_CHARACTERS } from "src/config/allowed-characters.config";
import { PrefixValidator } from "../../PrefixValidator";
import { PrefixTextComponent } from "../../prefix-text.component";
import { TemplateTextComponent } from "../../templates/template-text/template-text.component";
import { MaxLength } from "../../types/MaxLength";
import { MinLength } from "../../types/MinLength";

@Component({
  standalone: true,
  selector: "app-prefix-sof",
  imports: [TemplateTextComponent],
  templateUrl: "./prefix-sof.component.html",
  styleUrl: "./prefix-sof.component.less",
})
export class PrefixSofComponent extends PrefixTextComponent implements AfterViewInit {
  public minlength: MinLength;
  public maxlength: MaxLength;

  public constructor() {
    super();
    this.minlength = 9;
    this.maxlength = 9;
    this.characters = ALLOWED_CHARACTERS["sof"];
  }

  public ngAfterViewInit(): void {
    this.control.addValidators(PrefixValidator.bsn());
    this.control.updateValueAndValidity();
  }
}
