import { Component } from "@angular/core";
import { ALLOWED_CHARACTERS } from "src/config/allowed-characters.config";
import { PrefixComponent } from "../../prefix.component";
import { TemplateDateComponent } from "../../templates/template-date/template-date.component";

@Component({
  standalone: true,
  selector: "app-prefix-da2",
  imports: [TemplateDateComponent],
  templateUrl: "./prefix-da2.component.html",
  styleUrl: "./prefix-da2.component.less",
})
export class PrefixDa2Component extends PrefixComponent<string> {
  public constructor() {
    super();
    this.required = true;
    this.characters = ALLOWED_CHARACTERS["da2"];
  }
}
