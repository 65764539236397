import { Component, OnInit, inject } from "@angular/core";
import { DefaultComponent } from "src/app/default.component";
import { DialogService } from "../dialog.service";

@Component({
  standalone: true,
  selector: "app-dialog",
  imports: [],
  template: "",
})
export class DialogComponent<T = unknown> extends DefaultComponent implements OnInit {
  public dialog: DialogService;
  public data: T | null;

  public constructor() {
    super();
    this.dialog = inject(DialogService);
    this.data = null;
  }

  public ngOnInit(): void {
    const reference = this.dialog.instances.get(this.uuid);

    if (reference) {
      this.data = <T>reference.data;
    } else {
      throw new Error("Cannot find dialog reference.");
    }
  }

  public close(): void {
    this.dialog.close(this.uuid);
  }
}
