@if (control) {
  @if (!disabled) {
    <div class="map-container" #container></div>
    <div class="marker-overlay" [class.active]="activeMarker !== null">
      @if (activeMarker !== null) {
        <div class="context">
          <div>{{ activeMarker.context.data | json }}</div>
        </div>
        <button (click)="closeMarker()">close</button>
      }
    </div>
  } @else {
    <span>map disabled works!</span>
  }
} @else {
  <span>map loading works!</span>
}
