import { CommonModule } from "@angular/common";
import { Component, EventEmitter, inject, Input, Output } from "@angular/core";
import { MatButtonModule } from "@angular/material/button";
import { DefaultComponent } from "src/app/default.component";
import { ApplicationService } from "src/services/application.service";

export enum ButtonAction {
  SAVE = "SAVE",
  SNAPSHOT = "SNAPSHOT",
  REDIRECT = "REDIRECT",
  DELETE = "DELETE",
  ACTION = "ACTION",
  DOWNLOAD = "DOWNLOAD",
  RELOAD = "RELOAD",
  ARCHIVE = "ARCHIVE",
}

interface Button {
  description: string;
  ffwdaction: string;
  id: string;
  snapshottypes: { description: string; ffwdaction: string; id: string }[];
  dlname: string;
}

export interface ButtonEvent {
  action: ButtonAction;
  params: unknown[];
}

@Component({
  standalone: true,
  selector: "app-buttons",
  imports: [CommonModule, MatButtonModule],
  templateUrl: "./buttons.component.html",
  styleUrl: "./buttons.component.less",
})
export class ButtonsComponent extends DefaultComponent {
  @Input()
  public buttons: Button[];

  @Input()
  public rowsSelected: number;

  @Input()
  public disabled: boolean;

  @Output()
  public event: EventEmitter<ButtonEvent>;

  public application: ApplicationService;

  public constructor() {
    super();

    this.buttons = [];
    this.rowsSelected = -1;
    this.disabled = false;

    this.event = new EventEmitter();

    this.application = inject(ApplicationService);
  }

  public sendSave(filename: string | null = null, download = false): void {
    this.event.emit({
      action: ButtonAction.SAVE,
      params: [filename, download],
    });
  }

  public sendSnapshot(actionid: string, filename: string | null = null): void {
    this.event.emit({
      action: ButtonAction.SNAPSHOT,
      params: [actionid, filename],
    });
  }

  public sendRedirect(contentid: string): void {
    this.event.emit({
      action: ButtonAction.REDIRECT,
      params: [contentid],
    });
  }

  public sendAction(actionid: string): void {
    this.event.emit({
      action: ButtonAction.ACTION,
      params: [actionid],
    });
  }

  public sendDownload(actionid: string | null, filename: string | null = null): void {
    this.event.emit({
      action: ButtonAction.DOWNLOAD,
      params: [actionid, filename],
    });
  }

  public sendReload(): void {
    this.event.emit({
      action: ButtonAction.RELOAD,
      params: [],
    });
  }

  public sendDelete(actionid: string | null): void {
    this.event.emit({
      action: ButtonAction.DELETE,
      params: [actionid],
    });
  }

  public sendArchive(): void {
    throw new Error("Method not implemented.");
  }
}
