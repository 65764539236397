import { EnvironmentHelper } from "src/classes/EnvironmentHelper";
import { OrganisationConfig } from "src/interfaces/environment";

const CONFIG: OrganisationConfig = {
  NAME: "lipia",
  TITLE: "Lipia",
  API: null,
  WEBSOCKET: null,
  SHOW_HISTORY: true,
  QR_LOGIN: true,
  QR_REFRESH_RATE: 60000,
  QR_CHECK_RATE: 5000,
  MSAL: null,
  SUBFUNCTIONALITIES: [],
  MAP: null,
};

const CONFIG_DEVELOPMENT: Partial<OrganisationConfig> = {
  TITLE: "Lipia | Development",
  API: "https://dev.api.lipia.software",
};
const CONFIG_TEST: Partial<OrganisationConfig> = { TITLE: "Lipia | Test", API: "https://test.api.lipia.software" };
const CONFIG_STAGING: Partial<OrganisationConfig> = { TITLE: "Lipia | Staging", API: "https://staging.api.lipia.software" };
const CONFIG_PRODUCTION: Partial<OrganisationConfig> = { TITLE: "Lipia", API: "https://api.lipia.software" };

export const ORGANISATION = EnvironmentHelper.GetEnvironment({
  CONFIG,
  CONFIG_DEVELOPMENT,
  CONFIG_TEST,
  CONFIG_STAGING,
  CONFIG_PRODUCTION,
});
