import { Component, Input, OnInit, inject } from "@angular/core";
import { ALLOWED_CHARACTERS } from "src/config/allowed-characters.config";
import { ROUTES_CONFIG } from "src/config/routes.config";
import { TooltipDirective } from "src/directives/tooltip.directive";
import { ActionResponse } from "src/interfaces/post-request/post-request";
import { IconReplacePipe } from "src/pipes/icon-replace.pipe";
import { ApplicationService } from "src/services/application.service";
import { HttpService } from "src/services/http.service";
import { PrefixComponent, PrefixEventType } from "../../prefix.component";
import { LinkData } from "../../templates/template-link/template-link.component";

@Component({
  standalone: true,
  selector: "app-prefix-lna",
  imports: [TooltipDirective, IconReplacePipe],
  templateUrl: "./prefix-lna.component.html",
  styleUrl: "./prefix-lna.component.less",
})
export class PrefixLnaComponent extends PrefixComponent<LinkData[]> implements OnInit {
  private application: ApplicationService;
  private http: HttpService;

  @Input()
  public routes: LinkData[];

  public constructor() {
    super();
    this.application = inject(ApplicationService);
    this.http = inject(HttpService);
    this.routes = [];
    this.post = false;
    this.characters = ALLOWED_CHARACTERS["lna"];
  }

  public ngOnInit(): void {
    this.routes = this.mapLinkData(this.routes);
  }

  public async sendAction(route: LinkData): Promise<void> {
    const id = route.link;
    const res = await this.http.send<ActionResponse>(ROUTES_CONFIG.actionurl, {
      FFWDActionID: id,
    });
    this.application.onMessage(JSON.parse(res.postActionAsJSON).messages);

    this.event.emit({
      type: PrefixEventType.HIGHLIGHT,
      params: [true],
    });
  }

  public createIconElement(iconClassList: string): string {
    return `<i class="${iconClassList}"></i>`;
  }

  private mapLinkData(links: LinkData[]): LinkData[] {
    return links.map((link) => {
      if (link.value && link.value.includes(" fa-")) {
        if (!link.value.includes("<i class=")) {
          link.value = `<i class="${link.value}"></i>`;
        } else {
          link.value = `${link.value}`;
        }
      }
      // if (link.value && link.value.includes(" fa-")) link.value = `<i class="fas fa-cogs"></i>`;

      return link;
    });
  }
}
