import { Component, Input } from "@angular/core";
import { GridValue } from "src/app/components/default/form/form.interface";
import { ALLOWED_CHARACTERS } from "src/config/allowed-characters.config";
import { PrefixComponent } from "../../prefix.component";
import { TemplateGridComponent } from "../../templates/template-grid/template-grid.component";

@Component({
  standalone: true,
  selector: "app-prefix-gri",
  imports: [TemplateGridComponent],
  templateUrl: "./prefix-gri.component.html",
  styleUrl: "./prefix-gri.component.less",
})
export class PrefixGriComponent extends PrefixComponent<GridValue[]> {
  @Input()
  public date: boolean;

  @Input()
  public text: boolean;

  public constructor() {
    super();
    this.date = false;
    this.text = false;
    this.characters = ALLOWED_CHARACTERS["gri"];
  }
}
