import { Component, OnDestroy } from "@angular/core";
import { BehaviorSubject, Subscription } from "rxjs";
import { ENVIRONMENT } from "src/environments/environment";
import { v4 as uuid } from "uuid";

export enum ComponentStates {
  STARTED,
  FINISHED,
}

export interface ComponentState {
  id: string;
  state: ComponentStates;
}

@Component({
  standalone: true,
  selector: "app-default",
  template: "",
})
export class DefaultComponent implements OnDestroy {
  public readonly uuid = uuid();

  public status$: BehaviorSubject<ComponentStates>;
  public subscriptions: Subscription;

  public constructor() {
    this.status$ = new BehaviorSubject<ComponentStates>(ComponentStates.STARTED);
    this.subscriptions = new Subscription();
  }

  /**
   * Add one or multiple subscriptions
   * @param subscriptions
   */
  public addSubscription(...subscriptions: Subscription[]): void {
    for (const s of subscriptions) this.subscriptions.add(s);
  }

  /**
   * Unsubscribe all added subscriptions
   */
  public ngOnDestroy(): void {
    this.subscriptions.unsubscribe();
  }

  /**
   * Logger for html
   * @param x
   */
  public log(...x: unknown[]): void {
    if (ENVIRONMENT.DEBUG) {
      console.log(...x);
    }
  }

  public canDeactivate(): boolean | Promise<boolean> {
    return true;
  }
}
