<div class="grid-container" [ngClass]="[direction]" [class.has-checked]="checked">
  @for (option of value; track $index) {
    <span class="input-container" [ngClass]="{ date: date && !text, text: text && !date, checkbox: !text && !date }">
      <mat-checkbox [formControl]="group.controls[option.id].controls['checked']" [disabledInteractive]="false">
        <span [innerHTML]="option.label | translate | iconReplace" [ngClass]="{ 'checked-in-other-grid': option.checkedInOtherGrid }"></span>
      </mat-checkbox>

      @if (option.checked) {
        <div class="extras">
          @if (date && !text && $first) {
            <div class="date-container">
              <label>Vanaf</label>
              <span class="date">
                <app-template-date [disabled]="disabled" [value]="null" [control]="group.controls[option.id].controls['dateFrom']" />
              </span>
            </div>
            <div class="date-container">
              <label>Tot en met</label>
              <span class="date">
                <app-template-date [disabled]="disabled" [value]="null" [control]="group.controls[option.id].controls['dateTo']" />
              </span>
            </div>
          }
          @if (!date && text && option.hasText) {
            <app-template-textarea [disabled]="disabled" [value]="null" [control]="group.controls[option.id].controls['text']" />
          }
        </div>
      }
    </span>
  }
</div>
