import { QUESTION_TYPE_DATA } from "src/app/questionnaire/question-type-data";
import { PrefixData } from "src/interfaces/prefix-data";
import { PrefixB10Component } from "../../impl/prefix-b10/prefix-b10.component";
import { PrefixB11Component } from "../../impl/prefix-b11/prefix-b11.component";
import { PrefixBa2Component } from "../../impl/prefix-ba2/prefix-ba2.component";
import { PrefixBa3Component } from "../../impl/prefix-ba3/prefix-ba3.component";
import { PrefixBaeComponent } from "../../impl/prefix-bae/prefix-bae.component";
import { PrefixBanComponent } from "../../impl/prefix-ban/prefix-ban.component";
import { PrefixBe2Component } from "../../impl/prefix-be2/prefix-be2.component";
import { PrefixBe3Component } from "../../impl/prefix-be3/prefix-be3.component";
import { PrefixBe6Component } from "../../impl/prefix-be6/prefix-be6.component";
import { PrefixBedComponent } from "../../impl/prefix-bed/prefix-bed.component";
import { PrefixBelComponent } from "../../impl/prefix-bel/prefix-bel.component";
import { PrefixBesComponent } from "../../impl/prefix-bes/prefix-bes.component";
import { PrefixBlaComponent } from "../../impl/prefix-bla/prefix-bla.component";
import { PrefixBs2Component } from "../../impl/prefix-bs2/prefix-bs2.component";
import { PrefixBs3Component } from "../../impl/prefix-bs3/prefix-bs3.component";
import { PrefixBs4Component } from "../../impl/prefix-bs4/prefix-bs4.component";
import { PrefixBs5Component } from "../../impl/prefix-bs5/prefix-bs5.component";
import { PrefixBs6Component } from "../../impl/prefix-bs6/prefix-bs6.component";
import { PrefixBs7Component } from "../../impl/prefix-bs7/prefix-bs7.component";
import { PrefixBs8Component } from "../../impl/prefix-bs8/prefix-bs8.component";
import { PrefixBs9Component } from "../../impl/prefix-bs9/prefix-bs9.component";
import { PrefixCd8Component } from "../../impl/prefix-cd8/prefix-cd8.component";
import { PrefixCd9Component } from "../../impl/prefix-cd9/prefix-cd9.component";
import { PrefixCdfComponent } from "../../impl/prefix-cdf/prefix-cdf.component";
import { PrefixCdpComponent } from "../../impl/prefix-cdp/prefix-cdp.component";
import { PrefixCdxComponent } from "../../impl/prefix-cdx/prefix-cdx.component";
import { PrefixCdyComponent } from "../../impl/prefix-cdy/prefix-cdy.component";
import { PrefixChxComponent } from "../../impl/prefix-chx/prefix-chx.component";
import { PrefixDa2Component } from "../../impl/prefix-da2/prefix-da2.component";
import { PrefixDatComponent } from "../../impl/prefix-dat/prefix-dat.component";
import { PrefixDe2Component } from "../../impl/prefix-de2/prefix-de2.component";
import { PrefixDe3Component } from "../../impl/prefix-de3/prefix-de3.component";
import { PrefixDe4Component } from "../../impl/prefix-de4/prefix-de4.component";
import { PrefixDe5Component } from "../../impl/prefix-de5/prefix-de5.component";
import { PrefixDecComponent } from "../../impl/prefix-dec/prefix-dec.component";
import { PrefixDi3Component } from "../../impl/prefix-di3/prefix-di3.component";
import { PrefixDisComponent } from "../../impl/prefix-dis/prefix-dis.component";
import { PrefixEm2Component } from "../../impl/prefix-em2/prefix-em2.component";
import { PrefixEmlComponent } from "../../impl/prefix-eml/prefix-eml.component";
import { PrefixFilComponent } from "../../impl/prefix-fil/prefix-fil.component";
import { PrefixGeoComponent } from "../../impl/prefix-geo/prefix-geo.component";
import { PrefixGk1Component } from "../../impl/prefix-gk1/prefix-gk1.component";
import { PrefixGr2Component } from "../../impl/prefix-gr2/prefix-gr2.component";
import { PrefixGr3Component } from "../../impl/prefix-gr3/prefix-gr3.component";
import { PrefixGr4Component } from "../../impl/prefix-gr4/prefix-gr4.component";
import { PrefixGriComponent } from "../../impl/prefix-gri/prefix-gri.component";
import { PrefixGt2Component } from "../../impl/prefix-gt2/prefix-gt2.component";
import { PrefixGt4Component } from "../../impl/prefix-gt4/prefix-gt4.component";
import { PrefixGt5Component } from "../../impl/prefix-gt5/prefix-gt5.component";
import { PrefixGt7Component } from "../../impl/prefix-gt7/prefix-gt7.component";
import { PrefixGtpComponent } from "../../impl/prefix-gtp/prefix-gtp.component";
import { PrefixHi2Component } from "../../impl/prefix-hi2/prefix-hi2.component";
import { PrefixHi3Component } from "../../impl/prefix-hi3/prefix-hi3.component";
import { PrefixHidComponent } from "../../impl/prefix-hid/prefix-hid.component";
import { PrefixHtvComponent } from "../../impl/prefix-htv/prefix-htv.component";
import { PrefixHu2Component } from "../../impl/prefix-hu2/prefix-hu2.component";
import { PrefixHuiComponent } from "../../impl/prefix-hui/prefix-hui.component";
import { PrefixIcoComponent } from "../../impl/prefix-ico/prefix-ico.component";
import { PrefixIdfComponent } from "../../impl/prefix-idf/prefix-idf.component";
import { PrefixIdgComponent } from "../../impl/prefix-idg/prefix-idg.component";
import { PrefixIdhComponent } from "../../impl/prefix-idh/prefix-idh.component";
import { PrefixIdiComponent } from "../../impl/prefix-idi/prefix-idi.component";
import { PrefixIdjComponent } from "../../impl/prefix-idj/prefix-idj.component";
import { PrefixIdlComponent } from "../../impl/prefix-idl/prefix-idl.component";
import { PrefixIdoComponent } from "../../impl/prefix-ido/prefix-ido.component";
import { PrefixIdpComponent } from "../../impl/prefix-idp/prefix-idp.component";
import { PrefixIdrComponent } from "../../impl/prefix-idr/prefix-idr.component";
import { PrefixIdsComponent } from "../../impl/prefix-ids/prefix-ids.component";
import { PrefixIdtComponent } from "../../impl/prefix-idt/prefix-idt.component";
import { PrefixIduComponent } from "../../impl/prefix-idu/prefix-idu.component";
import { PrefixIm2Component } from "../../impl/prefix-im2/prefix-im2.component";
import { PrefixImgComponent } from "../../impl/prefix-img/prefix-img.component";
import { PrefixJa2Component } from "../../impl/prefix-ja2/prefix-ja2.component";
import { PrefixJaaComponent } from "../../impl/prefix-jaa/prefix-jaa.component";
import { PrefixJanComponent } from "../../impl/prefix-jan/prefix-jan.component";
import { PrefixJn3Component } from "../../impl/prefix-jn3/prefix-jn3.component";
import { PrefixJsnComponent } from "../../impl/prefix-jsn/prefix-jsn.component";
import { PrefixKnpComponent } from "../../impl/prefix-knp/prefix-knp.component";
import { PrefixKobComponent } from "../../impl/prefix-kob/prefix-kob.component";
import { PrefixKohComponent } from "../../impl/prefix-koh/prefix-koh.component";
import { PrefixKopComponent } from "../../impl/prefix-kop/prefix-kop.component";
import { PrefixLn2Component } from "../../impl/prefix-ln2/prefix-ln2.component";
import { PrefixLn3Component } from "../../impl/prefix-ln3/prefix-ln3.component";
import { PrefixLn4Component } from "../../impl/prefix-ln4/prefix-ln4.component";
import { PrefixLnkComponent } from "../../impl/prefix-lnk/prefix-lnk.component";
import { PrefixNaaComponent } from "../../impl/prefix-naa/prefix-naa.component";
import { PrefixNudComponent } from "../../impl/prefix-nud/prefix-nud.component";
import { PrefixNv2Component } from "../../impl/prefix-nv2/prefix-nv2.component";
import { PrefixNvlComponent } from "../../impl/prefix-nvl/prefix-nvl.component";
import { PrefixNvvComponent } from "../../impl/prefix-nvv/prefix-nvv.component";
import { PrefixOm2Component } from "../../impl/prefix-om2/prefix-om2.component";
import { PrefixOm4Component } from "../../impl/prefix-om4/prefix-om4.component";
import { PrefixOm5Component } from "../../impl/prefix-om5/prefix-om5.component";
import { PrefixOm6Component } from "../../impl/prefix-om6/prefix-om6.component";
import { PrefixOm7Component } from "../../impl/prefix-om7/prefix-om7.component";
import { PrefixOm8Component } from "../../impl/prefix-om8/prefix-om8.component";
import { PrefixOm9Component } from "../../impl/prefix-om9/prefix-om9.component";
import { PrefixOmlComponent } from "../../impl/prefix-oml/prefix-oml.component";
import { PrefixOmsComponent } from "../../impl/prefix-oms/prefix-oms.component";
import { PrefixPa2Component } from "../../impl/prefix-pa2/prefix-pa2.component";
import { PrefixPa3Component } from "../../impl/prefix-pa3/prefix-pa3.component";
import { PrefixPadComponent } from "../../impl/prefix-pad/prefix-pad.component";
import { PrefixPasComponent } from "../../impl/prefix-pas/prefix-pas.component";
import { PrefixPe2Component } from "../../impl/prefix-pe2/prefix-pe2.component";
import { PrefixPerComponent } from "../../impl/prefix-per/prefix-per.component";
import { PrefixPo2Component } from "../../impl/prefix-po2/prefix-po2.component";
import { PrefixPosComponent } from "../../impl/prefix-pos/prefix-pos.component";
import { PrefixPr2Component } from "../../impl/prefix-pr2/prefix-pr2.component";
import { PrefixPr3Component } from "../../impl/prefix-pr3/prefix-pr3.component";
import { PrefixPr6Component } from "../../impl/prefix-pr6/prefix-pr6.component";
import { PrefixPr7Component } from "../../impl/prefix-pr7/prefix-pr7.component";
import { PrefixPrcComponent } from "../../impl/prefix-prc/prefix-prc.component";
import { PrefixQrcComponent } from "../../impl/prefix-qrc/prefix-qrc.component";
import { PrefixRepComponent } from "../../impl/prefix-rep/prefix-rep.component";
import { PrefixRijComponent } from "../../impl/prefix-rij/prefix-rij.component";
import { PrefixRrnComponent } from "../../impl/prefix-rrn/prefix-rrn.component";
import { PrefixSaoComponent } from "../../impl/prefix-sao/prefix-sao.component";
import { PrefixSigComponent } from "../../impl/prefix-sig/prefix-sig.component";
import { PrefixSltComponent } from "../../impl/prefix-slt/prefix-slt.component";
import { PrefixSofComponent } from "../../impl/prefix-sof/prefix-sof.component";
import { PrefixTe2Component } from "../../impl/prefix-te2/prefix-te2.component";
import { PrefixTelComponent } from "../../impl/prefix-tel/prefix-tel.component";
import { PrefixTi2Component } from "../../impl/prefix-ti2/prefix-ti2.component";
import { PrefixTi3Component } from "../../impl/prefix-ti3/prefix-ti3.component";
import { PrefixTidComponent } from "../../impl/prefix-tid/prefix-tid.component";
import { PrefixTijComponent } from "../../impl/prefix-tij/prefix-tij.component";
import { PrefixTo3Component } from "../../impl/prefix-to3/prefix-to3.component";
import { PrefixToeComponent } from "../../impl/prefix-toe/prefix-toe.component";
import { PrefixUu3Component } from "../../impl/prefix-uu3/prefix-uu3.component";
import { PrefixUu4Component } from "../../impl/prefix-uu4/prefix-uu4.component";
import { PrefixUurComponent } from "../../impl/prefix-uur/prefix-uur.component";
import { PrefixVgpComponent } from "../../impl/prefix-vgp/prefix-vgp.component";
import { PrefixVolComponent } from "../../impl/prefix-vol/prefix-vol.component";
import { PrefixVriComponent } from "../../impl/prefix-vri/prefix-vri.component";
import { PrefixWa2Component } from "../../impl/prefix-wa2/prefix-wa2.component";
import { PrefixWa3Component } from "../../impl/prefix-wa3/prefix-wa3.component";
import { PrefixWacComponent } from "../../impl/prefix-wac/prefix-wac.component";
import { PrefixXdtComponent } from "../../impl/prefix-xdt/prefix-xdt.component";
import { PrefixXemComponent } from "../../impl/prefix-xem/prefix-xem.component";
import { PrefixXjaComponent } from "../../impl/prefix-xja/prefix-xja.component";
import { PrefixXprComponent } from "../../impl/prefix-xpr/prefix-xpr.component";
import { PrefixXxxComponent } from "../../impl/prefix-xxx/prefix-xxx.component";

export const FORM_PREFIX_DATA: PrefixData = {
  gk1: { class: PrefixGk1Component, inputs: {} },
  ba3: { class: PrefixBa3Component, inputs: {} },
  ico: { class: PrefixIcoComponent, inputs: {} },
  knp: { class: PrefixKnpComponent, inputs: {} },
  per: { class: PrefixPerComponent, inputs: {} },
  vgp: { class: PrefixVgpComponent, inputs: {} },
  vol: { class: PrefixVolComponent, inputs: {} },
  koh: { class: PrefixKohComponent, inputs: {} },
  kob: { class: PrefixKobComponent, inputs: {} },
  kop: { class: PrefixKopComponent, inputs: {} },
  idp: { class: PrefixIdpComponent, inputs: {} },
  tij: { class: PrefixTijComponent, inputs: {} },
  ti2: { class: PrefixTi2Component, inputs: {} },
  ti3: { class: PrefixTi3Component, inputs: {} },
  toe: { class: PrefixToeComponent, inputs: {} },
  dat: { class: PrefixDatComponent, inputs: {} },
  dis: { class: PrefixDisComponent, inputs: {} },
  di3: { class: PrefixDi3Component, inputs: {} },
  da2: { class: PrefixDa2Component, inputs: {} },
  b10: { class: PrefixB10Component, inputs: {} },
  b11: { class: PrefixB11Component, inputs: {} },
  oms: { class: PrefixOmsComponent, inputs: {} },
  fil: { class: PrefixFilComponent, inputs: {} },
  tel: { class: PrefixTelComponent, inputs: {} },
  te2: { class: PrefixTe2Component, inputs: {} },
  tid: { class: PrefixTidComponent, inputs: {} },
  bes: { class: PrefixBesComponent, inputs: {} },
  oml: { class: PrefixOmlComponent, inputs: {} },
  jaa: { class: PrefixJaaComponent, inputs: {} },
  gtp: { class: PrefixGtpComponent, inputs: {} },
  sof: { class: PrefixSofComponent, inputs: {} },
  naa: { class: PrefixNaaComponent, inputs: {} },
  bs2: { class: PrefixBs2Component, inputs: {} },
  bs3: { class: PrefixBs3Component, inputs: {} },
  wac: { class: PrefixWacComponent, inputs: {} },
  hui: { class: PrefixHuiComponent, inputs: {} },
  htv: { class: PrefixHtvComponent, inputs: {} },
  pos: { class: PrefixPosComponent, inputs: {} },
  om8: { class: PrefixOm8Component, inputs: {} },
  eml: { class: PrefixEmlComponent, inputs: {} },
  bel: { class: PrefixBelComponent, inputs: {} },
  bs4: { class: PrefixBs4Component, inputs: {} },
  bs5: { class: PrefixBs5Component, inputs: {} },
  bs6: { class: PrefixBs6Component, inputs: {} },
  bs7: { class: PrefixBs7Component, inputs: {} },
  bs8: { class: PrefixBs8Component, inputs: {} },
  bs9: { class: PrefixBs9Component, inputs: {} },
  cd8: { class: PrefixCd8Component, inputs: {} },
  cd9: { class: PrefixCd9Component, inputs: {} },
  cdf: { class: PrefixCdfComponent, inputs: {} },
  cdp: { class: PrefixCdpComponent, inputs: {} },
  cdx: { class: PrefixCdxComponent, inputs: {} },
  chx: { class: PrefixChxComponent, inputs: {} },
  cdy: { class: PrefixCdyComponent, inputs: {} },
  de2: { class: PrefixDe2Component, inputs: {} },
  de3: { class: PrefixDe3Component, inputs: {} },
  de4: { class: PrefixDe4Component, inputs: {} },
  de5: { class: PrefixDe5Component, inputs: {} },
  dec: { class: PrefixDecComponent, inputs: {} },
  em2: { class: PrefixEm2Component, inputs: {} },
  hu2: { class: PrefixHu2Component, inputs: {} },
  gt2: { class: PrefixGt2Component, inputs: {} },
  gt4: { class: PrefixGt4Component, inputs: {} },
  gt5: { class: PrefixGt5Component, inputs: {} },
  gt7: { class: PrefixGt7Component, inputs: {} },
  be2: { class: PrefixBe2Component, inputs: {} },
  be3: { class: PrefixBe3Component, inputs: {} },
  be6: { class: PrefixBe6Component, inputs: {} },
  bed: { class: PrefixBedComponent, inputs: {} },
  bla: { class: PrefixBlaComponent, inputs: {} },
  hi2: { class: PrefixHi2Component, inputs: {} },
  hi3: { class: PrefixHi3Component, inputs: {} },
  hid: { class: PrefixHidComponent, inputs: {} },
  ja2: { class: PrefixJa2Component, inputs: {} },
  nud: { class: PrefixNudComponent, inputs: {} },
  nv2: { class: PrefixNv2Component, inputs: {} },
  nvl: { class: PrefixNvlComponent, inputs: {} },
  om2: { class: PrefixOm2Component, inputs: {} },
  om4: { class: PrefixOm4Component, inputs: {} },
  om5: { class: PrefixOm5Component, inputs: {} },
  om6: { class: PrefixOm6Component, inputs: {} },
  om7: { class: PrefixOm7Component, inputs: {} },
  om9: { class: PrefixOm9Component, inputs: {} },
  pad: { class: PrefixPadComponent, inputs: {} },
  pas: { class: PrefixPasComponent, inputs: {} },
  po2: { class: PrefixPo2Component, inputs: {} },
  pr2: { class: PrefixPr2Component, inputs: {} },
  pr3: { class: PrefixPr3Component, inputs: {} },
  pr6: { class: PrefixPr6Component, inputs: {} },
  pr7: { class: PrefixPr7Component, inputs: {} },
  rij: { class: PrefixRijComponent, inputs: {} },
  sig: { class: PrefixSigComponent, inputs: {} },
  rrn: { class: PrefixRrnComponent, inputs: {} },
  vri: { class: PrefixVriComponent, inputs: {} },
  xxx: { class: PrefixXxxComponent, inputs: {} },
  uur: { class: PrefixUurComponent, inputs: {} },
  uu3: { class: PrefixUu3Component, inputs: {} },
  uu4: { class: PrefixUu4Component, inputs: {} },
  img: { class: PrefixImgComponent, inputs: {} },
  im2: { class: PrefixIm2Component, inputs: {} },
  nvv: { class: PrefixNvvComponent, inputs: {} },
  pa2: { class: PrefixPa2Component, inputs: {} },
  pa3: { class: PrefixPa3Component, inputs: {} },
  wa2: { class: PrefixWa2Component, inputs: {} },
  wa3: { class: PrefixWa3Component, inputs: {} },
  xpr: { class: PrefixXprComponent, inputs: {} },
  xem: { class: PrefixXemComponent, inputs: {} },
  xdt: { class: PrefixXdtComponent, inputs: {} },
  to3: { class: PrefixTo3Component, inputs: {} },
  pe2: { class: PrefixPe2Component, inputs: {} },
  xja: {
    class: PrefixXjaComponent,
    inputs: {
      label: "label",
    },
  },
  prc: { class: PrefixPrcComponent, inputs: {} },
  sao: {
    class: PrefixSaoComponent,
    inputs: {
      output: "output",
      hours: "hours",
      groups: "groups",
    },
  },
  idg: {
    class: PrefixIdgComponent,
    inputs: {
      groups: "listvaluesgroups",
      options: "listvalues",
    },
  },
  idf: { class: PrefixIdfComponent, inputs: { options: "listvalues" } },
  idh: { class: PrefixIdhComponent, inputs: { options: "listvalues" } },
  idi: { class: PrefixIdiComponent, inputs: { options: "listvalues" } },
  idj: { class: PrefixIdjComponent, inputs: { options: "listvalues" } },
  idl: { class: PrefixIdlComponent, inputs: { options: "listvalues" } },
  ido: { class: PrefixIdoComponent, inputs: { options: "listvalues" } },
  idr: { class: PrefixIdrComponent, inputs: { options: "listvalues" } },
  ids: { class: PrefixIdsComponent, inputs: { options: "listvalues" } },
  idt: { class: PrefixIdtComponent, inputs: { options: "listvalues" } },
  idu: { class: PrefixIduComponent, inputs: { options: "listvalues" } },
  gri: { class: PrefixGriComponent, inputs: { date: "date", text: "text" } },
  gr2: { class: PrefixGr2Component, inputs: { date: "date", text: "text" } },
  gr3: { class: PrefixGr3Component, inputs: { date: "date", text: "text" } },
  gr4: { class: PrefixGr4Component, inputs: { date: "date", text: "text" } },
  jan: { class: PrefixJanComponent, inputs: { options: "checkbox" } },
  jn3: { class: PrefixJn3Component, inputs: { options: "checkbox" } },
  lnk: { class: PrefixLnkComponent, inputs: { routes: "links" } },
  ln3: { class: PrefixLn3Component, inputs: { routes: "links" } },
  ln4: { class: PrefixLn4Component, inputs: { routes: "links" } },
  ln2: { class: PrefixLn2Component, inputs: { href: "links" } },
  qrc: { class: PrefixQrcComponent, inputs: {} },
  jsn: { class: PrefixJsnComponent, inputs: { schema: "schema" } },
  rep: { class: PrefixRepComponent, inputs: { repeating: "repeating", repeatingConditionalSetter: "repeatingConditionalSetter" } },
  geo: { class: PrefixGeoComponent, inputs: { long: "long", lat: "lat" } },
  slt: { class: PrefixSltComponent, inputs: {} },
  ban: { class: PrefixBanComponent, inputs: {} },
  ba2: { class: PrefixBa2Component, inputs: {} },
  bae: { class: PrefixBaeComponent, inputs: {} },
  ...QUESTION_TYPE_DATA,
};
