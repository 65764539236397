import { Component } from "@angular/core";
import { MatIconModule } from "@angular/material/icon";
import { TranslateModule } from "@ngx-translate/core";
import { ModalComponent } from "../modal/modal.component";

@Component({
  standalone: true,
  selector: "app-dialog-force",
  templateUrl: "./force.component.html",
  styleUrl: "./force.component.less",
  imports: [TranslateModule, MatIconModule],
})
export class ForceComponent extends ModalComponent {
  public constructor() {
    super();
  }
}
