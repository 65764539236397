@if (control) {
  @if (!disabled) {
    <div class="hexadecimal-container">
      <input [ariaLabel]="label" [formControl]="control" [placeholder]="placeholder" [maxlength]="maxlength" type="text" (beforeinput)="onBeforeInput($event)" />
      <ng-container [ngTemplateOutlet]="preview" />
    </div>
  } @else {
    <ng-container [ngTemplateOutlet]="preview" />
  }
}

<ng-template #preview>
  <span [ariaLabel]="label" role="img" class="preview" [style.backgroundColor]="value ? '#' + value : 'transparent'"></span>
</ng-template>
