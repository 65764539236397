import { CommonModule } from "@angular/common";
import { AfterViewInit, Component, Input } from "@angular/core";
import { MatButtonModule } from "@angular/material/button";
import { MatIconModule } from "@angular/material/icon";
import { TranslateModule } from "@ngx-translate/core";
import { BehaviorSubject, Subscription } from "rxjs";
import { FormField } from "src/app/components/default/form/form-field";
import { TooltipDirective } from "src/directives/tooltip.directive";
import { FormThreatTooltipComponent } from "../../../tooltip/impl/form-threat-tooltip/form-threat-tooltip.component";
import { FormPrefixComponent } from "../../containers/form-prefix/form-prefix.component";
import { PrefixComponent, ThreatLevel } from "../../prefix.component";

interface RepeatedGroup {
  index: number;
  label: string;
  fields: FormField<unknown>[];
  threatSubscription: Subscription;
  errors: Map<number, FormField>;
  warnings: Map<number, FormField>;
}
@Component({
  selector: "app-prefix-rep",
  standalone: true,
  imports: [FormPrefixComponent, MatIconModule, TooltipDirective, TranslateModule, MatButtonModule, CommonModule],
  templateUrl: "./prefix-rep.component.html",
  styleUrl: "./prefix-rep.component.less",
})
export class PrefixRepComponent extends PrefixComponent<Map<string, FormField<unknown>[]>> implements AfterViewInit {
  @Input()
  public repeating: FormField<unknown>[];

  @Input()
  public repeatingConditionalSetter?: (fields: FormField[]) => void;

  public threatTooltip: typeof FormThreatTooltipComponent;

  public repeated: RepeatedGroup[];

  public deactivate: BehaviorSubject<boolean>;
  public dirty: boolean;

  public constructor() {
    super();
    this.repeated = [];
    this.repeating = [];

    this.threatTooltip = FormThreatTooltipComponent;
    this.deactivate = new BehaviorSubject(true);
    this.dirty = false;
  }

  public ngAfterViewInit(): void {
    if (this.value) {
      const values = Array.from(this.value.entries());

      for (const [valueKey, valueFields] of values) {
        const repeatedGroup = {
          index: parseInt(valueKey),
          label: valueKey,
          fields: valueFields,
          threatSubscription: new Subscription(),
          errors: new Map<number, FormField>(),
          warnings: new Map<number, FormField>(),
        };
        this.threatListener4Group(repeatedGroup);
        this.setConditionals(repeatedGroup.fields);
        this.repeated.push(repeatedGroup);
      }
    }
  }

  public add(): void {
    const index = this.getHighestIndexValue() + 1;
    const copiedFields: FormField<unknown>[] = [];
    for (const repeatingField of this.repeating) {
      const field = new FormField(
        repeatingField.name,
        repeatingField.value.value,
        repeatingField.template,
        repeatingField.label,
        repeatingField.extras,
      );
      copiedFields.push(field);
    }
    const repeatedGroup = {
      index,
      label: `${index}`,
      fields: copiedFields, //the repeating fields
      threatSubscription: new Subscription(),
      errors: new Map<number, FormField>(),
      warnings: new Map<number, FormField>(),
    };
    this.threatListener4Group(repeatedGroup);

    this.setConditionals(repeatedGroup.fields);
    this.repeated.push(repeatedGroup);
    this.threatCheck();
    this.insertRepeatedIntoValue();
  }

  public remove(index: number): void {
    this.repeated.find((val) => val.index === index)?.threatSubscription.unsubscribe();
    this.repeated = this.repeated.filter((val) => val.index !== index);
    this.threatCheck();
    this.insertRepeatedIntoValue();
  }

  public threatListener4Group(repeatedGroup: {
    index: number;
    label: string;
    fields: FormField<unknown>[];
    threatSubscription: Subscription;
    errors: Map<number, FormField>;
    warnings: Map<number, FormField>;
  }): void {
    const sub = new Subscription();
    for (const [index, field] of repeatedGroup.fields.entries()) {
      sub.add(
        field.threat.subscribe((threat) => {
          switch (threat[1].type) {
            case ThreatLevel.VALID:
              if (!field.errors.size) repeatedGroup.errors.delete(index);
              if (!field.warnings.size) repeatedGroup.warnings.delete(index);
              break;

            case ThreatLevel.ERROR:
              repeatedGroup.errors.set(index, field);
              break;

            case ThreatLevel.WARNING:
              repeatedGroup.warnings.set(index, field);
              break;

            default:
              console.error(`Undefined threat level: ${threat}`);
              break;
          }

          this.emitThreatChilds(this.getErrors(repeatedGroup).length > 0, this.getWarnings(repeatedGroup).length > 0);
        }),
      );
      sub.add(
        field.value.subscribe(() => {
          this.insertRepeatedIntoValue();
        }),
      );
    }
  }

  public threatCheck(): void {
    let containsErrors = false;
    let containsWarnings = false;

    for (const repeatedGroup of this.repeated) {
      if (this.getErrors(repeatedGroup).length > 0) containsErrors = true;
      if (this.getWarnings(repeatedGroup).length > 0) containsWarnings = true;
    }

    this.emitThreatChilds(containsErrors, containsWarnings);
  }

  public getErrors(repeatedGroup: RepeatedGroup): FormField[] {
    return Array.from(repeatedGroup.errors.values()).filter((field) => !field.hidden && field.condition.value);
  }

  public getWarnings(repeatedGroup: RepeatedGroup): FormField[] {
    return Array.from(repeatedGroup.warnings.values()).filter((field) => !field.hidden && field.condition.value);
  }

  private emitThreatChilds(containsErrors: boolean, containsWarnings: boolean): void {
    if (containsErrors) {
      this.threatchanged.emit(["VALIDATION_REPEATING_CHILDS", { type: ThreatLevel.ERROR }]);
    } else if (containsWarnings) {
      this.threatchanged.emit(["VALIDATION_REPEATING_CHILDS", { type: ThreatLevel.WARNING }]);
    } else {
      this.threatchanged.emit(["VALIDATION_REPEATING_CHILDS", { type: ThreatLevel.VALID }]);
    }
  }

  private insertRepeatedIntoValue(): void {
    const map = new Map<string, FormField<unknown>[]>();

    for (const repeatedGroup of this.repeated) {
      map.set(repeatedGroup.label, repeatedGroup.fields);
    }
    this.updateControl(map);
  }

  private setConditionals(fields: FormField[]): void {
    if (typeof this.repeatingConditionalSetter !== "function") return;
    this.repeatingConditionalSetter(fields);
  }

  private getHighestIndexValue(): number {
    if (this.repeated.length === 0) return 0;
    return this.repeated
      .map((val) => val.index)
      .sort((a, b) => a - b)
      .reverse()[0];
  }
}
