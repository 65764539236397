@if (data) {
  <div class="container-fluid d-flex align-items-center">
    <div class="icon">
      <span class="icon-circle">
        <mat-icon>priority_high</mat-icon>
      </span>
    </div>
    <div class="d-flex flex-column gap">
      <span class="title">{{ "SNACKBAR.WARNING" | translate }}</span>
      <p [innerHTML]="data | translate"></p>
    </div>
  </div>
}
