<div class="container-fluid">
  <div class="row">
    <div class="col-md group-container">
      @for (group of groups; track group) {
        <div class="group">
          <div class="row">
            <div class="col title">
              <span>{{ "SAO.HOURS" | translate }}</span>
            </div>

            <div class="col title">
              <span>{{ "SAO.WAGE" | translate }}</span>
            </div>
          </div>

          @for (row of group.value; track row) {
            <div class="row group-row">
              <div class="label">
                <span>{{ row.label }}</span>
              </div>

              <div class="col field-prefix" [ngClass]="{ error: getField(group.labelSuffix, row.label)?.errors?.size }">
                <app-form-prefix [field]="getField(group.labelSuffix, row.label)" />
              </div>

              <div class="col-md field-prefix" [ngClass]="{ error: getField(group.labelSuffix, 'LW')?.errors?.size }">
                @if ($index === 0) {
                  <app-form-prefix [field]="getField(group.labelSuffix, 'LW')" />
                }
              </div>
            </div>
          }
        </div>
      }
    </div>
  </div>

  <div class="row total-container">
    <div class="col field-prefix bruto">
      <span>{{ "SAO.BRUTO" | translate }}</span>
      <app-prefix-gt2 (controlchanged)="bruto = $event" [disabled]="true" />
    </div>

    <div class="col-md field-prefix netto">
      <span>{{ "SAO.NETTO" | translate }}</span>
      <app-prefix-gt2 (controlchanged)="netto = $event" [disabled]="true" />
    </div>
  </div>
</div>
