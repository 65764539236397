import { v4 as uuidv4 } from "uuid";
import { TableField } from "./table-field";
import { TableHeader } from "./table-header";

export class TableRow {
  public id: string;
  public deletelink: string;
  public downloadlink: string;
  public fields: Map<TableHeader, TableField>;

  public hidden: boolean;
  public deleted: boolean;
  public selected: boolean;
  public highlighted: boolean;

  public constructor() {
    this.id = uuidv4();
    this.fields = new Map<TableHeader, TableField>();
    this.deletelink = "";
    this.downloadlink = "";

    this.hidden = false;
    this.deleted = false;
    this.selected = false;
    this.highlighted = false;
  }

  public delete(_delete = true): void {
    this.deleted = _delete;
  }

  public hide(hide = true): void {
    this.hidden = hide;
  }

  public select(select = true): void {
    this.selected = select;
  }

  public highlight(highlight = true): void {
    this.highlighted = highlight;
  }
}
