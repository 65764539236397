import { CommonModule } from "@angular/common";
import { Component, Input, OnInit } from "@angular/core";
import { FormControl, ReactiveFormsModule } from "@angular/forms";
import { DefaultComponent } from "src/app/default.component";
import { PrefixTemplate } from "../../PrefixTemplate";
import { PrefixValidator } from "../../PrefixValidator";
import { Regex } from "../../types/Regex";
import { TemplateHTMLComponent } from "../template-html/template-html.component";

@Component({
  standalone: true,
  selector: "app-template-date",
  imports: [ReactiveFormsModule, TemplateHTMLComponent, CommonModule],
  templateUrl: "./template-date.component.html",
  styleUrl: "./template-date.component.less",
})
export class TemplateDateComponent extends DefaultComponent implements PrefixTemplate<string>, OnInit {
  @Input({ required: true })
  public control: FormControl<string | null> | null;

  @Input()
  public value: string | null;

  @Input()
  public regex: Regex;

  @Input()
  public required: boolean;

  @Input()
  public disabled: boolean;

  @Input()
  public characters: string | null;

  @Input()
  public label: string | null;

  public date: FormControl<string | null>;

  public constructor() {
    super();

    this.control = null;
    this.value = null;
    this.regex = null;
    this.required = false;
    this.disabled = false;
    this.characters = null;
    this.label = null;

    this.date = new FormControl(null);
  }

  public ngOnInit(): void {
    const control = this.control;
    if (control) {
      this.addValidators(control);
      this.date.valueChanges.subscribe((date) => this.onChange(date));
      console.log(this.value);
      this.date.setValue(this.value);
    } else {
      throw new Error("Undefined control");
    }
  }

  private addValidators(control: FormControl<string | null>): void {
    if (this.regex) control.addValidators([PrefixValidator.regex(this.regex)]);
    if (this.required) control.addValidators([PrefixValidator.required()]);
    control.addValidators([PrefixValidator.minDate("1753-01-01")]);
    control.addValidators([PrefixValidator.maxDate("2099-12-31")]);
    control.updateValueAndValidity();
  }

  /**
   * fired when control date changes
   * @param date
   */
  private onChange(date: string | null): void {
    const control = this.control;
    if (control) {
      control.markAsDirty();
      control.setValue(date ? date : null);
    } else {
      throw new Error("Undefined control");
    }
  }
}
