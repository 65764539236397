import { Component } from "@angular/core";
import { ALLOWED_CHARACTERS } from "src/config/allowed-characters.config";
import { PrefixComponent } from "../../prefix.component";
import { TemplateSignatureComponent } from "../../templates/template-signature/template-signature.component";

@Component({
  standalone: true,
  selector: "app-prefix-sig",
  imports: [TemplateSignatureComponent],
  templateUrl: "./prefix-sig.component.html",
  styleUrl: "./prefix-sig.component.less",
})
export class PrefixSigComponent extends PrefixComponent<string> {
  public constructor() {
    super();
    this.characters = ALLOWED_CHARACTERS["sig"];
  }
}
