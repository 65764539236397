<div class="container-fluid">
  <div class="row formContainer" #formContainer (cdkObserveContent)="widthCheck(formContainer)" [class.hide-labels]="hideLabelColumn">
    @if (pageStack.length === 1) {
      <div class="col-md">
        @if (options.head) {
          <div class="row form-head-container">
            @if (options.attachments || credits.author || credits.editor) {
              <div class="col-md form-head">
                <div class="form-addons">
                  <ul>
                    @if (options.attachments) {
                      <li class="action attachments" (click)="openAttachments()" [tooltip]="'TOOLTIP.ATTACHMENTS'">
                        @if (!attachments.size) {
                          <span class="attachment"></span>
                        } @else {
                          <span class="attachment-filled"></span>
                        }
                      </li>
                    }
                    @if (credits.author || credits.editor) {
                      <li class="action" [tooltip]="{ component: creditsTooltip, data: credits }">
                        <mat-icon>schedule</mat-icon>
                      </li>
                    }
                  </ul>
                </div>
              </div>
            }
          </div>
        }

        <div class="row form-body">
          <div class="col-md">
            <ng-container *ngTemplateOutlet="body" />
          </div>
        </div>

        @if (options.foot || buttons.length) {
          <div class="row form-footer-container">
            <div class="col-md form-foot">
              <div class="form-addons">
                <ul>
                  <app-buttons [buttons]="buttons" (event)="onEvent($event)" [disabled]="state.value !== 'VALID' || (!buttonenabled && !dirty)" />
                </ul>
              </div>

              <div class="extra">
                <label></label>
              </div>
            </div>
          </div>
        }
      </div>
    } @else {
      <div class="row">
        <div class="col-md form-head stacked">
          <div class="previous-container" (click)="previousPage()">
            <div class="previous">
              <mat-icon>keyboard_arrow_left</mat-icon>
            </div>
          </div>

          <div class="form-breadcrumbs">
            <div class="crumbs-container">
              @for (stack of pageStack; track $index) {
                <span class="crumb">
                  <label (click)="setPage($index)">{{ stack.title }}</label>
                  @if ($last) {
                    <mat-icon>keyboard_arrow_right</mat-icon>
                  }
                </span>
              }
            </div>
          </div>
        </div>
      </div>

      <div class="row form-body">
        <div class="col-md">
          <ng-container *ngTemplateOutlet="body" />
        </div>
      </div>

      @if (options.foot) {
        <div class="row">
          <div class="col-md form-foot">
            <div class="extra">
              <label></label>
            </div>

            <div class="form-addons">
              <app-buttons [buttons]="buttons" (event)="onEvent($event)" />
            </div>
          </div>
        </div>
      }
    }
  </div>
</div>

<ng-template #body>
  <mat-accordion class="clean compact" [multi]="options.multi" [class.plain]="!(fields.size > 1 && page.value.fields.size > 1)">
    @for (category of page.value.fields | keyvalue; track $index) {
      <div class="row" [class.hidden]="(category.value.condition | async) === false">
        <div class="col-md">
          <mat-expansion-panel
            #panel
            hideToggle
            [expanded]="$index === 0 || category.value.expanded"
            [disabled]="!category.value.expandable"
            [class.flat]="category.value.name.length === 0"
          >
            @if (fields.size > 1 && page.value.fields.size > 1 && category.value.name.length > 0) {
              <mat-expansion-panel-header class="category-head">
                <div class="row">
                  <div class="col-md labelContainer" [ngClass]="{ error: false, warning: false }" (click)="log(category)">
                    <div class="label">
                      @if (category.value.expandable === true) {
                        <div class="icon">
                          <mat-icon>chevron_right</mat-icon>
                        </div>
                      }
                      <label>{{ category.value.label }}</label>
                    </div>
                    @let errors = getErrors(category.value);
                    @let warnings = getWarnings(category.value);
                    @if (errors.length) {
                      <div class="status">
                        <mat-icon class="error-icon" [tooltip]="'FORM.THREATS.CATEGORY' | translate: { amount: errors.length }">error_outline</mat-icon>
                      </div>
                    }

                    @if (warnings.length) {
                      <div class="status">
                        <mat-icon class="warning-icon" [tooltip]="'FORM.THREATS.CATEGORY.WARNING' | translate: { amount: warnings.length }">warning_amber</mat-icon>
                      </div>
                    }
                  </div>
                </div>
              </mat-expansion-panel-header>
            }

            @if (category.value.fields.size) {
              <div class="row category-body">
                <div class="col-md">
                  @for (row of category.value.rows; track $index) {
                    <div class="category-row">
                      @for (field of row; track $index) {
                        @if (field.tile) {
                          <app-form-field-tile [field]="field" />
                        } @else {
                          <div
                            class="field"
                            [class]="field.template"
                            [ngClass]="{ hidden: field.hidden || !field.condition.value, 'no-border': isFirstNonHidden(category.value, field) }"
                            (click)="log(field)"
                          >
                            @if (field.labelVisibility !== 2) {
                              <div class="field-name-container" [class.no-label]="!field.label || field.labelVisibility === 1">
                                <div class="field-name">
                                  {{ field.label }}
                                  @if (field.required) {
                                    <span class="required">*</span>
                                  }
                                </div>
                              </div>
                            }

                            <div class="field-prefix" [ngClass]="{ error: field.errors.size && _field.field?.touched, warning: field.warnings.size && _field.field?.touched }">
                              <app-form-prefix
                                #_field
                                [field]="field"
                                [ngClass]="[field.template]"
                                (onchange)="checkState()"
                                (dirty)="dirty = true; deactivate.next(false)"
                                [tooltip]="application.debug ? field.template : ''"
                              />
                              @if (field.labelVisibility !== 2) {
                                @if (field.errors.size || field.warnings.size || field.tooltip) {
                                  <div class="extras">
                                    @if ((field.errors.size || field.warnings.size) && _field.field?.touched) {
                                      <div class="threat-container">
                                        @if (field.errors.size) {
                                          <div class="status">
                                            <mat-icon class="error-icon" [tooltip]="{ component: threatTooltip, data: field.errors }">priority_high</mat-icon>
                                          </div>
                                        }
                                        @if (field.warnings.size) {
                                          <div class="status">
                                            <mat-icon class="warning-icon" [tooltip]="{ component: threatTooltip, data: field.warnings }">warning_amber</mat-icon>
                                          </div>
                                        }
                                      </div>
                                    } @else {
                                      <div class="tooltip-container">
                                        @if (field.tooltip) {
                                          <span class="tooltip-content" [tooltip]="field.tooltip">
                                            <mat-icon>info</mat-icon>
                                          </span>
                                        }
                                      </div>
                                    }
                                  </div>
                                }
                              }
                            </div>
                          </div>
                        }
                      }
                    </div>
                  }
                </div>
              </div>
            }
          </mat-expansion-panel>
        </div>
      </div>
    }
  </mat-accordion>
</ng-template>
