import { ObserversModule } from "@angular/cdk/observers";
import { CommonModule } from "@angular/common";
import { AfterContentInit, AfterViewInit, Component, EventEmitter, Input, OnInit, Output, inject } from "@angular/core";
import { MatExpansionModule } from "@angular/material/expansion";
import { MatIconModule } from "@angular/material/icon";
import { NavigationStart, Router } from "@angular/router";
import { TranslateModule, TranslateService } from "@ngx-translate/core";
import { BehaviorSubject, distinctUntilChanged, filter, pairwise } from "rxjs";
import { DefaultComponent } from "src/app/default.component";
import { Attachment } from "src/classes/Attachment";
import { TooltipDirective } from "src/directives/tooltip.directive";
import { DeactivateGuard } from "src/guards/deactivate.guard";
import { ApplicationService } from "src/services/application.service";
import { HttpService } from "src/services/http.service";
import { SessionService } from "src/services/session.service";
import { v4 as uuidv4 } from "uuid";
import { DialogService } from "../../global/dialog/dialog.service";
import { FormPrefixComponent } from "../../global/prefixes/containers/form-prefix/form-prefix.component";
import { ErrorMessageComponent } from "../../global/snackbar/impl/error-message/error-message.component";
import { SnackbarService } from "../../global/snackbar/snackbar.service";
import { CreditsTooltipComponent } from "../../global/tooltip/impl/credits-tooltip/credits-tooltip.component";
import { FormThreatTooltipComponent } from "../../global/tooltip/impl/form-threat-tooltip/form-threat-tooltip.component";
import { ButtonAction, ButtonEvent, ButtonsComponent } from "../buttons/buttons.component";
import { FormCategory } from "./form-category";
import { FormField, LabelVisibility } from "./form-field";
import { FormFieldTileComponent } from "./form-field-tile/form-field-tile.component";
import { FormFields, Mode, Page, State } from "./form.definition";
import { FormCredits, FormOptions, FormResponseButton } from "./form.interface";

export interface FormAction {
  type: ButtonAction;
  params: unknown[];
  form: FormComponent;
  dialogRef?: string;
}

export interface StateChange {
  state: State;
  form: FormComponent;
}

@Component({
  standalone: true,
  selector: "app-form",
  imports: [
    MatIconModule,
    ButtonsComponent,
    FormPrefixComponent,
    MatExpansionModule,
    TranslateModule,
    CommonModule,
    TooltipDirective,
    ObserversModule,
    FormFieldTileComponent,
  ],
  templateUrl: "./form.component.html",
  styleUrls: ["./form-prefix.component.less", "./form.component.less", "./form.print.less"],
})
export class FormComponent extends DefaultComponent implements OnInit, AfterContentInit, AfterViewInit {
  public application: ApplicationService;
  public session: SessionService;
  public snackbar: SnackbarService;
  public dialog: DialogService;
  public http: HttpService;
  public router: Router;
  private translate: TranslateService;
  private guard: DeactivateGuard;

  public pageStack: Page[];
  public page: BehaviorSubject<Page>;

  public width: number | null;

  @Input()
  public id: string;

  @Input()
  public title: string | null;

  @Input()
  public fields: FormFields;

  @Input()
  public collection: FormField[];

  @Input()
  public attachments: Map<number, Attachment>;

  @Input()
  public mode: Mode;

  public state: BehaviorSubject<State>;

  @Input()
  public credits: FormCredits;

  @Input()
  public options: FormOptions;

  @Input()
  public buttons: FormResponseButton[];

  @Input()
  public buttonenabled: boolean;

  @Input()
  public listenForRouteChanges: boolean;

  @Input()
  public dialogRef: string | null;

  @Output()
  public event: EventEmitter<FormAction>;

  @Output()
  public statechange: EventEmitter<StateChange>;

  @Output()
  public widthChange: EventEmitter<number | null>;

  @Output()
  public openAttachment: EventEmitter<{
    dialog: DialogService;
    form: FormComponent;
  }>;

  public creditsTooltip: typeof CreditsTooltipComponent;
  public threatTooltip: typeof FormThreatTooltipComponent;

  public dirty: boolean;
  public hideLabelColumn: boolean;

  public deactivate: BehaviorSubject<boolean>;

  public constructor() {
    super();

    this.application = inject(ApplicationService);
    this.session = inject(SessionService);
    this.snackbar = inject(SnackbarService);
    this.dialog = inject(DialogService);
    this.http = inject(HttpService);
    this.router = inject(Router);
    this.translate = inject(TranslateService);
    this.guard = inject(DeactivateGuard);
    this.dialogRef = null;

    this.pageStack = [];
    this.page = new BehaviorSubject<Page>({
      fields: new Map(),
      title: null,
    });
    this.collection = [];

    this.id = uuidv4();
    this.title = null;

    this.fields = new Map();
    this.attachments = new Map();

    this.mode = Mode.EDIT;
    this.listenForRouteChanges = true;

    this.width = null;
    this.dirty = false;
    this.buttonenabled = false;

    this.state = new BehaviorSubject<State>(State.VALID);

    this.credits = {
      author: "",
      editor: "",
      createdAt: -1,
      editedAt: -1,
    };

    this.options = {
      attachments: true,
      foot: true,
      head: true,
      multi: true,
    };

    this.buttons = [];
    this.event = new EventEmitter();
    this.statechange = new EventEmitter();
    this.openAttachment = new EventEmitter();
    this.widthChange = new EventEmitter();
    this.deactivate = new BehaviorSubject(true);

    this.creditsTooltip = CreditsTooltipComponent;
    this.threatTooltip = FormThreatTooltipComponent;
    this.hideLabelColumn = false;
  }

  public ngOnInit(): void {
    this.nextPage({
      fields: this.fields,
      title: this.title,
    });

    this.addSubscription(
      this.state.pipe(distinctUntilChanged()).subscribe((state) => this.onStateChange(state)),
      this.deactivate.subscribe((deactivate) => (this.application.deactivate = deactivate)),
    );

    if (this.listenForRouteChanges) {
      this.addSubscription(this.router.events.pipe(filter((e) => e instanceof NavigationStart)).subscribe(() => this.onRouteChange()));
    }

    this.options.foot = !!this.buttons.length;
    this.deactivate.next(!this.dirty);

    if (this.mode === Mode.VIEW) {
      for (const field of this.getAllFields()) {
        field.readonly = true;
      }
    }
  }

  public ngAfterContentInit(): void {
    this.togglePanels("hi3KlapkoppenStatusOpen");
    this.togglePanels("hi3KlapkoppenStatusDicht", false);
  }

  public ngAfterViewInit(): void {
    for (const field of this.collection) {
      field.value.pipe(pairwise()).subscribe(([prev, current]) => {
        if (prev != current && prev != null) {
          this.deactivate.next(false);
        }
      });
    }

    this.hideLabelColumn = this.getAllFields().every((field) => {
      return field.hidden || [LabelVisibility.hidden, LabelVisibility.invisible].includes(field.labelVisibility) || !field.label;
    });
  }

  public async onEvent(event: ButtonEvent): Promise<void> {
    const type = event.action;
    const params = event.params;
    const fields = this.getFields();
    const dialogRef = this.dialogRef;

    this.event.emit({
      type: type,
      params: [fields, ...params],
      form: this,
      dialogRef: dialogRef ?? undefined,
    });

    this.deactivate.next(true);
  }

  /**
   * Open attachment dialog
   */
  public openAttachments(): void {
    this.openAttachment.emit({
      dialog: this.dialog,
      form: this,
    });
  }

  /**
   * Add a page to pageStack
   * @param page
   */
  public nextPage(page: Page): void {
    this.page.next(page);
    this.pageStack.push(page);
  }

  /**
   * Set the page to a specific index of pageStack
   * @param index
   */
  public setPage(index: number): void {
    this.pageStack = this.pageStack.slice(0, index + 1);
    this.page.next(this.pageStack[this.pageStack.length - 1]);
  }

  /**
   * Go back to the previou page
   */
  public previousPage(): void {
    this.setPage(this.pageStack.length - 2);
  }

  /**
   * Return all visible fields with errors
   * @param category
   * @returns
   */
  public getErrors(category: FormCategory): FormField[] {
    return Array.from(category.errors.values()).filter((field) => !field.hidden && field.condition.value && field.touched);
  }

  /**
   * Return all visible fields with warnings
   * @param category
   * @returns
   */
  public getWarnings(category: FormCategory): FormField[] {
    return Array.from(category.warnings.values()).filter((field) => !field.hidden && field.condition.value && field.touched);
  }

  /**
   * Shorthand valid check
   * @returns
   */
  public isValid(): boolean {
    return this.state.value === State.VALID;
  }

  public widthCheck(element: HTMLDivElement): void {
    if (this.width !== null && this.width < element.clientWidth) {
      this.width = element.clientWidth;
    } else if (this.width === null) {
      this.width = element.clientWidth;
    }
    this.widthChange.emit(this.width);
  }

  public isFirstNonHidden(category: FormCategory, field: FormField): boolean {
    return Array.from(category.fields.values()).find((field) => !field.hidden) === field;
  }

  public checkState(): void {
    let valid = true;
    for (const category of this.fields.values()) {
      for (const field of category.fields.values()) {
        if (field.errors.size && (field.condition.value || field.hidden)) {
          valid = false;
          break;
        }
      }
    }
    this.state.next(valid ? State.VALID : State.INVALID);
  }

  /**
   * Fired when navigation starts
   */
  private onRouteChange(): void {
    let deactive = this.application.deactivate;
    if (!deactive && confirm(this.translate.instant("FORM.CONFIRM"))) {
      deactive = true;
    } else {
      this.application.loading.next(false);
    }
    this.guard.deactivate = deactive;
  }

  /**
   * send state change
   * @param state
   */
  private onStateChange(state: State): void {
    this.statechange.emit({
      state,
      form: this,
    });
  }

  /**
   * Return all fields or throw a snackbar
   * @returns
   */
  private getFields(): FormField[] {
    const fields: FormField[] = [];

    for (const category of this.fields.values()) {
      const errors = this.getErrors(category);

      if (errors.length) {
        this.snackbar.open<string>(ErrorMessageComponent, "Niet opgeslagen - fouten in velden");
        return [];
      }

      // if (category.warnings.size) {
      //   if (!confirm("Opslaan met waarschuwingen?")) return [];
      // }
    }

    for (const category of this.fields.values()) {
      for (const field of category.fields.values()) {
        if (field.postable) fields.push(field);
      }
    }
    return fields;
  }

  private getAllFields(): FormField[] {
    const fields: FormField[] = [];
    for (const category of this.fields.values()) {
      for (const field of category.fields.values()) {
        fields.push(field);
      }
    }
    return fields;
  }

  /**
   * Close or open category panels based on incoming hi3 fields
   * @param fieldName name of hi3 field
   * @param type open or close panels
   */
  private togglePanels(fieldName: string, open = true): void {
    const fields = this.getFields();
    const indices = (<number[]>JSON.parse(<string>fields.find((field) => field.name == fieldName)?.value.value || "[]"))
      .filter((index) => index >= 0)
      .map((i) => i + 1);
    for (const categoryIndex of indices) {
      const category = this.fields.get(categoryIndex);
      if (category) {
        category.expanded = open;
      }
    }
  }
}
