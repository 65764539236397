@if(!disabled) {
  @if (control) {
    <div class="password-container">
      <input [ariaLabel]="label" [formControl]="control" [placeholder]="placeholder" [type]="!visible ? 'password' : 'text'" (beforeinput)="onBeforeInput($event)" />
      <button type="button" (click)="toggleVisibility()" [ariaLabel]="'PASSWORD.BUTTON.CLEAR' | translate">
        <mat-icon class="icon-eye">{{ !visible ? "visibility" : "visibility_off" }}</mat-icon>
      </button>
    </div>
  }
} @else {
  <app-template-html [control]="control" [value]="value" />
}

