import { Injectable, inject } from "@angular/core";
import { Router } from "@angular/router";
import { Subscription } from "rxjs";
import { SessionService } from "../services/session.service";

@Injectable()
export class IsNotAuthenticatedGuard {
  public router: Router;
  public session: SessionService;

  public constructor() {
    this.router = inject(Router);
    this.session = inject(SessionService);
  }

  public async canActivate(): Promise<Subscription> {
    return this.session.authenticated.subscribe((authenticated) => {
      if (authenticated) this.router.navigate(["app/home"]);
    });
  }

  public async canActivateChild(): Promise<Subscription> {
    return this.canActivate();
  }
}
