import { Pipe, PipeTransform } from "@angular/core";

@Pipe({
  name: "extractHtml",
  standalone: true,
})
export class ExtractHtmlPipe implements PipeTransform {
  public transform(value: string): string {
    const span = document.createElement("span");
    span.innerHTML = value;
    return span.textContent || span.innerText || "";
  }
}
