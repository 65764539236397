import { Component, Input, OnChanges, OnInit, SimpleChanges } from "@angular/core";
import { FormControl } from "@angular/forms";
import { merge } from "rxjs";
import { DefaultComponent } from "src/app/default.component";
import { PrefixTemplate } from "../../PrefixTemplate";
import { PrefixValidator } from "../../PrefixValidator";
import { TemplateDateComponent } from "../template-date/template-date.component";
import { TemplateHTMLComponent } from "../template-html/template-html.component";
import { TemplateTimeComponent } from "../template-time/template-time.component";

@Component({
  selector: "app-template-datetime",
  standalone: true,
  imports: [TemplateDateComponent, TemplateTimeComponent, TemplateHTMLComponent],
  templateUrl: "./template-datetime.component.html",
  styleUrl: "./template-datetime.component.less",
})
export class TemplateDatetimeComponent extends DefaultComponent implements PrefixTemplate<string>, OnInit, OnChanges {
  @Input({ required: true })
  public control: FormControl<string | null> | null;

  @Input()
  public value: string | null;

  @Input()
  public required: boolean;

  @Input()
  public disabled: boolean;

  @Input()
  public characters: string | null;

  @Input()
  public label: string | null;

  public dateValue: string | null;
  public timeValue: string | null;

  public date: FormControl<string | null>;
  public time: FormControl<string | null>;

  public constructor() {
    super();
    this.control = null;
    this.value = null;
    this.required = false;
    this.disabled = false;
    this.characters = null;
    this.label = null;
    this.date = new FormControl();
    this.time = new FormControl();
    this.dateValue = null;
    this.timeValue = null;
  }

  public ngOnChanges(changes: SimpleChanges): void {
    if (changes["value"]) {
      this.dateValue = changes["value"].currentValue?.substring(0, 10) ?? null;
      this.timeValue = changes["value"].currentValue?.substring(11, 16) ?? null;
    }
  }

  public ngOnInit(): void {
    const control = this.control;
    if (control) {
      this.addValidators(control);
    } else {
      throw new Error("Undefined control");
    }

    this.addSubscription(
      merge(this.date.valueChanges, this.time.valueChanges).subscribe(() => {
        const date = this.date.value;
        const time = this.time.value ?? "00:00";

        let value: string | null = null;
        if (date && time && this.isDate(date) && this.isTime(time)) {
          value = `${date} ${time}`;
        }

        this.control?.patchValue(value);
        this.control?.markAsTouched();
      }),
    );
  }

  private addValidators(control: FormControl<string | null>): void {
    if (this.required) control.addValidators([PrefixValidator.required()]);
    control.addValidators([PrefixValidator.minDate("1753-01-01")]);
    control.addValidators([PrefixValidator.maxDate("2099-12-31")]);
    control.updateValueAndValidity();
  }

  private isDate(v: string): boolean {
    return /^[0-9]{4}-[0-9]{2}-[0-9]{2}$/.test(v);
  }

  private isTime(v: string): boolean {
    return /^[0-9]{2}:[0-9]{2}$/.test(v);
  }
}
