<div class="container-fluid">
  <div class="row">
    <div class="col-md p-0">
      <div class="row">
        @if (attachments.length) {
          <div id="download-container" class="col-md">
            <mat-accordion id="item-container" class="clean">
              @for (item of attachments; track $index) {
                <div class="item">
                  <mat-expansion-panel hideToggle>
                    <mat-expansion-panel-header>
                      <div class="item-header">
                        <div class="info">
                          <div class="status" [tooltip]="'TOOLTIP.ATTACHMENT_STATUS.' + item.status.value">
                            <span [ngClass]="{ correct: item.isLoaded(), warn: item.isNew(), error: item.isRemoved() }"></span>
                          </div>

                          <div class="label">
                            <span class="title">
                              <label [title]="item.label" [style.textDecoration]="item.isRemoved() ? 'line-through' : 'inherit'">{{ item.label }}</label>
                            </span>
                            <label class="sub">
                              <span class="highlight">{{ "TOOLTIP.ATTACHMENT_STATUS." + item.status.value | translate }}</span>
                            </label>
                          </div>
                        </div>

                        <div class="actions">
                          <span class="icon" (click)="item.isRemoved() ? item.restore($event) : item.delete($event)">
                            <mat-icon>{{ item.isRemoved() ? "restore_page" : "delete" }}</mat-icon>
                          </span>
                          <span class="icon" (click)="item.download($event)">
                            <mat-icon>download</mat-icon>
                          </span>
                        </div>
                      </div>
                    </mat-expansion-panel-header>
                    <div class="item-body">
                      <div class="row"><!-- //TODO - check why this element is here --></div>

                      <div class="row">
                        <div class="col-md file-data">
                          <ul>
                            <li>
                              <span>{{ "DIALOG.ATTACHMENT.NAME" | translate }}: </span>
                              <span>{{ item.label }}</span>
                            </li>

                            <li style="margin: 1rem 0rem">
                              <span>{{ "DIALOG.ATTACHMENT.DESCRIPTION" | translate }}: </span>
                              <span [innerHTML]="item.description"></span>
                            </li>
                          </ul>
                        </div>
                      </div>
                    </div>
                  </mat-expansion-panel>
                </div>
              }
            </mat-accordion>
          </div>
        }
        @if (upload) {
          <label id="upload-container" for="file-input" class="col-md">
            <mat-icon>upload_file</mat-icon>
            <span>{{ "DIALOG.ATTACHMENT.UPLOAD_CONTAINER" | translate }}</span>
            <input id="file-input" multiple type="file" (change)="handleFileUpload($event)" />
          </label>
        }
      </div>

      <div class="row">
        <div id="button-container" class="col-md">
          <button class="default" mat-stroked-button (click)="cancel()">{{ "DIALOG.ATTACHMENT.CANCEL" | translate }}</button>
          <button class="default" mat-stroked-button (click)="save()">{{ "DIALOG.ATTACHMENT.SAVE" | translate }}</button>
        </div>
      </div>
    </div>
  </div>
</div>
