import { Component } from "@angular/core";
import { DefaultComponent } from "src/app/default.component";

@Component({
  standalone: true,
  selector: "app-tooltip",
  imports: [],
  template: "",
})
export class TooltipComponent<DATA = unknown> extends DefaultComponent {
  public data: DATA | null;

  public constructor() {
    super();
    this.data = null;
  }
}
