import { AfterViewInit, Component } from "@angular/core";
import { FormField } from "src/app/components/default/form/form-field";
import { PrefixValidator } from "src/app/components/global/prefixes/PrefixValidator";
import { FormPrefixComponent } from "src/app/components/global/prefixes/containers/form-prefix/form-prefix.component";
import { PrefixComponent } from "src/app/components/global/prefixes/prefix.component";

@Component({
  selector: "app-question-type-trp",
  standalone: true,
  imports: [FormPrefixComponent],
  templateUrl: "./question-type-trp.component.html",
  styleUrl: "./question-type-trp.component.less",
})
export class QuestionTypeTrpComponent extends PrefixComponent<string> implements AfterViewInit {
  public startField: FormField<unknown>;
  public endField: FormField<unknown>;

  public constructor() {
    super();
    this.startField = new FormField<unknown, Record<string, unknown>>("start", null, "ti2", "start");
    this.endField = new FormField<unknown, Record<string, unknown>>("end", null, "ti2", "end");
  }

  public ngAfterViewInit(): void {
    if (this.required) this.control.addValidators([PrefixValidator.required()]);
    if (this.value) {
      const [start, end] = this.value.split("-");
      this.startField.value.next(start);
      this.endField.value.next(end);
    }
    this.control.updateValueAndValidity();
  }

  public onFieldChange(): void {
    let value: string | null = null;
    if (this.startField.errors.size < 1 && this.endField.errors.size < 1) {
      value = this.startField.value.value + "-" + this.endField.value.value;
    }

    this.updateControl(value);
  }
}
