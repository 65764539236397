import { Component, OnInit } from "@angular/core";
import { ALLOWED_CHARACTERS } from "src/config/allowed-characters.config";
import { PrefixComponent } from "../../prefix.component";
import { TemplateTextComponent } from "../../templates/template-text/template-text.component";
import { MaxLength } from "../../types/MaxLength";

@Component({
  standalone: true,
  selector: "app-prefix-cd9",
  imports: [TemplateTextComponent],
  templateUrl: "./prefix-cd9.component.html",
  styleUrl: "./prefix-cd9.component.less",
})
export class PrefixCd9Component extends PrefixComponent<string> implements OnInit {
  public maxlength: MaxLength;

  public disabled: boolean;

  public constructor() {
    super({
      defaultValue: "0000000",
    });
    this.maxlength = 12;
    this.required = true;
    this.disabled = false;
    this.characters = ALLOWED_CHARACTERS["cd9"];
  }

  protected override onControlValue(value: string | null): void {
    if (value) {
      if (/^[0-9]+$/.test(value)) value = value.padStart(7, "0");
      super.onControlValue(value);
      this.updateControl(value, false);
    }
  }

  protected override setInitialControlValue(value: string | null): void {
    super.setInitialControlValue(value);
    if (value) this.disabled = true;
  }
}
