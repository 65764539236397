import { Component } from "@angular/core";
import { ALLOWED_CHARACTERS } from "src/config/allowed-characters.config";
import { PrefixComponent } from "../../prefix.component";
import { TemplateNumberComponent } from "../../templates/template-number/template-number.component";
import { DecimalSeperator } from "../../types/DecimalSeperator";
import { MaxLength } from "../../types/MaxLength";

@Component({
  selector: "app-prefix-de3",
  standalone: true,
  imports: [TemplateNumberComponent],
  templateUrl: "./prefix-de3.component.html",
  styleUrl: "./prefix-de3.component.less",
})
export class PrefixDe3Component extends PrefixComponent<string> {
  public max: MaxLength;

  public commas: number;
  public decimalSeperator: DecimalSeperator;

  public constructor() {
    super({
      defaultValue: "0.00",
      updateOn: "blur",
    });
    this.max = 9999999.99;
    this.commas = 2;
    this.decimalSeperator = ".";
    this.characters = ALLOWED_CHARACTERS["de2"];
  }
}
