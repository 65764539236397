import { Component, Input } from "@angular/core";
import { ALLOWED_CHARACTERS } from "src/config/allowed-characters.config";
import { PrefixComponent } from "../../prefix.component";
import { RadioOption, TemplateRadioComponent } from "../../templates/template-radio/template-radio.component";

@Component({
  standalone: true,
  selector: "app-prefix-ido",
  imports: [TemplateRadioComponent],
  templateUrl: "./prefix-ido.component.html",
  styleUrl: "./prefix-ido.component.less",
})
export class PrefixIdoComponent extends PrefixComponent<string> {
  @Input()
  public options: RadioOption[];

  public constructor() {
    super();
    this.options = [];
    this.required = true;
    this.characters = ALLOWED_CHARACTERS["ido"];
  }
}
