import { BehaviorSubject } from "rxjs";
import { TABLE_TEMPLATE } from "./table-template";

export class TableField<VALUE = unknown, EXTRAS = Record<string, unknown>> {
  public template: TABLE_TEMPLATE;
  public value: BehaviorSubject<VALUE>;
  public tooltip: string | null;

  public hidden: boolean;
  public required: boolean;
  public postable: boolean;

  public extras: EXTRAS | null;

  public constructor(template: TABLE_TEMPLATE, value: VALUE, tooltip: string | null = null, extras: EXTRAS | null = null) {
    this.template = template;
    this.value = new BehaviorSubject(value);
    this.tooltip = tooltip;

    this.hidden = false;
    this.required = false;
    this.postable = true;

    this.extras = extras;
  }
}
