import { CommonModule } from "@angular/common";
import { Component, OnInit, inject } from "@angular/core";
import { MatButtonModule } from "@angular/material/button";
import { MatExpansionModule } from "@angular/material/expansion";
import { MatIconModule } from "@angular/material/icon";
import { TranslateModule } from "@ngx-translate/core";
import { firstValueFrom, pairwise } from "rxjs";
import { Attachment } from "src/classes/Attachment";
import { FileManager } from "src/classes/FilesManager";
import { ROUTES_CONFIG } from "src/config/routes.config";
import { TooltipDirective } from "src/directives/tooltip.directive";
import { AttachmentStatus } from "src/enums/attachment-status";
import { ActionResponse, ParsedActionResponse } from "src/interfaces/post-request/post-request";
import { ApplicationService } from "src/services/application.service";
import { HttpService } from "src/services/http.service";
import { SessionService } from "src/services/session.service";
import { v4 as uuid } from "uuid";
import { DialogTitle } from "../../template/dialog-template.component";
import { DialogComponent } from "../dialog.component";

export interface AttachmentsDialogData {
  title: DialogTitle;
  attachments: Attachment[];
  contentId: string | null;
  contentPartId: string | null;
}

@Component({
  standalone: true,
  selector: "app-attachments",
  imports: [MatExpansionModule, MatIconModule, TooltipDirective, TranslateModule, CommonModule, MatButtonModule],
  templateUrl: "./attachments.component.html",
  styleUrl: "./attachments.component.less",
})
export class AttachmentsComponent extends DialogComponent<AttachmentsDialogData> implements OnInit {
  public application: ApplicationService;
  public http: HttpService;
  public session: SessionService;

  public upload: boolean;
  public attachments: Attachment[];
  public initialAttachments: Attachment[];

  public contentId: string | null;
  public contentPartId: string | null;

  public constructor() {
    super();
    this.application = inject(ApplicationService);
    this.http = inject(HttpService);
    this.session = inject(SessionService);

    this.upload = true;
    this.initialAttachments = this.attachments = [];
    this.contentId = null;
    this.contentPartId = null;
  }

  public ngOnInit(): void {
    super.ngOnInit();
    const data = this.data;

    if (data) {
      this.initialAttachments = this.attachments = data.attachments || [];
      this.contentId = data.contentId;
      this.contentPartId = data.contentPartId;
      for (const attachment of this.attachments) this.handleFileStatusChange(attachment);
    } else {
      throw new Error("Undefined data");
    }
  }

  public handleFileUpload(event: Event): void {
    const target = <HTMLInputElement>event.target;

    if (!target.files?.length) {
      return;
    }

    for (const file of Array.from(target.files)) {
      const attachment = new Attachment(uuid(), file.name, file, AttachmentStatus.NEW, this.formatSize(file.size));
      this.handleFileStatusChange(attachment);
      this.attachments.push(attachment);
    }

    // reset input
    target.value = "";
  }

  public handleFileStatusChange(attachment: Attachment): void {
    this.addSubscription(
      attachment.status.pipe(pairwise()).subscribe(async ([current, next]) => {
        if (current === AttachmentStatus.NEW && next === AttachmentStatus.REMOVED) {
          this.attachments = this.attachments.filter((filterAttachment) => filterAttachment.id !== attachment.id);
          return;
        } else if (next === AttachmentStatus.DOWNLOADING) {
          await this.handleFileDownload(attachment, current);
        }
      }),
    );
  }

  public override close(): void {
    super.close();
  }

  public cancel(): void {
    this.attachments = this.initialAttachments;
    this.attachments.forEach((attachment) => attachment.restore());
    this.close();
  }

  public async save(): Promise<void> {
    for (const [, attachment] of this.attachments.entries()) {
      const status = await firstValueFrom(attachment.status);

      switch (status) {
        case AttachmentStatus.NEW:
          await this.uploadAttachment(attachment);
          break;

        case AttachmentStatus.REMOVED:
          await this.deleteAttachment(attachment);
          break;
      }
    }
    this.close();
  }

  public formatSize(number: number): string {
    return Intl.NumberFormat("en", {
      notation: "compact",
      style: "unit",
      unit: "byte",
      unitDisplay: "narrow",
    }).format(number);
  }

  private async handleFileDownload(attachment: Attachment, prevState: AttachmentStatus): Promise<void> {
    try {
      if (attachment.file instanceof Blob) {
        FileManager.download({
          blob: attachment.file,
          label: attachment.label,
        });
      } else if (typeof attachment.file == "string") {
        await this.downloadAttachment(attachment);
      } else {
        throw new Error("ERROR handleFileDownload");
      }
    } catch (e) {
      console.error("Unable to download file => ", e);
    } finally {
      attachment.status.next(prevState);
    }
  }

  private async deleteAttachment(attachment: Attachment): Promise<void> {
    try {
      const response = await this.http.send<ActionResponse>(ROUTES_CONFIG.actionurl, {
        FFWDActionID: attachment.id,
      });
      const responsedata = <ParsedActionResponse>JSON.parse(response.postActionAsJSON);
      this.application.onMessage(responsedata.messages);
    } catch {
      console.error(`Could not delete attachment => `, { attachment });
    }
  }

  private async uploadAttachment(attachment: Attachment): Promise<void> {
    try {
      const response = await this.http.sendForm<{ postDataAsJSON: string }>(ROUTES_CONFIG.contentUrl, {
        attachment: true,
        contentid: this.contentId,
        contentpartid: this.contentPartId,
        padHierarchie: 1,
        gtpIndex: 2,
        file: attachment.file,
      });
      const responsedata = <ParsedActionResponse>JSON.parse(response.postDataAsJSON);
      this.application.onMessage(responsedata.messages);
    } catch (error) {
      console.error(error);
    }
  }

  private async downloadAttachment(attachment: Attachment): Promise<void> {
    try {
      const res = await this.http.send<Blob>(
        ROUTES_CONFIG.actionurl,
        {
          FFWDActionID: <string>attachment.file,
        },
        {
          responseType: <"json">"blob",
        },
      );
      FileManager.download({
        blob: res,
        label: attachment.label,
      });
    } catch (e) {
      console.error(`Could not download attachment => `, { attachment });
    }
  }
}
