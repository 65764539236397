import { Directive, ViewContainerRef, inject } from "@angular/core";

@Directive({
  selector: "[appPrefixContainer]",
  standalone: true,
})
export class PrefixContainerDirective {
  public viewContainerRef: ViewContainerRef;

  public constructor() {
    this.viewContainerRef = inject(ViewContainerRef);
  }
}
