<div class="tile-container">
  @for (tile of tiles; track $index) {
    <div class="tile">
      <div class="title">
        {{ tile.title }}
      </div>
      <div class="row-container">
        @for (column of tile.content; track $index) {
          <div class="column">
            @if (column.header && column.header.visible) {
              <label>
                @if (column.header.options.icon && column.header.visible) {
                  <span [innerHTML]="createIconElement(column.header.options.icon) | iconReplace"></span>
                }
                <span> {{ column.header.label }}: </span>
              </label>
            }
            <app-table-prefix [field]="column.field" />
          </div>
        }
      </div>
    </div>
  }
</div>
