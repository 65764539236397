import { Component, EventEmitter, Input, OnDestroy, OnInit, Output } from "@angular/core";
import { MatProgressBarModule } from "@angular/material/progress-bar";
import { MatProgressSpinnerModule } from "@angular/material/progress-spinner";
import { DefaultComponent } from "src/app/default.component";

export type TimerOrder = "ASC" | "DESC";

@Component({
  selector: "app-timer",
  standalone: true,
  imports: [MatProgressSpinnerModule, MatProgressBarModule],
  templateUrl: "./timer.component.html",
  styleUrl: "./timer.component.less",
})
export class TimerComponent extends DefaultComponent implements OnInit, OnDestroy {
  @Input({ required: true })
  public ms: number | null;

  @Input()
  public order: TimerOrder;

  @Input()
  public type: "default" | "bar" | "spinner";

  @Input()
  public spinnerDiameter: number;

  @Output()
  public onfinish: EventEmitter<void>;

  public time: number | null;
  public timer: NodeJS.Timeout | undefined;

  public constructor() {
    super();
    this.ms = null;
    this.onfinish = new EventEmitter();
    this.time = null;
    this.order = "ASC";
    this.timer = undefined;
    this.type = "default";
    this.spinnerDiameter = 28;
  }

  public ngOnInit(): void {
    if (this.ms) {
      this.start(this.ms);
    } else {
      throw new Error("Undefined input: ms");
    }
  }

  public ngOnDestroy(): void {
    super.ngOnDestroy();
    clearInterval(this.timer);
    this.timer = undefined;
  }

  public start(milliseconds: number, ticksize = 100): void {
    const order = this.order;
    const tick = order == "ASC" ? ticksize : -ticksize;

    this.time = order == "ASC" ? 0 : milliseconds;
    this.timer = setInterval(() => {
      if (this.time !== null) {
        this.time += tick;

        if (order == "ASC" && this.time >= milliseconds) {
          this.finish();
        } else if (order == "DESC" && this.time <= 0) {
          this.finish();
        }
      } else {
        throw new Error("Corrupt Timer");
      }
    }, ticksize);
  }

  public finish(): void {
    this.time = this.order == "ASC" ? 0 : this.ms;
    this.onfinish.emit();
  }
}
