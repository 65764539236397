import { Component, Input } from "@angular/core";
import { ALLOWED_CHARACTERS } from "src/config/allowed-characters.config";
import { PrefixComponent } from "../../prefix.component";
import { SelectOption, TemplateSelectComponent } from "../../templates/template-select/template-select.component";

@Component({
  standalone: true,
  selector: "app-prefix-idh[options]",
  imports: [TemplateSelectComponent],
  templateUrl: "./prefix-idh.component.html",
  styleUrl: "./prefix-idh.component.less",
})
export class PrefixIdhComponent extends PrefixComponent<string> {
  @Input()
  public options: SelectOption[];
  public multiple: boolean;

  public disabled: boolean;

  public constructor() {
    super();
    this.options = [];
    this.multiple = false;
    this.required = true;
    this.disabled = false;
    this.characters = ALLOWED_CHARACTERS["idh"];
  }

  protected override setInitialControlValue(value: string | null): void {
    super.setInitialControlValue(value);
    if (value) this.disabled = true;
  }
}
